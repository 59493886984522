<!--
 * @Author: Ten
 * @Date: 2021/07/20 20:07
 * @LastEditTime: 2021/11/02 19:50
 * @LastEditors: Ten
 * @Description: 表格
-->
<template>
  <div
    v-loading="loading"
    class="flex flex-column flex-1 relative"
    :style="{'min-height': minHeight}"
  >
    <div
      class="flex flex-column flex-1"
      :style="{'opacity': showDefault ? 0 : 1}"
    >
      <div class="flex-1" :class="$style.table">
        <el-table
          ref="table"
          :data="data"
          :row-class-name="tableRowClassName"
          :stripe="stripe"
          :fit="fit"
          :highlight-current-row="highlightCurrentRow"
          @selection-change="handleSelectionChange"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <slot />
        </el-table>
      </div>
      <div v-if="showCount || showPagination" :class="$style.footer">
        <div v-if="showCount" :class="$style.count">
          已选
          <span class="color--primary">{{ multipleSelection.length }}</span> 条
        </div>
        <Pagination
          v-if="showPagination"
          :loading="loading"
          :total="pagination.total"
          :page="pagination.page"
          :psize="pagination.psize"
          :class="[
            $style.pagination,
            {
              [$style['pagination--show-count']]: showCount
            }
          ]"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        />
      </div>
    </div>
    <Default
      v-show="!loading && showDefault"
      position="absolute"
      :type="defaultType"
    />
  </div>
</template>

<script>
import { Pagination } from '@/components/pagination'
import { Default } from '@/components/default'

export default {
  name: 'Table',
  components: {
    Pagination,
    Default
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: String,
      default: '300px'
    },
    data: {
      type: Array,
      default: () => []
    },
    stripe: {
      type: Boolean,
      default: false
    },
    fit: {
      type: Boolean,
      default: true
    },
    highlightCurrentRow: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: [Boolean, Object],
      default: () => {
        return {
          total: 0,
          psize: 0,
          page: 0
        }
      }
    },
    borderType: { // border的类型
      type: String,
      default: 'none' // inside-内线 outer-外线 none-没有
    }
  },
  data() {
    return {
      multipleSelection: [],
      defaultType: 'nodata'
    }
  },
  computed: {
    showDefault() {
      return this.data.length === 0
    },
    showPagination() {
      return this.$options.propsData.pagination
    }
  },
  watch: {
    data: {
      handler(val, oldVal) {
        if (val.length !== oldVal.length) {
          this.defaultType = 'content'
        }
      },
      deep: true
    }
  },
  methods: {
    handleSelectionChange(selection) {
      this.multipleSelection = selection
      this.$emit('selection-change', selection)
    },
    tableRowClassName({ row }) {
      const idList = this.multipleSelection.map((item) => item.id)
      if (idList.includes(row.id)) {
        return 'el-table__row--checked'
      }
    },
    onSizeChange(size) {
      this.$emit('page-size-change', size)
    },
    onCurrentChange(page) {
      this.$emit('page-current-change', page)
    },
    clearSort() {
      this.$refs.table.clearSort()
    },
    clearSelection() {
      this.$refs.table.clearSelection()
    }
  }
}
</script>

<style lang="scss" module>
.table {
  padding-bottom: 16px;
  padding-left: $box-padding;
  padding-right: $box-padding;
}
.footer {
  padding-left: $box-padding;
  padding-right: $box-padding;
  border-top: 1px solid $box-border-color--base;
}
.count {
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 30px;
  font-size: $font-size--base;
}
.pagination {
  margin-top: 20px;
  &.pagination--show-count {
    margin-top: 0;
  }
}
</style>
