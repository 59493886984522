<script>
const map = {
  create: {
    type: 'primary',
    icon: 'el-icon-plus',
    text: ''
  },
  search: {
    type: 'primary',
    icon: 'el-icon-search',
    text: '查 询'
  },
  refresh: {
    type: 'default',
    icon: 'el-icon-refresh',
    text: '重 置'
  },
  import: {
    type: 'success',
    icon: 'el-icon-upload2',
    text: '导 入'
  },
  export: {
    type: 'default',
    icon: 'el-icon-download',
    text: '导 出'
  },
  delete: {
    type: 'default',
    icon: 'el-icon-delete',
    text: '删 除'
  },
  transfer: {
    type: 'warning',
    // icon: 'el-icon-delete',
    text: '转 移'
  }
}

export default {
  name: 'Button',
  functional: true,
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  render(createElement, context) {
    const { type } = context.props
    let attrsType = ''
    let attrsIcon = ''
    let text = ''
    if (map[type]) {
      attrsType = map[type].type
      attrsIcon = map[type].icon
      text = map[type].text
    }
    return createElement('el-button', {
      ...context.data,
      attrs: {
        type: attrsType,
        icon: type === 'create' ? attrsIcon : ''
      }
      // directives: context.data.directives,
      // on: context.listeners
    }, context.children || text)
  }
}
</script>
