/*
 * @Author: Ten
 * @Date: 2021/10/24 11:05
 * @LastEditTime: 2021/12/22 11:11
 * @LastEditors: Ten
 * @Description:
 */
/**
 * 解析接口返回数据
 * @param {array} rules 权限
 * @return {object}
 * @Author: Ten
 * @Date: 2021/10/24 11:24
 */
export function resolveRules(rules = []) {
  const menus = rules.filter(rule => !rule.toString().includes('button'))
  const buttons = rules.filter(rule => {
    return rule.toString().includes('button')
  }).filter(button => {
    return !button.includes('button-list_')
  }).map(button => {
    return Number(button.replace('button_', ''))
  })
  return {
    menus,
    buttons
  }
}
/**
 * 生成提交接口数据
 * @param {array} menus 菜单权限
 * @param {array} buttons 按钮权限
 * @return {array}
 * @Author: Ten
 * @Date: 2021/10/24 11:24
 */
export function generateRules(menus = [], buttons = []) {
  const rules = menus ? menus.map(id => Number(id)) : []
  return rules.concat(buttons ? buttons.map(id => `button_${id}`) : [])
}
