/*
 * @Author: Ten
 * @Date: 2021/08/09 10:38
 * @LastEditTime: 2021/10/11 16:11
 * @LastEditors: Ten
 * @Description: 文件处理
 */
/**
 * 将base64转化为blob对象
 * @param {string} base64Data base64数据
 * @return {blob}
 * @Author: Ten
 * @Date: 2021/10/11 16:10
 */
export function base64ToBlob(base64Data) {
  const arr = base64Data.split(',')
  const fileType = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let l = bstr.length
  const u8Arr = new Uint8Array(l)

  while (l--) {
    u8Arr[l] = bstr.charCodeAt(l)
  }
  return new Blob([u8Arr], {
    type: fileType
  })
}

/**
 * 将file对象转为base64
 * @param {File} file 文件对象
 * @return {promise}
 * @Author: Ten
 * @Date: 2021/10/11 16:10
 */
export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target.result)
    }
    reader.onerror = (e) => {
      reject(e)
    }
    reader.readAsDataURL(file)
  })
}

/**
 * 获取文件扩展名
 * @param {string} fileName
 * @return {string}
 * @Author: Ten
 * @Date: 2021/07/20 11:29
 */
export function getFileExt(fileName) {
  const data = fileName.split('.')
  return data[data.length - 1]
}

/**
 * 获取文件
 * @param {string} filePath
 * @return {string}
 * @Author: Ten
 * @Date: 2021/07/20 11:31
 */
export function getFileName(filePath) {
  const index = filePath.lastIndexOf('/') || 0
  return filePath.substr(index === -1 ? 0 : index)
}
