<!--
 * @Author: Ten
 * @Date: 2021/07/15 19:20
 * @LastEditTime: 2021/11/02 19:29
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <div class="demo">
    <h1>Buttons 按钮</h1>
    <h2>Primay Button 主要按钮</h2>
    <div>
      <div class="flex flex-start">
        <el-button type="primary" size="mini">主按钮</el-button>
        <el-button type="primary">确 定</el-button>
        <el-button type="primary" icon="el-icon-plus">新增</el-button>
        <el-button type="primary" size="large">新 增</el-button>
      </div>
      <div class="flex flex-start">
        <el-button type="primary" size="mini" disabled>主按钮</el-button>
        <el-button type="primary" disabled>确 定</el-button>
        <el-button type="primary" icon="el-icon-plus" disabled>新增</el-button>
        <el-button type="primary" size="large" disabled>新 增</el-button>
      </div>
    </div>
    <h2>Danger Button 危险按钮</h2>
    <div class="flex flex-start">
      <el-button type="danger" size="mini">主按钮</el-button>
      <el-button type="danger" disabled>确 定</el-button>
      <el-button type="danger" icon="el-icon-plus">新增</el-button>
      <el-button type="danger" size="large">新 增</el-button>
    </div>
    <h2>Ghost Button 幽灵按钮</h2>
    <div class="flex flex-start">
      <el-button size="mini">次按钮</el-button>
      <el-button>取消</el-button>
      <el-button type="primary" plain>取消</el-button>
    </div>
    <div class="flex flex-start">
      <el-button size="mini" disabled>次按钮</el-button>
      <el-button disabled>取消</el-button>
      <el-button type="primary" plain disabled>取消</el-button>
    </div>
    <h2>Text Button 文字按钮</h2>
    <div class="flex flex-start">
      <el-button type="text">删除</el-button>
      <el-button type="text" disabled>删除</el-button>
    </div>

    <h1>Navigation 导航</h1>
    <h2>1、Pagination 分页</h2>
    <Pagination align="left" :psize="15" :total="120" />

    <h2>2、Dropdown下拉菜单</h2>
    <el-dropdown>
      <span class="dropdown__button">
        下拉菜单 <i class="el-icon-arrow-down" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>选项A</el-dropdown-item>
        <el-dropdown-item>选项B</el-dropdown-item>
        <el-dropdown-item disabled>选项C</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <h2>3、面包屑导航</h2>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a href="/">系统管理</a></el-breadcrumb-item>
      <el-breadcrumb-item>后台导航管理</el-breadcrumb-item>
    </el-breadcrumb>

    <h1>DataEntry 数据录入</h1>
    <h2>1、Radio 单选框</h2>
    <div class="flex flex-middle">
      <el-radio-group v-model="radio">
        <el-radio label="2">未选</el-radio>
        <el-radio label="1">选中</el-radio>
        <el-radio disabled label="1">选中不可点击</el-radio>
        <el-radio disabled label="2">未选不可点击</el-radio>
      </el-radio-group>
    </div>

    <h2>2、Radio 多选框</h2>
    <div class="flex flex-middle">
      <el-checkbox-group v-model="checkList1">
        <el-checkbox label="未选"></el-checkbox>
        <el-checkbox label="选中"></el-checkbox>
      </el-checkbox-group>
      <el-checkbox-group v-model="checkList2" style="margin-left: 30px">
        <el-checkbox label="已选不可点击" disabled></el-checkbox>
        <el-checkbox label="未选不可点击" disabled></el-checkbox>
      </el-checkbox-group>
    </div>

    <h2>3、Input 输入框</h2>
    <div class="flex flex-column demo-input">
      <el-input
        size="small"
        prefix-icon="el-icon-user"
        placeholder="用户名"
        v-model="input1"
        clearable
      />
      <el-input
        size="small"
        prefix-icon="el-icon-lock"
        placeholder="密码"
        type="password"
        v-model="input2"
        clearable
      />
      <el-input
        size="small"
        suffix-icon="el-icon-search"
        placeholder="请输入搜索内容"
        v-model="input1"
        clearable
      />
      <el-input
        size="small"
        suffix-icon="el-icon-search"
        placeholder="请输入资源路径2"
        v-model="input1"
        clearable
      />
      <el-input
        size="small"
        suffix-icon="el-icon-search"
        placeholder="请输入资源路径2"
        v-model="input1"
        disabled
        clearable
      />
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入内容"
        v-model="textarea"
      >
      </el-input>
    </div>

    <h2>4、Form 表单</h2>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      class="demo-form"
    >
      <el-form-item label="标题六个字符：" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')"
          >立即创建</el-button
        >
        <el-button @click="resetForm('form')">重置</el-button>
      </el-form-item>
    </el-form>

    <h2>5、Select 下拉选项框</h2>
    <el-select v-model="value" placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <h2>6、DatePicker 日期选择框</h2>
    <DatePicker :start.sync="params.start_time" :end.sync="params.end_time" />

    <h2>7、Upload上传</h2>
    <ImageUploader />
    <!-- <el-upload
      action="https://jsonplaceholder.typicode.com/posts/"
      :show-file-list="false"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar">
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload> -->

    <h1>DataDisplay 数据展示</h1>
    <h2>1、头像</h2>
    <h2>2、标签</h2>
    <div class="flex flex-column flex-start">
      <Tag type="success">创建成功</Tag>
      <Tag type="danger">创建失败</Tag>
      <Tag type="warning">创建中</Tag>
    </div>

    <h2>3、Tooltip 文字提示</h2>
    <el-tooltip class="item" effect="dark" content="提示文字" placement="top">
      <el-button size="small">提示</el-button>
    </el-tooltip>

    <h2>4、Table 表格</h2>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="date" label="日期" width="180"> </el-table-column>
      <el-table-column prop="name" label="姓名" width="180"> </el-table-column>
      <el-table-column prop="address" label="地址"> </el-table-column>
    </el-table>

    <Table
      style="margin-top: 20px"
      :data="tableData"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="日期" width="120">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="120"> </el-table-column>
      <el-table-column prop="address" label="地址" show-overflow-tooltip>
      </el-table-column>
    </Table>

    <h1>Feedback 反馈</h1>
    <h2>1、Modal 对话框</h2>
    <el-button
      size="small"
      @click="handleOpenModal('内容已更改，退出编辑将不保存所修改的内容。')"
      >Modal 对话框1</el-button
    >
    <el-button
      size="small"
      @click="
        handleOpenModal(
          '内容已更改，退出编辑将不保存所修改的内容。内容已更改，退出编辑将不保存所修改的内容。'
        )
      "
      >Modal 对话框2</el-button
    >

    <h2>2、Popconfirm 气泡确认框</h2>
    <el-popconfirm
      title="确定要删除吗？"
      icon="el-icon-info"
      confirm-button-type="danger"
      cancel-button-type="default"
    >
      <el-button size="small" slot="reference">删除</el-button>
    </el-popconfirm>

    <h2>3、Message 全局提示</h2>
    <el-button size="small" @click="open1">错误</el-button>
    <el-button size="small" @click="open2">成功</el-button>
    <el-button size="small" @click="open3">警告</el-button>
    <el-button size="small" @click="open4">信息</el-button>
  </div>
</template>

<script>
import { ImageUploader } from '@/components/image'
import { Pagination } from '@/components/pagination'
import { DatePicker } from '@/components/date'
import { Tag } from '@/components/tag'
import { Table } from '@/components/table'
import { confirm } from '@/utils/messageBox'

export default {
  name: 'Demo',
  components: {
    ImageUploader,
    Pagination,
    DatePicker,
    Tag,
    Table
  },
  data() {
    return {
      radio: '1',
      checkList1: ['选中'],
      checkList2: ['已选不可点击'],
      input1: '',
      input2: '',
      form: {
        name: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入内容',
            trigger: 'blur'
          }
        ]
      },
      value: '1',
      options: [
        {
          value: '1',
          label: '全部'
        },
        {
          value: '2',
          label: '正常使用'
        },
        {
          value: '3',
          label: '不可用'
        }
      ],
      params: {
        start_time: '',
        end_tiem: ''
      },
      textarea: '',
      tableData: [
        {
          id: 1,
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          id: 2,
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          id: 3,
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          id: 4,
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      multipleSelection: [],
      imageUrl: ''
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('submit!')
        } else {
          console.log('error submit!!')
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    tableRowClassName({ row, rowIndex }) {
      console.log(row)
      const idList = this.multipleSelection.map((item) => item.id)
      if (idList.includes(row.id)) {
        return 'el-table__row--checked'
      }
    },
    handleOpenModal(message) {
      confirm({
        title: '确定要退出编辑吗？',
        message,
        subMessage: '对话框内容区域对话框内容区域对话框内容区域对话框内容区域对话框内容区域对话框内容区域对话框内容区域。'
      })
    },
    open1() {
      this.$message.error('这是一条失败的消息，会自动消失。')
    },
    open2() {
      this.$message.success({
        message: '这是一条成功的消息，会自动消失。',
        duration: 0
      })
    },
    open3() {
      this.$message.warning('这是一条询问的消息，会自动消失。')
    },
    open4() {
      this.$message.info({
        message: '这是一条询问的消息，会自动消失。',
        duration: 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.demo {
  padding: 20px 20px 400px;
  ::v-deep {
    .tag,
    .el-button {
      margin: 10px;
    }
  }
  .dropdown__button {
    color: $color--primary;
    &:hover {
      cursor: pointer;
    }
    &:hover,
    &:focus {
      color: $button-color--primary--hover;
    }
    &:active {
      color: $button-color--primary--active;
    }
  }
  .demo-input {
    ::v-deep {
      .el-textarea,
      .el-input {
        margin: 10px;
      }
      .el-textarea,
      .el-input {
        width: 400px;
      }
    }
  }
  .demo-form {
    width: 410px;
  }
}
</style>
