<!--
 * @Author: Ten
 * @Date: 2022/02/28 17:31
 * @LastEditTime: 2022/03/01 14:27
 * @LastEditors: Ten
 * @Description: 添加/编辑菜单
-->
<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    :title="currentTitle"
    width="600px"
    @close="onCloseDialog"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :rules="rules"
      :model="form"
      label-width="70px"
      class="is-dialog"
    >
      <el-form-item label="上级：" prop="pid">
        <MenuCascader
          v-model="form.pid"
          :props="defaultProps"
          :default-list="defaultMenuList"
          :options="menus"
        />
      </el-form-item>
      <el-form-item label="名称：" prop="title">
        <el-input v-model.trim="form.title" clearable />
      </el-form-item>
      <el-form-item label="标识：" prop="identify">
        <el-input v-model.trim="form.identify" clearable />
      </el-form-item>
      <el-form-item label="图标：" prop="icon">
        <el-input v-model.trim="form.icon" clearable />
      </el-form-item>
      <el-form-item label="路径：" prop="path">
        <el-input v-model.trim="form.path" clearable />
      </el-form-item>
      <el-form-item label="组件：" prop="component">
        <el-input v-model.trim="form.component" clearable />
      </el-form-item>
      <el-form-item label="排序：" prop="sorts">
        <el-input
          v-model.trim="form.sorts"
          clearable
          oninput="value=value.replace(/[^\d]/g,'')"
        />
      </el-form-item>
      <el-form-item label="跳转：" prop="redirect">
        <el-input v-model.trim="form.redirect" clearable />
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="0">正常</el-radio>
          <el-radio :label="1">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="隐藏：" prop="hidden">
        <el-radio-group v-model="form.hidden">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="缓存：" prop="no_cache">
        <el-radio-group v-model="form.no_cache">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="根路由：" prop="always_show">
        <el-radio-group v-model="form.always_show">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="菜单类型：" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :label="0">系统菜单</el-radio>
          <el-radio :label="1">项目菜单</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      v-loading="loading"
      element-loading-spinner=" "
      class="dialog-footer"
    >
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" :loading="loadingSubmit" @click="saveData">
        {{ loadingSubmit ? '保存中' : id === -1 ? '确 定' : '保 存' }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { createMenu, readMenu, updateMenu } from '@/api/base/modules/menu'
import { MenuCascader } from '@/components/menu'

export default {
  name: 'FormDialog',
  components: {
    MenuCascader
  },
  props: {
    menus: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      id: -1,
      defaultProps: {
        checkStrictly: true,
        expandTrigger: 'hover'
      },
      defaultMenuList: [{ id: 0, title: '顶级' }],
      form: {
        pid: '',
        title: '',
        identify: '',
        status: 0,
        icon: '',
        path: '',
        component: 'layout',
        hidden: 1,
        no_cache: 1,
        always_show: 1,
        redirect: '',
        sorts: 0,
        type: 0 // 菜单类型：0系统菜单;1项目菜单
      },
      cached: {
        path: ''
      },
      visible: false,
      rules: {
        pid: [{ required: true, message: '请选择上级菜单', trigger: 'change' }],
        title: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
        identify: [{ required: true, message: '请输入标识', trigger: 'blur' }],
        icon: [{ required: true, message: '请输入图标名称', trigger: 'blur' }],
        path: [
          {
            required: true,
            message: '请输入访问路径',
            trigger: 'blur'
          },
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (this.menus.some(menu => menu.path === value && this.cached.path !== value)) {
                callback(new Error(`路径"${value}"已被占用，请选取其他路径`))
              } else {
                callback()
              }
            }
          }
        ],
        component: [{ required: true, message: '请输入组件路径', trigger: 'blur' }],
        sorts: [{ required: true, message: '请输入排序', trigger: 'blur' }]
      }
    }
  },
  computed: {
    currentTitle() {
      return this.id === -1 ? '添加菜单' : '编辑菜单'
    },
    projectKey() {
      return this.$store.getters['user/projectKey']
    },
    projectId() {
      return this.$store.getters['user/projectId']
    }
  },
  methods: {
    open(id = -1) {
      this.id = id || -1
      if (this.id !== -1) {
        this.initUpdate(this.id)
      }
      this.visible = true
    },
    resetForm() {
      this.id = -1
      this.cached.path = ''
      this.$refs.form.resetFields()
    },
    initUpdate(id) {
      this.loading = true
      readMenu(id).then(res => {
        const { data } = res
        this.form.sorts = data.sorts
        this.form.pid = data.pid
        this.form.title = data.title
        this.form.identify = data.identify
        this.form.status = data.status
        this.form.icon = data.icon
        this.form.path = data.path
        this.cached.path = data.path
        this.form.component = data.component
        this.form.hidden = data.hidden
        this.form.no_cache = data.no_cache
        this.form.always_show = data.always_show
        this.form.type = data.type || 0
        this.form.redirect = data.redirect
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    async saveMenu() {
      try {
        this.loadingSubmit = true
        if (this.id === -1) {
          await createMenu(this.getForm())
        } else {
          await updateMenu(this.id, this.getForm())
        }
        this.$emit('submit')
        this.visible = false
        this.$message.success('保存成功')
        this.loadingSubmit = false
      } catch (err) {
        console.log(err)
        this.loadingSubmit = false
      }
    },
    saveData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveMenu()
        }
      })
    },
    onCloseDialog() {
      this.id = -1
      this.loading = false
      this.loadingSubmit = false
      this.resetForm()
    },
    getForm() {
      return {
        ...this.form,
        project: this.projectKey,
        project_id: this.projectId
      }
    }
  }
}
</script>
