/*
 * @Author: Ten
 * @Date: 2021/09/16 15:20
 * @LastEditTime: 2021/10/13 10:46
 * @LastEditors: Ten
 * @Description: 列表事件
 */
import { EventBus } from '@/utils/event-bus'
export const ListEventBusMixin = {
  created() {
    const pageName = this.$options.name
    EventBus.$on(pageName, ({ type, source = '' }) => {
      type === 'reset' && typeof this.handleReset === 'function' && this.handleReset()
      type === 'refresh' && typeof this.handleSearch === 'function' && this.handleRefresh(source)
      type === 'refreshInfo' && typeof this.handleRefreshInfo === 'function' && this.handleRefreshInfo()
    })
    this.$once('hook:beforeDestroy', () => {
      EventBus.$off(pageName)
    })
  }
}
