<!--
 * @Author: Ten
 * @Date: 2021/08/09 10:59
 * @LastEditTime: 2022/01/05 16:18
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <el-input class="input" :value="value" v-bind="$attrs" @input="onInput" />
</template>

<script>
export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      default: ''
    },
    reg: {
      type: [String, RegExp],
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  methods: {
    onInput(value) {
      let formattedValue = ''
      if (this.reg) {
        formattedValue = value.replace(new RegExp(this.reg, 'g'), '')
      } else {
        switch (this.type) {
          // 限制只能输入数字
          case 'number':
            formattedValue = /^[0|1|2|3|4|5|6|7|8|9]*$/.test(value)
              ? (value.length > 1 ? Number(value) : value)
              : this.value
            break
          // 限制不能输入空格
          case 'trim-all':
            formattedValue = value.replace(new RegExp(/\s+/, 'g'), '')
        }
      }
      this.$emit('input', formattedValue)
      this.$emit('change', formattedValue)
    }
  }
}
</script>

<style lang="scss">
.input {
  &.el-input--small ~ .el-form-item__error {
    padding-top: 0;
  }
}
</style>
