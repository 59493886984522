/*
 * @Author: Ten
 * @Date: 2021/10/21 10:17
 * @LastEditTime: 2021/10/21 11:23
 * @LastEditors: Ten
 * @Description:
 */
import {
  commonRequstInterceptor,
  filterEmptyGetParamsInterceptor,
  commonResponseInterceptor,
  statusResponseInterceptor
} from '@/api/base/interceptors'

// 忽略 project 参数的API
const ignoreProjectAPI = []

// 设置全局 project 参数
export const projectParamsInterceptor = {
  onResolved(config, options) {
    const { store } = options
    if (!ignoreProjectAPI.some(api => api.test(config.url))) {
      const projectId = store.getters['user/projectId']
      const projectKey = store.getters['user/projectKey']
      if (config.method === 'get') {
        config.params = {
          project_id: projectId,
          project: projectKey,
          ...config.params
        }
      } else if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
        config.data = {
          project_id: projectId,
          project: projectKey,
          ...config.data
        }
      }
    }
    return config
  }
}

// 请求拦截器
export const requestInterceptors = [
  commonRequstInterceptor,
  filterEmptyGetParamsInterceptor,
  projectParamsInterceptor
]

// 响应拦截器
export const responseInterceptors = [
  commonResponseInterceptor,
  statusResponseInterceptor
]
