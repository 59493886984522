<!--
 * @Author: Ten
 * @Date: 2021/10/18 15:09
 * @LastEditTime: 2022/03/03 16:02
 * @LastEditors: Ten
 * @Description: 菜单管理
-->
<template>
  <Page>
    <FilterBar>
      <el-form :inline="true">
        <el-form-item label="菜单名称：" label-width="75px">
          <el-input v-model.trim="params.title" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container>
      <template #header>
        <Button v-auth:create type="create" @click="handleAdd">添加菜单</Button>
      </template>
      <Table
        :loading="loading"
        :data="treeData"
        row-key="id"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          min-width="48"
          fixed
        />
        <el-table-column
          label="名称"
          min-width="150px"
          prop="title"
          fixed
        />
        <el-table-column
          label="标识"
          min-width="150px"
          prop="identify"
        />
        <el-table-column
          label="图标"
          min-width="120px"
          prop="icon"
        />
        <el-table-column
          label="路径"
          min-width="150px"
          prop="path"
        />
        <el-table-column label="组件" min-width="150px" prop="component" />
        <el-table-column label="状态" min-width="120px">
          <template slot-scope="scope">
            <StatusButton
              v-auth:change-status
              :status="scope.row.status"
              @click="handleModifyStatus(scope.row.id, scope.row.status)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="150px"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-auth:edit
              title="点击编辑"
              type="text"
              @click="handleUpdate(scope.row.id)"
            >
              编辑
            </el-button>
            <PopconfirmDelButton
              class="ml-16"
              v-auth:delete
              @confirm="handleDel(scope.row.id)"
            >
              删除
            </PopconfirmDelButton>
          </template>
        </el-table-column>
      </Table>
    </Container>
    <BatchDelBar
      v-if="selectedRows.length > 0"
      :selected-count="selectedRows.length"
      @confirm="handleDelBatch()"
    />
    <FormDialog
      ref="fromMenu"
      :menus="list"
      @submit="handleRefresh"
    />
  </Page>
</template>

<script>
import { readAllMenus, modifyMenuStatus, delMenu, delMenus } from '@/api/base/modules/menu'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { FilterBar } from '@/components/filter'
import { BatchDelBar } from '@/components/bar'
import { Button, PopconfirmDelButton } from '@/components/button'
import { StatusButton } from '@/components/status-button'
import { Table } from '@/components/table'
import FormDialog from './comps/FormDialog'
import { confirm } from '@/utils/messageBox'
import { toTree, filterTree } from '@/utils/tree'

export default {
  name: 'SystemMenuList',
  components: {
    Page,
    Container,
    FilterBar,
    BatchDelBar,
    Button,
    PopconfirmDelButton,
    StatusButton,
    Table,
    FormDialog
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: '禁用',
        1: '正常'
      }
      return statusMap[status]
    }
  },
  data() {
    return {
      loading: true,
      loadingDel: false,
      loadingModifyStatus: false,
      list: [],
      selectedRows: [],
      params: {
        title: ''
      }
    }
  },
  computed: {
    treeData({ list }) {
      return toTree(list)
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      this.loading = true
      readAllMenus().then(res => {
        this.list = filterTree(toTree(res.data), this.params.title, {
          label: 'title',
          value: 'id'
        })
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    handleRefresh() {
      this.fetchList()
    },
    handleSearch() {
      this.fetchList()
    },
    handleReset() {
      this.params.title = ''
      this.fetchList()
    },
    handleAdd() {
      this.$refs.fromMenu.open()
    },
    handleUpdate(id) {
      this.$refs.fromMenu.open(id)
    },
    handleSelectionChange(val) {
      this.selectedRows = val
    },
    handleModifyStatus(id, status) {
      this.loadingModifyStatus = true
      const params = { status: 1 - status }
      modifyMenuStatus(id, params).then(() => {
        this.loadingModifyStatus = false
        this.fetchList()
        // this.list.forEach((item) => {
        //   if (item.id === id) {
        //     item.status = 1 - status
        //   }
        // })
      }).catch(err => {
        this.loadingModifyStatus = false
        console.log(err)
      })
    },
    handleDel(id) {
      confirm({
        message: '此操作将永久删除该记录, 是否继续?',
        forever: true
      }).then(() => {
        this.loading = true
        delMenu(id).then(() => {
          this.$message.success('删除成功')
          this.fetchList()
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      })
    },
    handleDelBatch() {
      if (this.selectedRows.length === 0) {
        this.$message.error('请选择要删除的数据')
        return
      }
      confirm({
        message: '此操作将永久删除该记录, 是否继续?',
        forever: true
      }).then(() => {
        this.loadingDel = true
        const ids = this.selectedRows.map(item => item.id).join(',')
        delMenus(ids).then(() => {
          this.$message.success('删除成功')
          this.handleRefresh()
          this.loadingDel = false
        }).catch(err => {
          console.log(err)
          this.loadingDel = false
        })
      })
    }
  }
}
</script>
