<!--
 * @Author: Ten
 * @Date: 2021/09/06 14:27
 * @LastEditTime: 2022/03/01 11:38
 * @LastEditors: Ten
 * @Description: 添加已有成员弹窗
-->
<template>
  <el-dialog
    title="添加已有成员"
    width="1000px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="onCloseDialog"
  >
    <SelectPanel
      v-loading="loading"
      :left-span="9"
      :right-span="15"
      height="500px"
    >
      <template #left>
        <RoleTreeSelector
          ref="roleTreeSelector"
          v-model="checked"
          :options="options"
          filterable
          @check="handleRoleTreeSelectorCheck"
        />
      </template>
      <template #right>
        <el-form ref="form" :model="form" :class="$style.form">
          <el-table
            ref="table"
            :data="form.checked"
            :height="500"
            :class="$style.table"
            row-key="id"
            border
          >
            <el-table-column
              label="成员名称"
              prop="real_name"
              min-width="120"
            />
            <el-table-column label="成员账号" prop="username" min-width="120" />
            <el-table-column label="游戏角色" prop="role" min-width="150">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'checked.' + scope.$index + '.roles'"
                  :rules="{
                    required: true,
                    message: '请选择角色',
                    trigger: 'change'
                  }"
                >
                  <RoleSelector
                    v-model="scope.row.roles"
                    :options="roles"
                    size="small"
                    multiple
                    collapse-tags
                    @change="$refs.table.doLayout()"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
              <template slot-scope="{ row }">
                <el-button
                  title="点击移除"
                  type="text"
                  @click="handleRoleTreeSelectorSetKeys(row.id, row.tree_id)"
                >
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </template>
    </SelectPanel>
    <div slot="footer" v-loading="loading" element-loading-spinner=" ">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { batchSetUserRole, getDepUserList } from '@/api/project/modules/user'
import { SelectPanel } from '@/components/panel'
import { RoleSelector, RoleTreeSelector } from '@/components/role'

export default {
  name: 'MemberJoinDialog',
  components: {
    SelectPanel,
    RoleTreeSelector,
    RoleSelector
  },
  data() {
    return {
      visible: false,
      loading: false,
      options: [],
      checked: [],
      form: {
        checked: []
      }
    }
  },
  computed: {
    projectKey() {
      return this.$store.getters['user/projectKey']
    },
    projectId() {
      return this.$store.getters['user/projectId']
    },
    roles() {
      return this.$store.getters['role/data']
    }
  },
  methods: {
    async open() {
      this.visible = true
      this.loading = true
      this.initData().then(() => {
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    initData() {
      const promises = [
        this.fetchDepUserList(),
        this.$store.dispatch('role/getData', {
          project: this.projectKey,
          project_id: this.projectId
        })
      ]
      return Promise.all(promises)
    },
    // 请求公司成员列表
    fetchDepUserList() {
      return getDepUserList()
        .then(res => {
          this.options = res.data.map(item => {
            return {
              ...item,
              roles: []
            }
          })
          this.$nextTick(() => {
            this.$refs.roleTreeSelector.filter()
          })
        })
    },
    // 勾选/取消勾选
    handleRoleTreeSelectorCheck(selectedList) {
      // 勾选
      if (selectedList.length > this.form.checked.length) {
        // 过滤出之前已选的数据id
        const checkedIds = this.form.checked.map(({ id }) => id)
        // 过滤出新勾选的数据
        const checkeds = selectedList.filter(({ id }) => {
          return !checkedIds.includes(id)
        })
        if (checkeds.length > 0) {
          // 从开头插入新勾选的数据
          this.form.checked = checkeds.map(checked => {
            return {
              ...checked,
              roles: []
            }
          }).concat(this.form.checked)
        }
        return
      }
      // 取消勾选
      // 过滤出当前勾选的数据id
      const checkedIds = selectedList.map(({ id }) => id)
      // 过滤出当前选中的数据
      this.form.checked = this.form.checked.filter(({ id }) => {
        return checkedIds.includes(id)
      })
      // this.form.checked = selectedList.map(item => {
      //   return {
      //     ...item,
      //     roles: this.form.checked.find(({ id }) => id === item.id)?.roles || []
      //   }
      // })
    },
    // 移除
    handleRoleTreeSelectorSetKeys(id, treeId) {
      // console.log('handleRoleTreeSelectorSetKeys', id)
      this.checked = this.checked.filter((i) => i !== id)
      this.form.checked = this.form.checked.filter((option) => {
        return this.checked.includes(option.id)
      })
      this.$refs.roleTreeSelector.setChecked(treeId)
      console.log(id, this.checked, this.form.checked)
    },
    handleSubmit() {
      if (this.form.checked.length === 0) {
        this.$message.error('请选择成员后进行添加！')
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const data = this.form.checked.map((item) => {
            return {
              user_id: item.id,
              roles: item.roles
            }
          })
          batchSetUserRole({
            data
          }).then(() => {
            this.$message.success('添加成功')
            this.$emit('submit')
            this.loading = false
            this.visible = false
          }).catch((err) => {
            console.log(err)
            this.loading = false
          })
        }
      })
    },
    onCloseDialog() {
      this.loading = false
      this.options = []
      this.checked = []
      this.form.checked = []
      // 清空搜索输入框
      this.$refs.roleTreeSelector.filter('')
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  height: 480px;
  background-color: rebeccapurple;
}
.item {
  display: flex;
  align-items: center;
  padding: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid $border-color--primary;
  }
  .name {
    line-height: 20px;
    font-size: $font-size--base;
    color: $color-text--primary;
  }
  .subname {
    line-height: 17px;
    font-size: $font-size--extra-small;
    color: $color-text--regular;
  }
  .add {
    font-size: $font-size--medium;
    color: $color--primary;
    &:hover {
      cursor: pointer;
      color: $button-color--primary--hover;
    }
    &:active {
      color: $button-color--primary--active;
    }
  }
}
.table {
  border: 1px solid $border-color--primary;
  border-radius: 6px;
  &::before,
  &::after {
    content: none;
  }
  :global {
    td.el-table__cell,
    th.el-table__cell.is-leaf {
      border-bottom-color: $border-color--primary;
      border-right: 0;
      border-left-width: 1px;
      border-left-style: solid;
      &:first-child {
        border-left: 0;
      }
    }

    th.el-table__cell {
      border-color: $border-color--primary;
      &.gutter {
        border-right: none;
      }
    }
    td.el-table__cell {
      border-color: $border-color--primary;
      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
    th.el-table__cell {
      background-color: transparent;
    }
    td:first-child {
      padding-left: 0;
    }
  }
}
.form {
  :global {
    .el-form-item {
      margin-bottom: 0;
      .el-select {
        .el-input {
          .el-input__inner {
            padding-left: 0;
            border-color: transparent !important;
            background-color: transparent;
            &:focus {
              box-shadow: none;
            }
          }
          &.is-focus {
            .el-input__inner {
              box-shadow: none;
            }
          }
        }
      }
      &.is-error {
        .el-input__inner {
          &:focus {
            box-shadow: none !important;
          }
          &::placeholder {
            color: $color--danger !important;
          }
        }
      }
    }
    .el-form-item__error {
      display: none;
    }
  }
}
</style>
