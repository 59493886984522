<!--
 * @Author: Ten
 * @Date: 2021/10/18 15:08
 * @LastEditTime: 2022/03/01 16:25
 * @LastEditors: Ten
 * @Description: 用户管理
-->
<template>
  <Page>
    <FilterBar>
      <el-form :inline="true" :model="params" class="form-inline">
        <el-form-item label="成员名称：">
          <el-input
            v-model.trim="params.real_name"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="成员账号：">
          <el-input
            v-model.trim="params.username"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="最后访问日期：">
          <DatePicker :start.sync="params.start_time" :end.sync="params.end_time" />
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container>
      <template #header>
        <Button v-auth:create type="create" @click="handleAdd">添加新成员</Button>
      </template>
      <Table
        :loading="loading"
        :data="list"
        :pagination="pagination"
        @page-size-change="onSizeChange"
        @page-current-change="onCurrentChange"
      >
        <el-table-column
          label="序号"
          min-width="115"
          fixed
        >
          <template slot-scope="scope">
            {{ scope.$index | filterForamtSerialNumber(pagination.page, pagination.psize) }}
          </template>
        </el-table-column>
        <el-table-column
          label="成员名称"
          min-width="110px"
          fixed
        >
          <template slot-scope="scope">{{ scope.row.real_name }}</template>
        </el-table-column>
        <el-table-column
          label="成员账号"
          min-width="110px"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.username }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="所属项目"
          min-width="300"
        >
          <template slot-scope="scope">
            <TextTooltipOfArray :content="scope.row.project" :maxlength="2" inline />
          </template>
        </el-table-column>
        <el-table-column
          label="最后访问时间"
          width="160px"
        >
          <template slot-scope="scope">
            {{ scope.row.login_time || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="110px">
          <template slot-scope="scope">
            <StatusButton
              v-auth:change-status
              :status="scope.row.status"
              @click="handleModifyStatus(scope.row, scope.row.id, scope.row.status)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="120px"
          class-name="small-padding"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-auth:edit
              title="点击编辑"
              type="text"
              @click="handleUpdate(scope.row.id)"
            >
              编辑
            </el-button>
            <PopconfirmDelButton
              v-auth:delete
              class="ml-16"
              @confirm="handleDel(scope.row.id)"
            >
              删除
            </PopconfirmDelButton>
          </template>
        </el-table-column>
      </Table>
    </Container>
  </Page>
</template>

<script>
import { readUsers, delUser, updateUser } from '@/api/base/modules/user'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { FilterBar } from '@/components/filter'
import { Button, PopconfirmDelButton } from '@/components/button'
import { DatePicker } from '@/components/date'
import { StatusButton } from '@/components/status-button'
import { Table } from '@/components/table'
import { TextTooltipOfArray } from '@/components/text'

export default {
  name: 'SystemUserList',
  components: {
    Page,
    Container,
    FilterBar,
    Button,
    DatePicker,
    StatusButton,
    Table,
    PopconfirmDelButton,
    TextTooltipOfArray
  },
  data() {
    return {
      list: [],
      loading: true,
      params: {
        username: '',
        real_name: ''
      },
      pagination: {
        page: 1,
        psize: 20,
        total: 0
      }
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    handleReset() {
      this.resetParms()
      this.fetchList()
    },
    handleSearch() {
      this.pagination.page = 1
      this.fetchList()
    },
    onSizeChange(val) {
      this.pagination.page = 1
      this.pagination.psize = val
      this.fetchList()
    },
    onCurrentChange(val) {
      this.pagination.page = val
      this.fetchList()
    },
    handleModifyStatus(row, id, status) {
      updateUser(id, {
        status: 1 - status
      }).then(() => {
        row.status = 1 - status
      })
    },
    handleAdd() {
      this.$router.push({
        name: 'SystemUserCreate',
        params: {
          id: -1
        }
      })
    },
    handleUpdate(id) {
      this.$router.push({
        name: 'SystemUserCreate',
        params: {
          id
        }
      })
    },
    handleRefreshList() {
      this.fetchList()
    },
    handleDel(id) {
      this.loading = true
      delUser(id).then(() => {
        this.$message.success('删除成功')
        this.fetchList()
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    fetchList() {
      this.loading = true
      readUsers(this.getParams()).then(res => {
        this.list = res.data.data.map(item => {
          let project = []
          if (item.user_project_role && item.user_project_role.length > 0) {
            project = item.user_project_role.map(project => {
              const roles = project.user_role ? project.user_role.map(role => role.role_title).join('；') : ''
              return project.title + (roles ? `（${roles}）` : '')
            })
          }
          return {
            ...item,
            login_time: item.login_time || '/',
            project
          }
        })
        this.pagination.total = res.data.total
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    getParams() {
      return {
        ...this.params,
        page: this.pagination.page,
        psize: this.pagination.psize
      }
    },
    resetParms() {
      this.pagination.page = 1
      this.pagination.psize = 20
      this.params.username = ''
      this.params.real_name = ''
      this.params.start_time = ''
      this.params.end_time = ''
    }
  }
}
</script>
