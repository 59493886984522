<!--
 * @Author: Ten
 * @Date: 2022/02/28 11:51
 * @LastEditTime: 2022/03/01 14:19
 * @LastEditors: Ten
 * @Description: 所属类型下拉框
-->
<template>
  <el-cascader
    v-model="currentValue"
    :options="options"
    :props="{ checkStrictly: true }"
    :show-all-levels="false"
    placeholder="请选择"
    filterable
    clearable
    @change="onChange"
  />
</template>

<script>
import { toTree } from '@/utils/tree'

export default {
  name: 'URLTypeSelector',
  props: {
    value: {
      type: [Array],
      default() {
        return []
      }
    }
  },
  data() {
    return {
      currentValue: []
    }
  },
  computed: {
    options() {
      return toTree(this.$store.getters['permissions/buttonMap'])
    }
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val
      },
      immediate: true
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.currentValue)
      this.$emit('change', this.currentValue)
    }
  }
}
</script>
