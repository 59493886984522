<!--
 * @Author: Ten
 * @Date: 2021/09/14 15:16
 * @LastEditTime: 2021/09/23 10:14
 * @LastEditors: Ten
 * @Description: json数据显示
-->
<template>
  <div
    :class="$style.wrapper"
  >
    <json-viewer
      :value="currentContent"
      :copyable="{
        copyText: '复制',
        copiedText: '已复制',
      }"
      :expand-depth="expandDepth"
    />
  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'JSONViewer',
  components: {
    JsonViewer
  },
  props: {
    content: { // json数据
      type: Object,
      default() {
        return {}
      }
    },
    expandDepth: { // 默认展开层级
      type: Number,
      default: 1
    }
  },
  computed: {
    currentContent() {
      return this.content ? this.content : {}
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  :global {
    .jv-container {
      &.jv-light {
        padding: 20px 25px;
        min-height: 100%;
        background-color: #F4F7FF;
        border-radius: $border-radius--base;
        .jv-key {
          color: $color-text--regular;
        }
        .jv-item {
          &.jv-number,
          &.jv-string {
            color: $color-text--regular;
          }
        }
      }
      .jv-code {
        padding: 0;
      }
      .jv-button {
        padding: 0;
        color: $color--primary;
      }
    }
  }
}
</style>
