/*
 * @Author: Ten
 * @Date: 2021/10/10 18:38
 * @LastEditTime: 2022/02/28 11:35
 * @LastEditors: Qy
 * @Description: 请求方法
 */
import axios from 'axios'
import { requestInterceptors, responseInterceptors } from './interceptors'
import { loadRequestInterceptors as _loadRequestInterceptors } from '@/utils/request'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000
})
request.isCancel = axios.isCancel

/**
 * 请求拦截器
 * @param {object} options
 * @return {void}
 * @Author: Ten
 * @Date: 2021/10/11 12:03
 */
export function loadRequestInterceptors(options) {
  _loadRequestInterceptors(request, {
    requestInterceptors,
    responseInterceptors
  }, options)
}

export default function(options) {
  return request({
    ...options,
    cancelToken: new axios.CancelToken(function executor(c) {
      if (options.cancelRequest) {
        options.cancelRequest.cancel = c
      }
    })
  })
}
