<!--
 * @Author: Ten
 * @Date: 2021/08/22 11:59
 * @LastEditTime: 2021/10/26 20:54
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <FilterPanel
    v-model="currentKeyword"
    placeholder="请输入搜索关键词"
  >
    <MenuTree
      :keyword="currentKeyword"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </FilterPanel>
</template>

<script>
import { FilterPanel } from '@/components/filter'
import MenuTree from './MenuTree.vue'

export default {
  name: 'MenuTreePanel',
  components: {
    FilterPanel,
    MenuTree
  },
  props: {
    keyword: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentKeyword: ''
    }
  },
  watch: {
    keyword: {
      handler(val) {
        this.currentKeyword = val
      },
      immediate: true
    }
  }
}
</script>
