<!--
 * @Author: Ten
 * @Date: 2021/08/09 18:02
 * @LastEditTime: 2022/03/01 16:25
 * @LastEditors: Ten
 * @Description: 企微账号同步日志
-->
<template>
  <Page>
    <FilterBar>
      <el-form :inline="true" :model="params" label-width="70px">
        <el-form-item label="同步事项：">
          <el-select v-model="params.type" placeholder="请选择" clearable>
            <el-option
              v-for="item in eventOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="账号：">
          <el-input
            v-model.trim="params.wechat_uid"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="params.status" placeholder="请选择" clearable>
            <el-option
              v-for="item in statusOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="同步日期：">
          <DatePicker
            :start.sync="params.start_time"
            :end.sync="params.end_time"
          />
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
          <el-tooltip content="单次导出不可超出10000条数据" placement="top">
            <Button
              v-auth:export
              type="export"
              class="ml-25"
              @click="handleExport"
            />
          </el-tooltip>
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container>
      <Table
        ref="table"
        :loading="loading"
        :data="list"
        :pagination="{
          total: total,
          page: params.page,
          psize: params.psize
        }"
        row-key="id"
        show-count
        @page-size-change="onSizeChange"
        @page-current-change="onCurrentChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="70"
          :reserve-selection="true"
          fixed
        />
        <el-table-column label="序号" width="135" fixed>
          <template slot-scope="scope">
            {{
              scope.$index | filterForamtSerialNumber(params.page, params.psize)
            }}
          </template>
        </el-table-column>
        <el-table-column label="同步事项" min-width="150">
          <template slot-scope="{ row }">
            <FieldFilter :map="eventOpt" :value="row.type" />
          </template>
        </el-table-column>
        <el-table-column prop="wechat_uid" label="账号" min-width="150" />
        <el-table-column label="状态" min-width="150">
          <template slot-scope="{ row }">
            <span v-if="row.status === 0" :class="$style['success']">成功</span>
            <span v-else :class="$style['danger']">失败：{{row.fail_reason}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="同步时间" min-width="150" />
      </Table>
    </Container>
  </Page>
</template>

<script>
import { readWWXLogs, exportWWXLogs } from '@/api/base/modules/wwxlog'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { toDownload, resolveFileName } from '@/utils/download'
import { FilterBar } from '@/components/filter'
import { DatePicker } from '@/components/date'
import { Button } from '@/components/button'
import { Table } from '@/components/table'
import { FieldFilter } from '@/components/status-button'

export default {
  name: 'SystemWwxLogList',
  components: {
    Page,
    Container,
    FilterBar,
    DatePicker,
    Button,
    Table,
    FieldFilter
  },
  data() {
    return {
      loading: false,
      eventOpt: [
        {
          label: '新增成员',
          value: 1
        },
        {
          label: '删除成员',
          value: 2
        }
      ],
      statusOpt: [
        {
          label: '成功',
          value: 0
        },
        {
          label: '失败',
          value: 1
        }
      ],
      params: {
        type: '',
        wechat_uid: '',
        status: '',
        start_time: '',
        end_time: '',
        page: 1,
        psize: 20
      },
      total: 0,
      list: [],
      selectedList: []
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    handleSearch() {
      this.params.page = 1
      this.$refs.table.clearSelection()
      this.fetchList()
    },
    handleReset() {
      this.resetParams()
      this.fetchList()
    },
    handleExport() { // 导出
      exportWWXLogs(this.getExportParams(true)).then((res) => {
        const url = res.data.url
        toDownload(url, resolveFileName(url))
      })
    },
    handleSelectionChange(selectedList) {
      this.selectedList = selectedList
    },
    onSizeChange(size) {
      this.params.page = 1
      this.params.psize = size
      this.fetchList()
    },
    onCurrentChange(page) {
      this.params.page = page
      this.fetchList()
    },
    fetchList() {
      this.loading = true
      readWWXLogs(this.getParams())
        .then((res) => {
          this.list = res.data.data || []
          this.total = res.data.total || 0
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    getExportParams() {
      let params = {}
      if (this.selectedList.length > 0) {
        params.ids = this.selectedList.map((item) => {
          return item.id
        })
      } else {
        params = {
          ...this.params,
          page: undefined,
          psize: undefined
        }
        for (const key in params) {
          if (params[key] === '') {
            params[key] = undefined
          }
        }
      }
      return params
    },
    getParams() {
      return this.params
    },
    resetParams() {
      this.$refs.table.clearSelection()
      this.params.type = ''
      this.params.wechat_uid = ''
      this.params.status = ''
      this.params.start_time = ''
      this.params.end_time = ''
      this.params.page = 1
      this.params.psize = 20
    }
  }
}
</script>

<style lang="scss" module>
.success {
  color: $color--success;
}
.danger {
  color: $color--danger;
}
</style>
