<!--
 * @Author: Qy
 * @Date: 2021/11/25 17:50
 * @LastEditTime: 2022/03/01 14:43
 * @LastEditors: Ten
 * @Description: 从目标游戏复制角色成员
-->
<template>
  <el-dialog
    ref="dialog"
    :title="currentTitle"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="500px"
    class="demo-dialog"
    @close="onCloseDialog"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :rules="rules"
      :model="form"
      :class="$style.form"
      label-width="130px"
    >
      <el-form-item label="复制方式：" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio v-for="i in typeOpt" :key="i.value" :label="i.value">{{ i.label }}
            <el-tooltip
              effect="light"
              :content="i.tips"
              placement="top"
            >
              <i class="el-icon-question-outline fs-b color--empty" />
            </el-tooltip>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="选择目标的游戏："
        prop="target_project_id"
      >
        <ProjectCheckboxGroup
          :options="projects"
          :border="false"
          :ignore-id="projectId"
          v-model="form.target_project_id"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" v-loading="loading" element-loading-spinner=" ">
      <el-button @click="visible = false">取 消</el-button>
      <el-button
        :loading="loadingSubmit"
        :disabled="loading"
        type="primary"
        @click="handleSubmit"
      >
        {{ loadingSubmit ? '保存中' : '确 定' }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ProjectCheckboxGroup } from '@/components/project'
import { copyProjectUserRole } from '@/api/project/modules/user'

export default {
  name: 'CopyRolesFormDialog',
  components: {
    ProjectCheckboxGroup
  },
  data() {
    return {
      visible: false,
      currentTitle: '从目标游戏复制角色成员',
      loading: false,
      loadingSubmit: false,
      typeOpt: [
        {
          value: 1,
          label: '追加',
          tips: '将目标游戏的成员、角色追加到本游戏中！'
        },
        {
          value: 2,
          label: '覆盖',
          tips: '本游戏中成员、角色将被清空，使用目标游戏的成员！'
        }
      ],
      form: {
        type: 1,
        target_project_id: ''
      },
      rules: {
        target_project_id: [
          {
            required: true,
            trigger: 'change',
            message: '选择目标的游戏'
          }
        ]
      }
    }
  },
  computed: {
    projects() {
      return this.$store.getters['project/data'].filter(project => {
        // 有游戏成员管理菜单且有游戏角色管理菜单权限的应用才能被复制成员角色
        const projectMenuChildren = project.project_menus.find(menu => menu.identify === 'Project')?.children || []
        const projectMemberRoleMenu = projectMenuChildren.filter(child => child.identify === 'ProjectUserList' || child.identify === 'ProjectRoleList')
        return projectMemberRoleMenu.length === 2
      })
    },
    projectId() {
      return this.$store.getters['user/projectId']
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    onCloseDialog() {
      this.loading = false
      this.loadingSubmit = false
      this.type = 1
      this.target_project_id = ''
      this.$refs.form.resetFields()
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(valid)
          this.loadingSubmit = true
          this.loading = true
          copyProjectUserRole(this.getParams()).then(res => {
            this.$message.success('复制成功')
            this.$emit('submit')
            this.loadingSubmit = false
            this.loading = false
            this.visible = false
          }).catch(err => {
            console.log(err)
            this.loadingSubmit = false
            this.loading = false
          })
        }
      })
    },
    getParams() {
      return this.form
    }
  }
}
</script>

<style lang="scss" module>
.form {
  :global {
    .el-form-item {
      margin-bottom: 10px;
    }
  }
}
.project-radio-group {
  display: flex;
  flex-direction: column;
  :global {
    .el-radio {
      line-height: 36px;
    }
  }
}
</style>
