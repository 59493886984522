<!--
 * @Author: Ten
 * @Date: 2022/02/28 11:51
 * @LastEditTime: 2022/03/01 16:24
 * @LastEditors: Ten
 * @Description: 项目管理
-->
<template>
  <Page>
    <FilterBar>
      <el-form :inline="true" :model="params">
        <el-form-item label="项目名称：">
          <el-input v-model.trim="params.title" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="添加日期：">
          <DatePicker :start.sync="params.start_time" :end.sync="params.end_time" />
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container>
      <template #header>
        <Button v-auth:create type="create" @click="handleAdd">添加项目</Button>
      </template>
      <Table
        :loading="loading"
        :data="list"
        :pagination="pagination"
        @page-size-change="onSizeChange"
        @page-current-change="onCurrentChange"
      >
        <el-table-column label="序号" min-width="70" fixed>
          <template slot-scope="scope">
            {{ scope.$index | filterForamtSerialNumber(pagination.page, pagination.psize) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          label="项目名称"
          min-width="200"
          fixed
        />
        <el-table-column
          label="备注"
          min-width="200"
        >
          <template slot-scope="scope">
            <TextTooltip :content="scope.row.desc" />
          </template>
        </el-table-column>
        <el-table-column
          prop="appid"
          label="APPID"
          min-width="150"
        />
        <el-table-column
          prop="key"
          label="AppKey"
          min-width="150"
        />
        <el-table-column label="AppSecret" min-width="400">
          <template slot-scope="scope">
            {{ scope.row.secret }}
            <i
              title="点击刷新AppSecret"
              class="el-icon-refresh secret-btn"
              @click="handleUpdateSecret(scope.row.id)"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="添加时间"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.created_at | filterFormatDateOfTable }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button v-auth:edit type="text" @click="handleEditPlatform(scope.row.id)">
              编辑
            </el-button>
            <PopconfirmDelButton
              v-auth:delete
              class="ml-16"
              @confirm="handleDel(scope.row.id)"
            >
              删除
            </PopconfirmDelButton>
          </template>
        </el-table-column>
      </Table>
    </Container>
    <PlatformFormDialog ref="platformFormDialog" @submit="handleRefreshList" />
    <SecretFormDialog ref="secretFormDialog" @submit="handleRefreshList" />
  </Page>
</template>

<script>
import { readProjects, delProject } from '@/api/base/modules/project'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { FilterBar } from '@/components/filter'
import { DatePicker } from '@/components/date'
import { Button, PopconfirmDelButton } from '@/components/button'
import { Table } from '@/components/table'
import { TextTooltip } from '@/components/text'
import PlatformFormDialog from './comps/FormDialog.vue'
import SecretFormDialog from './comps/SecretFormDialog.vue'

export default {
  name: 'SystemProjectList',
  components: {
    Page,
    Container,
    FilterBar,
    DatePicker,
    Button,
    Table,
    TextTooltip,
    PlatformFormDialog,
    SecretFormDialog,
    PopconfirmDelButton
  },
  data() {
    return {
      loading: false,
      params: {
        title: '',
        start_time: '',
        end_time: ''
      },
      pagination: {
        page: 1,
        psize: 20,
        total: 0
      },
      list: []
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    handleSearch() {
      this.pagination.page = 1
      this.fetchList()
    },
    handleRefreshList(id) {
      if (!id) {
        this.resetParms()
      }
      this.fetchList()
    },
    handleReset() {
      this.resetParms()
      this.fetchList()
    },
    handleAdd() {
      this.$refs.platformFormDialog.open()
    },
    handleEditPlatform(platformId) {
      this.$refs.platformFormDialog.open(platformId)
    },
    handleDel(platformId) {
      delProject(platformId).then(() => {
        this.$message.success('删除成功')
        this.fetchList()
      }).catch(err => {
        console.log(err)
      })
    },
    onSizeChange(size) {
      this.pagination.page = 1
      this.pagination.psize = size
      this.fetchList()
    },
    onCurrentChange(page) {
      this.pagination.page = page
      this.fetchList()
    },
    handleUpdateSecret(platformId) {
      this.$refs.secretFormDialog.open(platformId)
    },
    fetchList() {
      this.loading = true
      readProjects(this.getParams())
        .then(res => {
          this.list = res.data.data || []
          this.pagination.total = res.data.total || 0
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getParams() {
      return {
        ...this.params,
        page: this.pagination.page,
        psize: this.pagination.psize
      }
    },
    resetParms() {
      this.params.title = ''
      this.params.start_time = ''
      this.params.end_time = ''
      this.pagination.page = 1
      this.pagination.psize = 20
    }
  }
}
</script>

<style lang="scss" scoped>
.secret-btn {
  vertical-align: middle;
  font-size: 16px;
  font-weight: bold;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
}
</style>
