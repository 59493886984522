<!--
 * @Author: Ten
 * @Date: 2021/08/09 16:19
 * @LastEditTime: 2022/03/01 16:20
 * @LastEditors: Ten
 * @Description: 个人信息页
-->
<template>
  <Page>
    <Container inner>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        :class="$style.form"
      >
        <Section>
          <template #header>基础信息</template>
          <template>
            <el-form-item label="名称：" prop="real_name">
              <el-input
                v-model.trim="form.real_name"
                maxlength="20"
                clearable
              />
            </el-form-item>
            <el-form-item label="账号：">{{ username }}</el-form-item>
          </template>
        </Section>
        <Section>
          <template #header>账号安全</template>
          <template>
            <el-form-item label="原密码：" prop="original_password">
              <el-input
                type="password"
                v-model.trim="form.original_password"
                maxlength="30"
                placeholder="请输入原密码"
                clearable
              />
            </el-form-item>
            <el-form-item label="新密码：" prop="password">
              <el-input
                type="password"
                v-model.trim="form.password"
                autocomplete="new-password"
                maxlength="30"
                placeholder="请输入6-30新密码"
                clearable
              />
            </el-form-item>
            <el-form-item label="确认密码：" prop="newPassword">
              <el-input
                type="password"
                v-model.trim="form.newPassword"
                maxlength="30"
                placeholder="请再次输入新密码"
                clearable
              />
            </el-form-item>
          </template>
        </Section>
      </el-form>
      <template #footer>
        <div class="tx-c">
          <el-button
            type="primary"
            :loading="loadingSubmit"
            @click="handleSubmit"
          >
            {{ loadingSubmit ? '保存中' : '保 存' }}
          </el-button>
        </div>
      </template>
    </Container>
  </Page>
</template>

<script>
import { mapGetters } from 'vuex'
import { updateUser } from '@/api/base/modules/user'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { Section } from '@/components/section'

export default {
  name: 'Userinfo',
  components: {
    Page,
    Container,
    Section
  },
  data() {
    return {
      loadingSubmit: false,
      form: {
        real_name: '',
        original_password: '',
        password: '',
        newPassword: ''
      },
      rules: {
        real_name: [
          {
            required: true,
            validator: (rule, value, callback) => {
              value = value?.trim()
              if (!value || (value && (value.length < 2 || value.length > 20))) {
                callback(new Error('请输入成员名称，2-20字'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        original_password: [
          {
            validator: (rule, value, callback) => {
              if (value || this.form.password || this.form.newPassword) {
                if (!value) {
                  callback(new Error('请输入原密码'))
                } else if (value.length < 6) {
                  callback(new Error('请输入6-30位原密码'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (this.form.original_password || this.form.newPassword) {
                if (!value) {
                  callback(new Error('请输入新密码'))
                } else if (value.length < 6) {
                  callback(new Error('请输入6-30位新密码'))
                } else if (this.form.original_password === value) {
                  callback(new Error('新密码不可与原密码一致，请重新输入'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            validator: (rule, value, callback) => {
              if (this.form.original_password || this.form.newPassword) {
                if (!value) {
                  callback(new Error('请再次输入新密码'))
                } else if (value.length < 6) {
                  callback(new Error('请再次输入6-30位新密码'))
                } else if (this.form.newPassword !== this.form.password) {
                  callback(new Error('确认新密码与新密码不一致，请重新输入'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      username: 'user/name',
      userId: 'user/id'
    })
  },
  created() {
    this.form.real_name = this.$store.getters['user/realname']
  },
  methods: {
    handleSubmit() { // 保存事件
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.fetchSubmit()
        }
      })
    },
    async fetchSubmit() { // 提交保存个人信息
      try {
        this.loadingSubmit = true
        const submitForm = {
          ...this.form
        }
        if (!submitForm.original_password || !submitForm.password) {
          submitForm.original_password = undefined
          submitForm.password = undefined
        }
        submitForm.newPassword = undefined
        await updateUser(this.userId, submitForm)
        this.$store.commit('user/setNickname', this.form.real_name)
        this.$store.commit('user/setRealname', this.form.real_name)
        this.form.original_password = ''
        this.form.password = ''
        this.form.newPassword = ''
        this.$message.success('保存成功')
        if (submitForm.password) {
          setTimeout(() => { // 成功修改密码后自动跳转登录
            this.$store.dispatch('user/logout', 'login')
          }, 1000)
        }
        this.loadingSubmit = false
      } catch (err) {
        console.log(err)
        this.loadingSubmit = false
      }
    }
  }
}
</script>

<style lang="scss" module>
.form {
  padding-top: 8px;
  :global {
    .el-form-item {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .el-input {
      max-width: 518px;
    }
  }
}
</style>
