<!--
 * @Author: Ten
 * @Date: 2021/07/27 10:08
 * @LastEditTime: 2021/07/29 10:47
 * @LastEditors: Ten
 * @Description: 二次确认框删除按钮
-->
<template>
  <el-popconfirm
    :title="title"
    icon="el-icon-info"
    confirm-button-type="danger"
    cancel-button-type="default"
    @confirm="handleConfirm"
  >
    <el-button
      v-auth:delete="'area'"
      slot="reference"
      :type="type"
      :disabled="disabled"
    >
      <slot />
    </el-button>
  </el-popconfirm>
</template>

<script>
export default {
  name: 'PopconfirmDelButton',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    title: {
      type: String,
      default: '确定要删除吗？'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>
