<!--
 * @Author: Qy
 * @Date: 2021/10/26 15:51
 * @LastEditTime: 2021/10/28 19:30
 * @LastEditors: Qy
 * @Description:执行打印组件
-->
<template>
  <div ref="printBoxRef" id="printBox" class="printBox_wrapper">
    <PrintView
      v-for="(info, infoIndex) in tplData"
      :key="infoIndex"
      :info="info"
    />
  </div>
</template>

<script>
import PrintView from './PrintView.vue'
import printJS from 'print-js'
export default {
  name: 'PrintFilesBox',
  components: { PrintView },
  props: {
    tplData: Array
  },
  data() {
    return {}
  },
  methods: {
    printFiles() {
      printJS({
        printable: this.$refs.printBoxRef,
        type: 'html',
        targetStyles: ['*'], // 繼承原來的所有樣式
        documentTitle: 'Indra应聘登记表',
        scanStyles: false,
        css: ['./styles/print.css']
      })
    }
  }
}
</script>
