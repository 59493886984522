<!--
 * @Author: Qy
 * @Date: 2021/10/22 14:52
 * @LastEditTime: 2022/03/16 19:21
 * @LastEditors: Qy
 * @Description:详情弹框
-->
<template>
  <el-dialog
    width="930px"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="onCloseDialog"
    custom-class="resume-info-dialog"
    :show-close="false"
  >
    <div slot="title" class="dialog-title">
      <div class="title-text">{{ title }}</div>
      <div class="button-right-wrapper">
        <el-button
          type="text"
          icon="el-icon-printer"
          class="btn"
          @click="handlePrint"
          >打印</el-button
        >
        <el-button
          type="text"
          icon="el-icon-close"
          class="btn"
          @click="dialogVisible = false"
          >关闭</el-button
        >
      </div>
    </div>
    <div class="resume-info-box" v-loading="loading">
      <PrintView
        class="resume-info"
        v-for="(info, infoIndex) in tplData"
        :key="infoIndex"
        :info="info"
      />
    </div>
    <span slot="footer">
      <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getResumeInfos } from '@/api/biz/modules/job'
import PrintView from './PrintView.vue'

export default {
  name: 'ResumeInfoDialog',
  components: { PrintView },
  props: {
    title: String
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      id: '',
      tplData: []
    }
  },
  methods: {
    open(id) {
      this.id = id
      this.getResumeInfo(id)
      this.dialogVisible = true
    },
    getResumeInfo(id) {
      this.loading = true
      getResumeInfos({ ids: id })
        .then((res) => {
          const { data } = res
          this.tplData = data
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    onCloseDialog() {
      this.id = ''
      this.info = {}
      this.loading = false
      this.dialogVisible = false
    },
    handlePrint() {
      this.$emit('print', this.id)
    }
  }
}
</script>
<style lang="scss">
.resume-info-dialog {
  .resume-info-box {
    .resume-info {
      h1 {
        margin: 0;
      }
      h3 {
        margin-block-start: 0;
        margin-block-end: 0;
      }
      &.print_view_box {
        .info_wrapper {
          padding: 20px 40px 0;
          .info-title {
            font-weight: 500;
            color: #223345;
            font-size: 20px;
            text-align: center;
            margin-bottom: 12px;
          }
          .form-title {
            border-left: #3481fc 3px solid;
            color: #223345;
            padding: 0 13px;
            font-size: 16px;
            font-weight: 500;
            color: #223345;
            line-height: initial;
            margin-bottom: 16px;
          }
          .base-info-descrip {
            margin-bottom: 32px;
            .label {
              background-color: #f6f7fb;
              color: #4e5c6a;
              width: 110px;
              white-space: pre;
              font-size: 14px;
            }
            .content {
              background-color: #fff;
              color: #223345;
              width: 250px;
            }
            &.el-descriptions {
              .is-bordered {
                .el-descriptions-item__cell {
                  border: 1px solid #edf1f7;
                }
              }
            }
          }
          .exp-info-wrap {
            .item-card {
              display: flex;
              flex-direction: column;
              margin-bottom: 12px;
              .item-num {
                font-weight: 500;
                color: #223345;
                font-size: 14px;
                margin-bottom: 5px;
              }
              &:last-child {
                margin-bottom: 32px;
              }
            }
          }
          .exp-info-descrip {
            .label {
              background-color: #f6f7fb;
              color: #4e5c6a;
              width: 160px;
              font-size: 14px;
            }
            .content {
              background-color: #fff;
              color: #223345;
              width: 250px;
            }
            &.el-descriptions {
              .is-bordered {
                .el-descriptions-item__cell {
                  border: 1px solid #edf1f7;
                }
              }
            }
          }
          .print-table {
            margin-bottom: 32px;
          }
          table {
            font-size: 14px;
            color: #223345;
            border-collapse: collapse;
            table-layout: auto;
            width: 100%;
            text-align: left;
            border: 1px solid #edf1f7;
            border-radius: 4px;
            thead {
              line-height: 40px;
              tr {
                background-color: #f6f7fb;
                line-height: 40px;
                .too-long {
                  width: 100px !important;
                  white-space: normal;
                  line-height: 15px;
                  padding: 2px 0;
                }
              }
              td {
                white-space: nowrap;
                padding: 0 5px;
                color: #4e5c6a;
                font-weight: bold;
              }
            }
            tbody {
              tr {
                border-top-width: 1px;
                border-top-style: solid;
                border-top-color: #edf1f7;
                line-height: 23px;
                background: #fff;
              }
              td {
                padding: 5px 10px;
                width: 100%;
                word-break: break-all;
              }
            }
          }
        }
        .declar_wrapper {
          font-size: 14px;
          line-height: 20px;
          border-top: 1px solid #edf1f7;
          padding: 32px;
          .p1 {
            font-weight: 500;
            color: #223345;
            margin-bottom: 10px;
          }
          .p2 {
            color: #4e5c6a;
            margin-bottom: 10px;
          }
          .sign {
            padding-right: 200px;
            text-align: right;
            .s1 {
              font-weight: 600;
              color: #223345;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }

  .el-dialog__header {
    padding: 0;
    .dialog-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-text {
        font-weight: 500;
        color: #223345;
        font-size: 16px;
        padding-left: 16px;
      }
      .button-right-wrapper {
        display: flex;
        .btn {
          border: 0;
          border-radius: 0;
          border-left: 1px solid #ebebeb;
          padding: 16px;
          color: $color-text--regular;
          display: flex;
          align-items: center;
          &:hover {
            color: $color--primary;
          }
        }
      }
    }
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
