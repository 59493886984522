<!--
 * @Author: Qy
 * @Date: 2022/03/15 10:20
 * @LastEditTime: 2022/04/02 14:33
 * @LastEditors: Qy
 * @Description:编辑
-->
<template>
  <el-dialog
    width="1200px"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="onCloseDialog"
    custom-class="resume-edit-dialog"
    :show-close="false"
    v-loading="loading"
  >
    <div slot="title" class="dialog-title">
      <div class="title-text">编辑信息</div>
      <div class="button-right-wrapper">
        <el-button
          type="text"
          icon="el-icon-close"
          class="btn"
          @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="text"
          icon="el-icon-check"
          class="btn"
          :loading="loadingSubmit"
          :disabled="loading"
          @click="applySubmit"
          >保存</el-button
        >
      </div>
    </div>
    <div
      :class="[
        'apply-wrapper',
        isMobile ? 'apply-wrapper-mobile' : 'apply-wrapper-desktop'
      ]"
    >
      <div class="apply-forms-wrapper" id="applyFormsWrapper">
        <div class="forms-wrapper">
          <ApplyForm ref="applyForm" :form="params" :isMobile="isMobile" />
          <PersonForm ref="personForm" :form="params" :isMobile="isMobile" />
          <ContactForm ref="contactForm" :form="params" :isMobile="isMobile" />
          <EducationForm
            ref="educationForm"
            :formModel="eductionFormModel"
            :isMobile="isMobile"
          />
          <ExperienceForm
            ref="experienceForm"
            :formModel="experienceFormModel"
            :isMobile="isMobile"
          />
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        :loading="loadingSubmit"
        :disabled="loading"
        @click="applySubmit"
        >保 存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  ApplyForm,
  ContactForm,
  EducationForm,
  ExperienceForm,
  PersonForm
} from '@client/components/job-comps'
import { getResumeInfos, editResume } from '@/api/biz/modules/job'
import { mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'FormDialog',
  components: {
    ApplyForm,
    PersonForm,
    ContactForm,
    EducationForm,
    ExperienceForm
  },
  data() {
    return {
      loadingSubmit: false,
      dialogVisible: false,
      loading: false,
      id: 0,
      resumeType: 0, // 申请来源：官网=1；HR推荐=2；
      params: {
        // applyForm
        job_name: '',
        join_time: '',
        current_salary: '',
        expected_salary: '',
        current_jon_status: '',
        origin: '', // 渠道来源
        channel: [], // 自行投递-招聘信息 接口传3,7
        other_channel: '', // 其他渠道
        referee: '', // 推荐人
        // personForm
        name: '',
        sex: '',
        birth_date: '',
        nation: '',
        native_place: '',
        political_status: '',
        is_sign_agreement: '',
        // contactForm
        phone: '',
        email: '',
        wechat: '',
        urgent_phone: '',
        home_place: ''
      },
      eductionFormModel: {
        tableData: [
          {
            education_type: '',
            education_name: '',
            is_fulltime: '',
            start_time: '',
            end_time: '',
            academic_degree: '',
            course: ''
          }
        ],
        rules: {
          education_type: [
            { required: true, message: '请选择', trigger: 'change' }
          ],
          education_name: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          is_fulltime: [
            { required: true, message: '请选择', trigger: 'change' }
          ],
          start_time: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.eduTimesValidator(rule, value, callback, 'start')
              }
            }
          ],
          end_time: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.eduTimesValidator(rule, value, callback, 'end')
              }
            }
          ],
          academic_degree: [
            { required: true, message: '请输入', trigger: 'blur' }
          ],
          course: [{ required: true, message: '请输入', trigger: 'blur' }]
        }
      },
      experienceFormModel: {
        tableData: [
          {
            company_name: '',
            start_date: '',
            end_date: '',
            department: '',
            job_title: '',
            leave_reason: '',
            salary: '',
            auth_phone: ''
          },
          {
            company_name: '',
            start_date: '',
            end_date: '',
            department: '',
            job_title: '',
            leave_reason: '',
            salary: '',
            auth_phone: ''
          },
          {
            company_name: '',
            start_date: '',
            end_date: '',
            department: '',
            job_title: '',
            leave_reason: '',
            salary: '',
            auth_phone: ''
          }
        ],
        rules: {
          company_name: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          start_date: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请选择开始年月')
              }
            },
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.expTimesValidator(rule, value, callback, 'start')
              }
            }
          ],
          end_date: [
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请选择结束年月')
              }
            },
            {
              required: true,
              trigger: 'change',
              validator: (rule, value, callback) => {
                this.expTimesValidator(rule, value, callback, 'end')
              }
            }
          ],
          department: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          job_title: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          leave_reason: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          salary: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ],
          auth_phone: [
            {
              required: true,
              trigger: 'blur',
              validator: (rule, value, callback) => {
                this.expRulesValidator(rule, value, callback, '请输入')
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device
    }),
    isMobile() {
      // return this.device === 'mobile'
      return false
    }
  },
  methods: {
    open(id) {
      this.id = id
      this.getResumeInfo(id)
      this.dialogVisible = true
    },
    onCloseDialog() {
      this.id = 0
      this.loading = false
      this.dialogVisible = false
    },
    // 获取详情
    getResumeInfo(id) {
      this.loading = true
      getResumeInfos({ ids: id })
        .then((res) => {
          const { data } = res
          console.log('详情', data)
          this.resumeType = data[0].type
          this.params.job_name = data[0].job_name
          this.params.join_time = data[0].join_time
          this.params.current_salary = data[0].current_salary
          this.params.expected_salary = data[0].expected_salary
          this.params.current_jon_status = data[0].current_jon_status + ''
          this.params.origin = data[0].origin + ''
          this.params.channel = data[0].channel.split(',')
          this.params.other_channel = data[0].other_channel
          this.params.referee = data[0].referee
          this.params.name = data[0].name
          this.params.sex = data[0].sex + ''
          this.params.birth_date = data[0].birth_date
          this.params.nation = data[0].nation
          this.params.native_place = data[0].native_place
          this.params.political_status = data[0].political_status
          this.params.is_sign_agreement = data[0].is_sign_agreement + ''
          this.params.phone = data[0].phone
          this.params.email = data[0].email
          this.params.wechat = data[0].wechat
          this.params.urgent_phone = data[0].urgent_phone
          this.params.home_place = data[0].home_place
          this.eductionFormModel.tableData = data[0].eduction.map((i) => {
            return {
              education_type: i.education_type + '',
              education_name: i.education_name,
              is_fulltime: i.is_fulltime + '',
              // start_time: `${i.start_time}-01 00:00:00`.replace(/-/g, '/'),
              // end_time: `${i.end_time}-01 00:00:00`.replace(/-/g, '/'),
              start_time: dayjs(i.start_time).format('YYYY/MM/DD HH:mm:ss'),
              end_time: dayjs(i.end_time).format('YYYY/MM/DD HH:mm:ss'),
              academic_degree: i.academic_degree,
              course: i.course
            }
          })
          this.experienceFormModel.tableData = data[0].experience.map((i) => {
            return {
              company_name: i.company_name,
              // start_date: `${i.start_date}-01 00:00:00`.replace(/-/g, '/'),
              // end_date: i.is_now === 1 ? '至今' : `${i.end_date}-01 00:00:00`.replace(/-/g, '/'),
              start_date: dayjs(i.start_date).format('YYYY/MM/DD HH:mm:ss'),
              end_date:
                i.is_now === 1
                  ? '至今'
                  : dayjs(i.end_date).format('YYYY/MM/DD HH:mm:ss'),
              department: i.department,
              job_title: i.job_title,
              leave_reason: i.leave_reason,
              salary: i.salary,
              auth_phone: i.auth_phone
            }
          })
          console.log(data[0].eduction, data[0].experience)
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    eduTimesValidator(rule, value, callback, type) {
      const arr = rule.field.split('.')
      const obj = this.eductionFormModel.tableData[+arr[1]]
      if (!obj.start_time) {
        callback(new Error('请选择开始年月'))
      } else if (!obj.end_time) {
        callback(new Error('请选择结束年月'))
      } else if (
        dayjs(obj.start_time).valueOf() > dayjs(obj.end_time).valueOf()
      ) {
        callback(
          new Error(
            `${
              type === 'start'
                ? '开始时间不可大于结束时间'
                : '结束时间不可小于于开始时间'
            }`
          )
        )
      } else {
        callback()
      }
    },
    expTimesValidator(rule, value, callback, type) {
      const arr = rule.field.split('.')
      const obj = this.experienceFormModel.tableData[+arr[1]]
      console.log(
        '校验',
        dayjs(obj.start_date).valueOf(),
        dayjs(obj.end_date).valueOf(),
        dayjs(obj.start_date).valueOf() > dayjs(obj.end_date).valueOf()
      )
      if (
        (obj.company_name ||
          obj.start_date ||
          obj.end_date ||
          obj.department ||
          obj.job_title ||
          obj.leave_reason ||
          obj.salary ||
          obj.auth_phone) &&
        value !== ''
      ) {
        if (!obj.start_date) {
          callback(new Error('请选择开始年月'))
        } else if (!obj.end_date) {
          callback(new Error('请选择结束年月'))
        } else if (
          dayjs(obj.start_date).valueOf() > dayjs(obj.end_date).valueOf()
        ) {
          callback(
            new Error(
              `${
                type === 'start'
                  ? '开始时间不可大于结束时间'
                  : '结束时间不可小于于开始时间'
              }`
            )
          )
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    expRulesValidator(rule, value, callback, msg) {
      const arr = rule.field.split('.')
      const obj = this.experienceFormModel.tableData[+arr[1]]
      if (
        (obj.company_name ||
          obj.start_date ||
          obj.end_date ||
          obj.department ||
          obj.job_title ||
          obj.leave_reason ||
          obj.salary ||
          obj.auth_phone) &&
        value === ''
      ) {
        callback(new Error(msg))
      } else {
        callback()
      }
    },
    async validate() {
      return await Promise.all([
        this.$refs.applyForm.validate(),
        this.$refs.personForm.validate(),
        this.$refs.contactForm.validate(),
        this.$refs.educationForm.validate(),
        this.$refs.experienceForm.validate()
      ])
    },
    getParams() {
      return this.validate()
        .then(async () => {
          // 获取请求参数
          const p1 = await this.$refs.applyForm.getData()
          const p2 = await this.$refs.personForm.getData()
          const p3 = await this.$refs.contactForm.getData()
          const p4 = await this.$refs.educationForm.getData()
          const p5 = await this.$refs.experienceForm.getData()
          // const type = Number(getQuery('type') || 0) // 申请来源：官网=1；HR推荐=2；
          const params = {
            ...p1,
            ...p2,
            ...p3,
            eduction: p4,
            experience: p5,
            type: this.resumeType
          }
          console.log('参数', params, 'p4', p4, 'p5', p5)
          return params
        })
        .catch((err) => {
          this.$message.error('还有字段尚未填写，请填写完整后再提交')
          const errorTracingPoint =
            document
              .querySelector('.el-form-item.is-error')
              .getBoundingClientRect().top + document.scrollingElement.scrollTop
          if (!this.isMobile) {
            document.scrollingElement.scrollTop =
              errorTracingPoint - (69 + 22 + 240)
          } else {
            document.scrollingElement.scrollTop = errorTracingPoint - 24
          }
          console.log(err)
        })
    },
    // 首次填写表单-提交按钮
    async applySubmit() {
      try {
        this.loadingSubmit = true
        this.loading = true
        const params = await this.getParams()
        console.log(params)
        // 处理channel
        params?.channel.length > 0
          ? (params.channel = params?.channel.join(','))
          : (params.channel = '')
          // 处理教育背景
        const filterEduData = params?.eduction.map((i) => {
          return {
            ...i,
            start_time: dayjs(i.start_time).format('YYYY-MM-DD HH:mm:ss'),
            end_time: dayjs(i.end_time).format('YYYY-MM-DD HH:mm:ss')
          }
        })
        params.eduction = filterEduData
        // 处理工作经历
        const filterData = params?.experience
          .filter((item) => {
            return item.company_name !== ''
          })
          .map((i) => {
            return {
              ...i,
              is_now: i.end_date === '至今' ? 1 : 0,
              start_date: dayjs(i.start_date).format('YYYY-MM-DD HH:mm:ss'),
              end_date: i.end_date === '至今' ? '至今' : dayjs(i.end_date).format('YYYY-MM-DD HH:mm:ss')
            }
          })
        params.experience = filterData
        console.log('editResume', params)
        // 发起请求
        await editResume(this.id, params)
        this.$message.success('保存成功')
        this.$emit('submit')
        this.loadingSubmit = false
        this.dialogVisible = false
      } catch (err) {
        console.log(err)
        this.loadingSubmit = false
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// 桌面端
.apply-wrapper-desktop {
  margin: auto;
  max-width: 1200px;
  .apply-forms-wrapper {
    background-color: #ffffff;
    .forms-wrapper {
      ::v-deep {
        .el-form {
          &.el-form--label-top {
            .el-form-item__label {
              padding: 0;
              line-height: 25px;
            }
          }
          .el-form-item {
            width: 248px;
            margin-right: 48px;
            margin-bottom: 20px;
            &:nth-child(4n) {
              margin-right: 0;
            }
            &.one-line {
              width: 100%;
            }
            &.home-place-item{
                width: 544px;
              }
          }
          &.form {
            margin-top: 20px;
            margin-bottom: 12px;
            //&.exp-form ,
            &.edu-form {
              .el-form-item {
                margin-bottom: 8px;
              }
              .el-form-item__error {
                position: relative;
                text-align: left;
              }
            }
          }
          .el-table {
            .el-form-item {
              width: 100%;
            }
          }
          .channel-group {
            .el-form-item {
              width: auto;
              margin-right: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .bottom-wrapper {
      .tips-wrapper {
        border-top: rgba(0, 0, 0, 0.05) 1px solid;
        padding: 32px 35px 0;
        font-size: 14px;
        p {
          margin-block-start: 5px;
          margin-block-end: 5px;
        }
        .tips {
          font-weight: bold;
          color: #223345;
          margin-bottom: 8px;
        }
        .txt {
          color: #8c94a5;
          line-height: 25px;
        }
      }
      .btns-wrapper {
        text-align: center;
        padding: 48px 0 32px;
      }
    }
  }
  .result-wrapper {
    min-height: 582px;
  }
}
// 移动端
.apply-wrapper-mobile {
  margin: auto;
  .apply-forms-wrapper {
    background-color: #ffffff;
    margin-top: 12px;
    .forms-wrapper {
      padding: 12px;
      ::v-deep {
        .el-form {
          padding-top: 16px;
          .el-form-item__label {
            padding: 0;
            line-height: 25px;
          }
          .el-form-item__content {
            line-height: 20px;
          }
          .el-form-item {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
            &.channel-form-item {
              margin-bottom: 0;
            }

            &:nth-child(4n) {
              margin-right: 0;
            }
            &.one-line {
              width: 100%;
            }
          }
          .el-table {
            .el-form-item {
              width: 100%;
            }
          }
        }
        .pick-time {
          width: 100%;
        }
        .el-radio-group {
          line-height: 25px;
        }
      }
    }
    .bottom-wrapper {
      .tips-wrapper {
        border-top: rgba(0, 0, 0, 0.05) 1px solid;
        padding: 12px;
        font-size: 14px;
        line-height: 20px;
        p {
          margin-block-start: 5px;
          margin-block-end: 5px;
        }
        .tips {
          font-weight: bold;
          color: #223345;
          margin-bottom: 8px;
        }
        .txt {
          color: #8c94a5;
        }
      }
      .btns-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        text-align: center;
        padding: 24px 12px;
        .btn {
          display: inline-block;
          width: 48%;
        }
      }
    }
  }
  .result-wrapper {
    height: calc(100vh - 69px);
    ::v-deep {
      .success-img-wrap {
        .success-img {
          width: 150px;
          height: 100px;
        }
      }
      .txt {
        font-size: 16px;
        font-weight: 600;
      }
      .btns {
        padding: 0 12px;
        .btn {
          display: block;
          width: 100%;
          margin: 0 0 12px;
        }
      }
    }
  }
}
::v-deep {
  .el-input.is-disabled .el-input__inner,
  .el-range-editor.is-disabled input {
    opacity: 1;
    color: #8c94a5;
    -webkit-text-fill-color: #8c94a5;
  }
}
@media screen and (max-width: 1200px) {
  .apply-wrapper-desktop {
    .forms-wrapper {
      ::v-deep {
        .el-form {
          .el-form-item {
            margin-right: 48px !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.resume-edit-dialog {
  .el-dialog__header {
    padding: 0;
    .dialog-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-text {
        font-weight: 500;
        color: #223345;
        font-size: 16px;
        padding-left: 16px;
      }
      .button-right-wrapper {
        display: flex;
        .btn {
          border: 0;
          border-radius: 0;
          border-left: 1px solid #ebebeb;
          padding: 16px;
          color: $color-text--regular;
          display: flex;
          align-items: center;
          &:hover {
            color: $color--primary;
          }
        }
      }
    }
  }
  .el-dialog__body {
    padding: 24px 32px;
  }
}
</style>
