/*
 * @Author: Ten
 * @Date: 2021/08/09 10:38
 * @LastEditTime: 2021/10/11 15:57
 * @LastEditors: Ten
 * @Description: 工具函数
 */
/**
 * 防抖函数
 * @param {function} fn 函数
 * @param {number} delay 延迟毫秒数
 * @return {function}
 * @Author: Ten
 * @Date: 2021/10/11 15:30
 */
export function debounce(fn, delay = 500) {
  let timer = null
  return function() {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments)
      timer = null
    }, delay)
  }
}

/**
 * 节流函数
 * @param {function} fn 函数
 * @param {number} delay 延迟毫秒数
 * @return {function}
 * @Author: Ten
 * @Date: 2021/10/11 15:30
 */
export function throttle(fn, delay = 100) {
  let timer = null
  return function () {
    if (timer) {
      return
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments)
      timer = null
    }, delay)
  }
}

/**
 * 单例函数（只执行一次）
 * @param {function} fn 函数
 * @return {function}
 * @Author: Ten
 * @Date: 2021/10/11 15:57
 */
export function once(fn) {
  let done = false
  return function () {
    if (!done) {
      done = true
      fn.apply(this, arguments)
    }
  }
}
