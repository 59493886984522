<!--
 * @Author: Ten
 * @Date: 2021/07/20 16:51
 * @LastEditTime: 2021/07/22 11:05
 * @LastEditors: Ten
 * @Description: 面板
-->
<template>
  <div class="panel">
    <div v-if="$slots.header" class="panel-header">
      <slot name="header" />
    </div>
    <div class="panel-body">
      <slot />
    </div>
    <div v-if="$slots.footer" class="panel-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Panel'
}
</script>

<style lang="scss" scoped>
.panel {
  border-radius: 4px;
  line-height: normal;
  border: 1px solid $border-color--base;
  .panel-header {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    background-color: #F3F3F5;
    font-size: 14px;
    font-weight: 500;
    color: $color-text--primary;
  }
  .panel-body {
    display: flex;
    padding: 20px 12px;
  }
  .panel-footer {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 12px;
    border-top: 1px solid $border-color--base;
  }
}
</style>
