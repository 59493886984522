<!--
 * @Author: Ten
 * @Date: 2021/08/12 11:13
 * @LastEditTime: 2021/12/01 14:41
 * @LastEditors: Qy
 * @Description: 角色多选框组
-->
<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.9)"
    :class="[$style.wrapper, {'flex flex-middle': filterOptions.length === 0}]"
  >
    <el-checkbox-group
      v-model="currentValue"
      class="flex flex-column"
      @change="onChanged"
    >
      <div
        v-for="(option, index) in filterOptions"
        :key="option.id"
        @click="handleClick($event, option)"
      >
        <el-checkbox
          :label="option.id"
          :class="{
            [$style['checkbox--round']]: !multiple,
            [$style['checkbox--active']]: option.id === clickId
          }"
        >
          <slot v-bind="{ data: option, index }">
            {{ option.title }}
          </slot>
        </el-checkbox>
      </div>
    </el-checkbox-group>
    <div v-show="!loading && filterOptions.length === 0" class="el-tree__empty-block">
      <span class="el-tree__empty-text">暂无数据</span>
    </div>
  </div>
</template>

<script>
import { readAllRoles } from '@/api/base/modules/role'

export default {
  name: 'RoleCheckboxGroup',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    multiple: {
      // 是否多选
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    keyword: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      options: [],
      currentValue: [],
      clickId: 0
    }
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val
      },
      immediate: true
    },
    currentValue(val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  },
  computed: {
    filterOptions() {
      return this.options.filter((option) => {
        return option.title.includes(this.keyword)
      })
    }
  },
  created() {
    this.loading = true
    readAllRoles(this.data)
      .then(res => {
        this.options = res.data || []
        this.loading = false
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
  },
  methods: {
    handleClick(event, option) {
      if (
        this.$listeners['label-click'] &&
        event.target &&
        event.target.tagName.toLowerCase() !== 'input' &&
        !event.target.className.includes('el-checkbox__inner')
      ) {
        event.preventDefault()
        event.stopPropagation()
        this.clickId = option.id
        this.$emit('label-click', option)
      }
    },
    onChanged() {
      if (!this.multiple) {
        const value = this.currentValue[this.currentValue.length - 1]
        if (value) {
          this.currentValue = [value]
        } else {
          this.currentValue = []
        }
      }
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  min-height: 300px;
}
.checkbox--round {
  :global {
    .el-checkbox__inner {
      border-radius: 50%;
    }
  }
}
.checkbox--active {
  :global {
    .el-checkbox__label {
      color: $color--primary !important;
    }
  }
}
</style>
