<!--
 * @Author: Ten
 * @Date: 2021/08/09 15:38
 * @LastEditTime: 2022/03/01 16:17
 * @LastEditors: Ten
 * @Description: 项目角色管理
-->
<template>
  <Page>
    <FilterBar>
      <el-form :inline="true" :model="params">
        <el-form-item label="角色名称：">
          <el-input
            v-model="params.title"
            placeholder="请输入"
            maxlength="10"
            clearable
          />
        </el-form-item>
        <el-form-item label="最后更新日期：">
          <DatePicker :start.sync="params.start_time" :end.sync="params.end_time" />
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container>
      <template #header>
        <Button v-auth:create type="create" @click="handleAdd">添加角色</Button>
      </template>
      <Table
        :loading="loading"
        :data="list"
        :pagination="{
          total: total,
          page: params.page,
          psize: params.psize
        }"
        @page-size-change="onSizeChange"
        @page-current-change="onCurrentChange"
      >
        <el-table-column
          label="序号"
          min-width="150"
          fixed
        >
          <template slot-scope="scope">
            {{ scope.$index | filterForamtSerialNumber(params.page, params.psize) }}
          </template>
        </el-table-column>
        <el-table-column
          label="角色名称"
          min-width="150px"
          prop="title"
          fixed
        >
          <template slot-scope="{ row }">
            <span>{{ row.title }}</span>
            <Tag v-if="row.type === 0" size="mini">系统角色</Tag>
          </template>
        </el-table-column>
        <el-table-column label="备注" min-width="298">
          <template slot-scope="scope">
            <TextTooltip :content="scope.row.desc" />
          </template>
        </el-table-column>
        <el-table-column
          label="最后更新时间"
          min-width="224"
          prop="updated_at"
        />
        <el-table-column
          label="操作"
          width="140"
          class-name="small-padding"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button
              v-auth:edit
              title="点击编辑"
              type="text"
              @click="handleEdit(row.id)"
              :disabled="row.type === 0"
            >
              编辑
            </el-button>
            <PopconfirmDelButton
              v-auth:delete
              class="ml-16"
              :disabled="row.type === 0"
              @confirm="handleDel(row.id)"
            >
              删除
            </PopconfirmDelButton>
          </template>
        </el-table-column>
      </Table>
    </Container>
  </Page>
</template>

<script>
import { readRoles, delRole } from '@/api/project/modules/role'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { FilterBar } from '@/components/filter'
import { Button, PopconfirmDelButton } from '@/components/button'
import { Table } from '@/components/table'
import { DatePicker } from '@/components/date'
import { TextTooltip } from '@/components/text'
import { Tag } from '@/components/tag'
import { ListEventBusMixin } from '@/mixins/list-event-bus'
import { EventBus } from '@/utils/event-bus'

export default {
  name: 'ProjectRole',
  components: {
    Page,
    Container,
    FilterBar,
    Button,
    PopconfirmDelButton,
    Table,
    TextTooltip,
    DatePicker,
    Tag
  },
  mixins: [ListEventBusMixin],
  data() {
    return {
      list: [],
      loading: true,
      params: {
        page: 1,
        psize: 20,
        title: '',
        start_time: '',
        end_time: ''
      },
      total: 0
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    getParams() {
      return this.params
    },
    fetchList() {
      this.loading = true
      readRoles(this.getParams()).then((res) => {
        this.list = res.data.data
        this.total = res.data.total
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    handleSearch() {
      this.params.page = 1
      this.fetchList()
    },
    handleRefresh(source = '') {
      if (source === 'del' && this.list.length === 1 && this.params.page > 1) {
        this.params.page -= 1
      }
      this.fetchList()
    },
    handleReset() {
      this.params.page = 1
      this.params.psize = 20
      this.params.title = ''
      this.params.start_time = ''
      this.params.end_time = ''
      this.fetchList()
    },
    onSizeChange(val) {
      this.params.page = 1
      this.params.psize = val
      this.fetchList()
    },
    onCurrentChange(val) {
      this.params.page = val
      this.fetchList()
    },
    handleAdd() {
      this.$router.push({
        name: 'ProjectRoleCreate',
        params: {
          id: -1
        }
      })
    },
    handleEdit(id) {
      this.$router.push({
        name: 'ProjectRoleCreate',
        params: {
          id
        }
      })
    },
    handleDel(id) {
      this.loading = true
      delRole(id).then(() => {
        this.$message.success('删除成功')
        this.handleRefresh('del')
        EventBus.$emit('SystemRoleList', {
          type: 'refresh'
        })
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    }
  }
}
</script>
