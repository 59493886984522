<!--
 * @Author: Ten
 * @Date: 2021/10/26 19:42
 * @LastEditTime: 2022/03/01 14:40
 * @LastEditors: Ten
 * @Description: 添加/编辑项目用户
-->
<template>
  <Container
    v-loading="loading"
    back
    inner
  >
    <el-form
      ref="form"
      :rules="rules"
      :model="form"
    >
      <el-row>
        <el-col :sm="24" :md="12" :lg="12" :offset="12" :pull="12">
          <el-form-item label="成员名称：" prop="real_name" label-width="90px">
            <el-input
              v-model.trim="form.real_name"
              maxlength="20"
              placeholder="请输入2-20位成员名称"
              clearable
            />
          </el-form-item>
          <el-form-item label="成员账号：" :prop="id === -1 ? 'username' : ''" label-width="90px">
            <Input
              v-if="id === -1"
              v-model.trim="form.username"
              type="trim-all"
              maxlength="20"
              placeholder="请输入6-20位成员账号"
              clearable
            />
            <template v-else>{{ form.username }}</template>
          </el-form-item>
          <el-form-item label="密码：" prop="password" label-width="90px">
            <Input
              v-model.trim="form.password"
              type="trim-all"
              maxlength="30"
              placeholder="请输入6-30位密码"
              clearable
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :sm="24" :md="24" :lg="12">
          <el-form-item label="角色：" prop="roles" label-width="90px">
            <RoleCheckboxPanel
              v-model="form.roles"
              title="游戏角色"
              placeholder="请输入搜索关键词"
              :data="{
                project: projectKey,
                project_id: projectId
              }"
              :view-style="{
                'height': '340px'
              }"
              @label-click="handleLabelClick"
                :multiple="true"
            />
          </el-form-item>
        </el-col>
        <el-col v-show="role.id !== 0" :sm="24" :md="24" :lg="12">
          <el-form-item label-width="90px">
            <div class="flex flex-between">
              <span class="ellipsis" :title="role.name">{{ role.name }}：</span>
              <el-link
                v-show="hasRoleCreateAuth && role.id !== 0"
                type="primary"
                @click="handleOpenRole(role.id)"
              >
                编辑
              </el-link>
            </div>
            <div>
              <Card
                title="功能权限"
                :view-style="{
                  'height': '340px'
                }"
              >
                <MenuTree
                  v-model="role.rules"
                  is-slot
                  :editable="false"
                  :default-expand-all="true"
                  empty-text="无权限"
                />
              </Card>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <div class="flex flex-center flex-middle">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button :loading="loadingSubmit" type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </template>
  </Container>
</template>

<script>
import { readUser, createUser, updateUser } from '@/api/project/modules/user'
import { Container } from '@/components/container'
import { RoleCheckboxPanel } from '@/components/role'
import { MenuTree } from '@/components/menu'
import { Card } from '@/components/card'
import { Input } from '@/components/input'
import { EventBus } from '@/utils/event-bus'
import { generateRules } from '@/components/menu/utils'

export default {
  name: 'ProjectUserCreate',
  components: {
    Container,
    RoleCheckboxPanel,
    MenuTree,
    Card,
    Input
  },
  data() {
    return {
      id: -1,
      loading: false,
      loadingSubmit: false,
      form: {
        real_name: '',
        username: '',
        password: '',
        roles: []
      },
      role: {
        id: 0,
        name: '',
        rules: [],
        buttons: ''
      },
      rules: {
        real_name: [
          {
            required: true,
            validator: (rule, value, callback) => {
              value = value?.trim()
              if (!value || (value && (value.length < 2 || value.length > 20))) {
                callback(new Error('请输入2-20位成员名称'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        username: [
          {
            required: true,
            validator: (rule, value, callback) => {
              value = value?.trim()
              if (
                !value ||
                (value && (value.length < 6 || value.length > 20))
              ) {
                callback(new Error('请输入6-20位成员账号'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              value = value?.trim()
              if ((rule.required || (this.id !== -1 && value)) && (!value || (value && (value.length < 6 || value.length > 30)))) {
                callback(new Error('请输入6-30位密码'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        roles: [
          {
            required: true,
            message: '请选择角色',
            trigger: 'change'
          }
        ]
      },
      hasRoleCreateAuth: false // 是否有角色编辑权限
    }
  },
  computed: {
    currentSuccessTips() {
      return this.id === -1 ? '添加成功' : '保存成功'
    },
    projectKey() {
      return this.$store.getters['user/projectKey']
    },
    projectId() {
      return this.$store.getters['user/projectId']
    }
  },
  created() {
    this.hasRoleCreateAuth = this.$router.getRoutes().some(route => route.name === 'ProjectRoleCreate')
    this.id = Number(this.$route.params.id) || -1
    if (this.id !== -1) {
      this.loading = true
      // this.rules.username.forEach(item => {
      //   item.required = false
      // })
      this.rules.password.forEach(item => {
        item.required = false
      })
      readUser(this.id).then(res => {
        const { data } = res
        this.form.real_name = data.real_name
        this.form.username = data.username
        let roles = []
        if (data.user_project_role && data.user_project_role.length > 0) {
          const project = data.user_project_role.find(project => {
            return project.id === this.projectId
          })
          roles = project.user_role.map(role => {
            return role.role_id
          })
        }
        this.form.roles = roles || []
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    } else {
      this.rules.password.forEach(item => {
        item.required = true
      })
    }
  },
  methods: {
    handleCancel() {
      this.$history.back.apply(this)
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveUser()
        }
      })
    },
    handleLabelClick(option) {
      const { id, title, menus, buttons } = option
      this.role.id = id
      this.role.name = title
      this.role.rules = generateRules(menus, buttons)
    },
    handleOpenRole(roleId) {
      this.$router.push({
        name: 'ProjectRoleCreate',
        params: {
          id: roleId
        }
      })
    },
    getParams() {
      return {
        ...this.form,
        password: this.form.password || undefined
        // roles: this.form.roles
      }
    },
    async saveUser() {
      try {
        this.loadingSubmit = true
        if (this.id === -1) {
          await createUser(this.getParams())
        } else {
          await updateUser(this.id, this.getParams())
        }
        this.$emit('submit', this.id)
        this.loadingSubmit = false
        this.$message.success(this.currentSuccessTips)
        ;['ProjectUserList', 'SystemUserList'].forEach(eventName => {
          EventBus.$emit(eventName, {
            type: this.id === -1 ? 'reset' : 'refresh'
          })
        })
        this.$history.back.apply(this)
      } catch (err) {
        console.log(err)
        this.loadingSubmit = false
      }
    }
  }
}
</script>

<style lang="scss" module>
.role {
  flex: 1;
  flex-wrap: wrap;
  :global {
    .el-form-item {
      margin-bottom: 0;
      padding-bottom: 20px;
      height: 100%;
      .el-form-item__content {
        height: 100%;
      }
    }
  }
}
</style>
