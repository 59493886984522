<template>
  <div
    class="app-container"
    :style="currentStyle"
    v-bind="$attrs"
  >
    <div
      v-if="back || $slots.header"
      class="app-container__header"
      :class="{'app-container__header--end': !back}"
    >
      <svg-icon
        v-if="back"
        icon-class="arrow"
        class-name="app-container__back-icon"
        @click="handleBack"
      />
      <slot name="header" />
    </div>
    <div
      class="app-container__body"
      :class="{'app-container__body--inner': inner}"
    >
      <slot></slot>
    </div>
    <div
      v-if="footer && $slots.footer"
      class="app-container__footer"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    inner: {
      type: Boolean,
      default: false
    },
    module: {
      type: Boolean,
      default: false
    },
    back: {
      type: Boolean,
      default: false
    },
    footer: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentStyle() {
      if (this.module) {
        return {
          margin: 0,
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }
      return {}
    }
  },
  methods: {
    handleBack() {
      this.$history.back.apply(this)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  background-color: $box-bg-color;
  border-radius: $border-radius--base;
  z-index: 1;
  .app-container__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px $box-padding;
    border-bottom: 1px solid $box-border-color--base;
    &.app-container__header--end {
      justify-content: flex-end;
    }
    .app-container__back-icon {
      font-size: 20px;
      color: $color-text--regular;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .app-container__body {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 24px 0;
    &.app-container__body--inner {
      padding-left: $box-padding;
      padding-right: $box-padding;
    }
  }
  .app-container__footer {
    padding: 20px $box-padding;
    border-top: 1px solid $box-border-color--base;
  }
}
</style>
