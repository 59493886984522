<!--
 * @Author: Ten
 * @Date: 2021/08/22 16:16
 * @LastEditTime: 2021/10/12 14:26
 * @LastEditors: Ten
 * @Description: 卡片
-->
<template>
  <div :class="$style.wrapper">
    <div v-if="title || $slots.header" :class="$style.header">
      <slot name="header">
        {{ title }}
      </slot>
    </div>
    <div :class="$style.body">
      <el-scrollbar :view-style="viewStyle">
        <div :class="$style.inner">
          <slot />
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      default: ''
    },
    viewStyle: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  line-height: normal;
  border: 1px solid $border-color--primary;
  &:first-child {
    border-radius: $border-radius--base 0 0 $border-radius--base;
  }
  &:last-child {
    border-radius: 0 $border-radius--base $border-radius--base 0;
  }
  &:only-child{
    border-radius: $border-radius--base;
  }
}
.wrapper + .wrapper {
  border-left: 0;
}
.header {
  padding: 15px 20px;
  border-bottom: 1px solid $border-color--primary;
  font-size: $font-size--base;
  color: $color-text--primary;
}
.body {
  .inner {
    padding: 5px 15px;
  }
}
</style>
