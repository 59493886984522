<!--
 * @Author: Ten
 * @Date: 2022/02/28 11:51
 * @LastEditTime: 2022/03/01 16:24
 * @LastEditors: Ten
 * @Description: 部门管理
-->
<template>
  <Page>
    <Container>
      <template #header>
        <Button v-auth:create type="create" @click="handleAdd">添加部门</Button>
      </template>
      <Table
        :loading="loading"
        :data="treeList"
        row-key="id"
      >
        <el-table-column
          label="部门名称"
          min-width="100px"
          prop="title"
          fixed
        />
        <el-table-column label="负责人" min-width="100px" prop="leader" />
        <el-table-column
          label="操作"
          min-width="120px"
          class-name="small-padding"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-auth:edit
              title="点击编辑"
              type="text"
              @click="handleUpdate(scope.row.id)"
            >
              编辑
            </el-button>
            <PopconfirmDelButton
              v-auth:delete
              class="ml-16"
              @confirm="handleDel(scope.row.id)"
            >
              删除
            </PopconfirmDelButton>
          </template>
        </el-table-column>
      </Table>
      <FormDialog ref="fromRules" @submit="handleReset" />
    </Container>
  </Page>
</template>
<script>
import { readAllDepartments, delDepartment } from '@/api/base/modules/department'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { Button, PopconfirmDelButton } from '@/components/button'
import { Table } from '@/components/table'
import FormDialog from './comps/FormDialog'
import { toTree } from '@/utils/tree'

export default {
  name: 'SystemDepartment',
  components: {
    Page,
    Container,
    Button,
    Table,
    PopconfirmDelButton,
    FormDialog
  },
  data() {
    return {
      list: [],
      loading: true
    }
  },
  computed: {
    treeList({ list }) {
      return toTree(list, 0, 'id', 'pid', 'children', {
        delete: false
      })
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      this.loading = true
      readAllDepartments({
        pid: -1
      }).then((res) => {
        this.list = res.data
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    handleAdd() {
      this.$refs.fromRules.open()
    },
    handleUpdate(id) {
      this.$refs.fromRules.open(id)
    },
    handleReset() {
      this.fetchList()
    },
    handleDel(id) {
      this.loading = true
      delDepartment(id).then(() => {
        this.$message.success('删除成功')
        this.fetchList()
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    }
  }
}
</script>
