<!--
 * @Author: Ten
 * @Date: 2021/08/22 11:23
 * @LastEditTime: 2021/10/12 14:10
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <FilterPanel
    v-model="currentKeyword"
    :title="title"
    :placeholder="placeholder"
    :view-style="viewStyle"
  >
    <RoleCheckboxGroup
      :keyword="currentKeyword"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </FilterPanel>
</template>

<script>
import { FilterPanel } from '@/components/filter'
import RoleCheckboxGroup from './RoleCheckboxGroup.vue'

export default {
  name: 'RoleCheckboxPanel',
  components: {
    FilterPanel,
    RoleCheckboxGroup
  },
  props: {
    title: {
      type: String,
      default: '系统角色'
    },
    keyword: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入搜索关键词'
    },
    viewStyle: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      currentKeyword: ''
    }
  },
  watch: {
    keyword: {
      handler(val) {
        this.currentKeyword = val
      },
      immediate: true
    }
  }
}
</script>
