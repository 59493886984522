<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    hide-required-asterisk
  >
    <el-form-item
      prop="file"
      class="image-uploader"
      :class="{
        'image-uploader--one': limit === 1
      }"
    >
      <div class="image-uploader__inner">
        <el-upload
          ref="imageUploader"
          class="image-uploader__form"
          :class="{'image-uploader--hide-upload': hideUpload}"
          name="image"
          list-type="picture-card"
          :action="action"
          :headers="headers"
          :show-file-list="true"
          :file-list="fileList"
          :limit="limit"
          :auto-upload="false"
          :before-upload="onBeforeUpload"
          :on-success="onSuccess"
          :on-error="onError"
          :on-change="onChange"
          :on-remove="onRemove"
          :disabled="disabled"
        >
          <!-- <div v-show="loading" class="image-uploader__loading">
            <div class="image-uploader__loading-tips">图片上传中</div>
            <div class="image-uploader__loading-bar">
              <div class="image-uploader__loading-progress"></div>
            </div>
          </div>
          <div v-show="!loading"> -->
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <el-image
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                :fit="fit"
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
            <!-- <div v-show="status === 0" class="image-uploader__error">
              <svg-icon icon-class="image" class-name="image-uploader__error-icon" />
              <div class="image-uploader__error-tips">图片上传失败</div>
            </div> -->
          <!-- </div> -->
        </el-upload>
        <div v-if="showDefault" class="image-uploader__default">
          <el-image
            v-show="defaultImgPath"
            class="image-uploader__default-img"
            :src="defaultImgPath"
            fit="contain"
          />
          <div class="image-uploader__label">{{ label }}</div>
        </div>
      </div>
      <div class="image-uploader__tips">
        <template v-if="tips">
          {{ tips }}
        </template>
        <slot v-else name="tips" />
      </div>
      <ImageViewer
        :visible.sync="imageViewer.visible"
        :urlList="imageViewer.urlList"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import Vue from 'vue'
import { api, defaultAvatar } from '@/settings'
import { imageCompress } from '@/utils/image'
import { base64ToBlob } from '@/utils/file'
import ImageViewer from './ImageViewer.vue'

export default {
  name: 'ImageUploader',
  components: {
    ImageViewer
  },
  props: {
    limit: {
      type: Number,
      default: 1
    },
    defaultImgPath: {
      type: String,
      default: defaultAvatar
    },
    action: {
      type: String,
      default: api.userIco
      // default: 'http://localhost:8092/api/user-ico'
    },
    label: {
      type: String,
      default: '默认头像'
    },
    tips: {
      type: String,
      default: ''
    },
    full: {
      type: Boolean,
      default: false
    },
    showDefault: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fit: {
      type: String,
      default: 'cover'
    },
    fileList: {
      type: Array,
      default() {
        return []
      }
    },
    message: {
      type: String,
      default: '请上传文件'
    }
  },
  data() {
    return {
      loading: false,
      bus: null,
      headers: {
        'access-token': ''
      },
      imageViewer: {
        visible: false,
        urlList: []
      },
      form: {
        file: ''
      },
      rules: {
        file: [
          {
            required: true,
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (this.fileList.length === 0) {
                callback(new Error(this.message))
              } else {
                callback()
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    hideUpload() {
      return this.fileList.length >= this.limit
    }
  },
  created() {
    this.headers['access-token'] = this.$store.getters['user/token']
    this.bus = new Vue()
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          console.log(valid)
          if (valid) {
            resolve()
          } else {
            reject(new Error('validate error'))
          }
        })
      })
    },
    upload() {
      return new Promise((resolve, reject) => {
        const needUpload = this.fileList.some(item => {
          return item.status === 'ready'
        })
        if (!needUpload) {
          if (this.showDefault && this.limit === 1) {
            const path = this.fileList.length > 0 ? this.fileList[0].url : this.defaultImgPath
            resolve(path)
          } else {
            resolve()
          }
          return
        }
        this.$refs.imageUploader.submit()
        this.bus.$on('success', (path) => {
          this.bus.$off('success')
          resolve(path)
        })
        this.bus.$on('error', (err) => {
          this.$message.error(err.msg)
          this.bus.$off('error')
          reject(err)
        })
      })
    },
    onSuccess(res, file) {
      this.loading = false
      if (res.status === 0 && res.data.image_path) {
        console.log(this.fileList)
        this.$emit('update:fileList', this.fileList.map(item => {
          if (file.uid === item.uid) {
            item.status = 'success'
            item.url = res.data.image_path
          }
          return item
        }))
        this.bus.$emit('success', res.data.image_path)
      } else {
        this.$refs.imageUploader.handleRemove(file)
      }
    },
    onError(err) {
      console.log(err)
      this.loading = false
      this.bus.$emit('error', {
        msg: err.toString()
      })
    },
    onBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        this.loading = true
        imageCompress({
          image: file,
          type: 'png'
        }).then((res) => {
          resolve(base64ToBlob(res.image))
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      })
    },
    onChange(file, fileList) {
      if (!file.raw.type.includes('image/')) {
        this.$message.warning('上传头像图片只能是 JPG/JPEG/PNG/GIF 格式!')
        this.$refs.imageUploader.clearFiles()
        return false
      } else if (!(file.raw.size / 1024 / 1024 < 2)) {
        this.$message.warning('上传头像图片大小不能超过 2MB!')
        this.$refs.imageUploader.clearFiles()
        return false
      }
      console.log('change')
      this.$emit('update:fileList', fileList.map(item => {
        item.status = item.url.indexOf('blob') === 0 ? 'ready' : 'success'
        return item
      }))
      this.$refs.form.clearValidate()
    },
    onRemove(file, fileList) {
      // this.bus.$emit('success', '')
      this.$nextTick(() => {
        this.$emit('update:fileList', fileList)
      })
    },
    handleRemove(file) {
      // this.bus.$emit('success', '')
      this.$refs.imageUploader.handleRemove(file)
    },
    handlePictureCardPreview(file) {
      this.imageViewer.visible = true
      this.imageViewer.urlList = [file.url]
    }
  }
}
</script>

<style lang="scss" scoped>
.image-uploader {
  display: inline-block;
  margin-bottom: 0;
  ::v-deep {
    .el-upload-list__item-status-label {
      display: none;
    }
  }
  &.image-uploader--one {
    ::v-deep {
      .el-upload-list--picture-card {
        .el-upload-list__item {
          margin-right: 0;;
        }
      }
    }
  }
  &.image-uploader--error {
    ::v-deep {
      .el-upload--picture-card {
        position: relative;
        border-color: $color--danger;
        border-style: solid;
      }
    }
  }
  &.image-uploader--loading {
    ::v-deep {
      .el-upload--picture-card {
        border-color: $upload-picture-card-border-color;
      }
      .el-upload-list {
        display: none;
      }
    }
  }
  .image-uploader__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 4px;
  }
  .image-uploader__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 80px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .7);
    border-radius: 0 4px 0 12px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    z-index: 1;
    &:hover {
      cursor: pointer;
    }
  }
  .image-uploader__form,
  .image-uploader__default {
    display: inline-block;
  }
  .image-uploader__default {
    margin-left: 10px;
  }
  .image-uploader__form {
    display: flex;
    &.image-uploader--hide-upload {
      ::v-deep {
        .el-upload--picture-card {
          display: none;
          transition: none;
        }
      }
    }
  }
  .image-uploader__label {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    margin: auto;
    line-height: normal;
    font-size: 12px;
    color: #999;
    z-index: 0;
  }
  .image-uploader__default {
    position: relative;
    width: 90px;
    height: 90px;
    text-align: center;
    border: 1px dashed #ccc;
    border-radius: 4px;
    .image-uploader__default-img {
      position: absolute;
      top: 10%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 60%;
      height: 60%;
    }
  }
  .image-uploader__tips {
    line-height: 20px;
    font-size: 14px;
    color: $color-text--secondary;
  }
  .image-uploader__error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color--danger;
    line-height: normal;
    .image-uploader__error-icon {
      font-size: 24px;
    }
    .image-uploader__error-tips {
      margin-top: 6px;
      font-size: 12px;
    }
  }
  .image-uploader__loading {
    width: 100%;
    line-height: normal;
    .image-uploader__loading-tips {
      font-size: 12px;
      color: $color-text--regular;
    }
    .image-uploader__loading-bar {
      position: relative;
      margin-top: 12px;
      margin-left: auto;
      margin-right: auto;
      width: 85%;
      height: 2px;
      border-radius: 1px;
      background-color: $box-border-color--base;
      .image-uploader__loading-progress {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $color--primary;
        border-radius: 1px;
      }
    }
  }
}
</style>
