<!--
 * @Author: Ten
 * @Date: 2021/11/01 14:56
 * @LastEditTime: 2022/03/01 16:29
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <div class="flex flex-column flex-1">
    <div
      v-if="showBreadcrumb || $slots.actions"
      class="flex flex-between flex-middle"
      :class="$style.wrapper"
    >
      <Breadcrumb v-if="showBreadcrumb" :class="$style.breadcrumb" />
      <slot name="actions" />
    </div>
    <slot />
  </div>
</template>

<script>
import Breadcrumb from '@/layout/comps/Breadcrumb/index.vue'

export default {
  name: 'Page',
  components: {
    Breadcrumb
  },
  props: {
    showBreadcrumb: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  margin-top: 20px;
  margin-bottom: 24px;
}
.breadcrumb {
  line-height: 32px;
}
</style>
