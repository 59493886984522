<!--
 * @Author: Qy
 * @Date: 2021/11/29 10:50
 * @LastEditTime: 2022/01/04 19:04
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <div :class="$style.wrapper">
    <div v-if="filterable" :class="$style.filter">
      <el-input
        v-model="keyword"
        :placeholder="filterPlaceholder"
        suffix-icon="el-icon-search"
        class="is-unverify"
      />
    </div>
    <div :class="$style.body">
      <el-scrollbar>
        <div :class="$style.inner">
          <el-tree
            ref="tree"
            :class="$style.tree"
            :data="options"
            node-key="tree_id"
            show-checkbox
            default-expand-all
            :props="defaultProps"
            :indent="24"
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            @check="handleCheckNode"
          >
            <template v-slot:default="{ data }">
              <span class="custom-tree-node">
                <svg-icon
                  v-show="!data.is_user"
                  icon-class="wwx-dep"
                  class="color--primary"
                />
                <svg-icon
                  v-show="data.is_user"
                  icon-class="wwx-user"
                  class="color--primary"
                />
                <span class="ml-5">
                  <!-- {{ data.is_user ? data.id : data.tree_id }}- -->
                  {{ data.is_user ? data.real_name : data.title }}
                </span>
              </span>
            </template>
          </el-tree>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleTreeSelector',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    filterable: {
      type: Boolean,
      default: false
    },
    filterPlaceholder: {
      type: String,
      default: '搜索'
    },
    filterMethod: {
      type: Function,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      keyword: '',
      checked: [],
      selectedList: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      }
    }
  },
  computed: {
    projectId() {
      return this.$store.getters['user/projectId']
    }
  },
  watch: {
    value: {
      handler(val) {
        this.checked = val
      },
      immediate: true,
      deep: true
    },
    keyword(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    // 勾选
    handleCheckNode(checkedObj, checkedData) {
      const checkedUser = checkedData.checkedNodes.filter((i) => {
        return i.is_user === true && !i.user_project_role.find(({ id }) => id === this.projectId)
      })
      const checkedUserIds = checkedUser.map((i) => i.id)
      this.selectedList = checkedUser || []
      this.checked = checkedUserIds || []
      console.log(this.checked, checkedObj, checkedData)
      this.$emit('input', this.checked)
      this.$emit('change', this.checked)
      this.$emit('check', this.selectedList)
    },
    // 过滤
    filterNode(value, data) {
      return (
        data.is_user &&
        !data.user_project_role.find(({ id }) => id === this.projectId) &&
        data.title.includes(value || '')
      )
    },
    // 设置树的值
    setChecked(treeId) {
      this.$refs.tree.setChecked(treeId, false, true)
    },
    filter(keyword = '') {
      this.keyword = keyword
      this.$refs.tree.filter(keyword)
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 480px;
  box-sizing: border-box;
  border-radius: $border-radius--base;
  border: 1px solid $border-color--base;
}
.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  .inner {
    padding-left: 20px;
    padding-right: 20px;
  }
  :global {
    .el-scrollbar {
      height: 100%;
    }
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
.filter {
  padding: 20px 20px 10px;
}

.tree {
  :global {
    .el-tree-node__content {
      height: 36px;
      &  > .el-tree-node__expand-icon {
        order: -2;
        padding: 0 2px;
        color: #333333;
        font-size: 18px;
        &:not(.is-leaf) {
          margin-right: 5px;
        }
      }
    }
    .is-leaf {
      width: 0;
      visibility: hidden;
    }
    span.custom-tree-node {
      flex: 1;
      order: -1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .el-tree-node__content {
      justify-content: space-between;
    }
  }
}
</style>
