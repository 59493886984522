<template>
  <div class="flex flex-column flex-1">
    <Container>
      <template #header>
        <Button v-auth:create type="create" @click="handleAdd">添加配置组</Button>
      </template>
      <Table
        :loading="loading"
        :data="list"
        :pagination="pagination"
        @page-size-change="onSizeChange"
        @page-current-change="onCurrentChange"
      >
        <el-table-column label="ID" min-width="100px" prop="id" />
        <el-table-column label="配置名称" min-width="150px" prop="title" />
        <el-table-column label="创建时间" min-width="150px" prop="created_at" />
        <el-table-column label="更新时间" min-width="150px" prop="updated_at" />
        <el-table-column label="状态" width="110px">
          <template slot-scope="scope">
            <StatusButton
              v-auth:change-status
              :status="scope.row.status"
              @click="handleModifyStatus(scope.$index, scope.row.id, scope.row.status)"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="150px"
          class-name="small-padding"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-auth:edit
              title="点击编辑"
              type="text"
              @click="handleEdit(scope.row.id)"
            >
              编辑
            </el-button>
            <PopconfirmDelButton
              class="ml-16"
              @confirm="handleDel(scope.row.id)"
            >
              删除
            </PopconfirmDelButton>
          </template>
        </el-table-column>
      </Table>
    </Container>
    <FormDialog ref="formDialog" @submit="onConfigGroupFromSubmit" />
  </div>
</template>

<script>
import { readConfigGroups, delConfig, modifyConfigGroupStatus } from '@/api/base/modules/config'
import { Container } from '@/components/container'
import { Button, PopconfirmDelButton } from '@/components/button'
import { Table } from '@/components/table'
import { StatusButton } from '@/components/status-button'
import FormDialog from './comps/FormDialog.vue'

export default {
  name: 'ConfigGroup',
  components: {
    Container,
    Button,
    Table,
    StatusButton,
    PopconfirmDelButton,
    FormDialog
  },
  data() {
    return {
      loading: false,
      list: [],
      pagination: {
        page: 1,
        psize: 20,
        total: 0
      }
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    handleReset() {
      this.resetParms()
      this.fetchList()
    },
    handleAdd() {
      this.$refs.formDialog.open()
    },
    handleModifyStatus(index, id, status) {
      modifyConfigGroupStatus(id, {
        status: 1 - status
      }).then(() => {
        console.log('dd')
        this.list[index].status = 1 - status
      })
    },
    handleEdit(id) {
      this.$refs.formDialog.open(id)
    },
    handleDel(id) {
      this.loading = true
      delConfig(id).then(() => {
        this.$message.success('删除成功')
        this.fetchList()
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    onSizeChange(val) {
      this.pagination.page = 1
      this.pagination.psize = val
      this.fetchList()
    },
    onCurrentChange(val) {
      this.pagination.page = val
      this.fetchList()
    },
    onConfigGroupFromSubmit() {
      this.fetchList()
    },
    fetchList() {
      this.loading = true
      readConfigGroups(this.getParams()).then(res => {
        this.pagination.total = res.data.total
        this.list = res.data.data || []
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    getParams() {
      return {
        page: this.pagination.page,
        psize: this.pagination.psize
      }
    },
    resetParms() {
      this.pagination.page = 1
      this.pagination.psize = 20
    }
  }
}
</script>
