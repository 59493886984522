<template>
  <div class="pointer" @click="handleClick">
    <Tag v-if="status === 0" type="success">{{ successLabel }}</Tag>
    <Tag v-else type="danger">{{ dangerLabel }}</Tag>
  </div>
</template>

<script>
import { Tag } from '@/components/tag'

export default {
  name: 'StatusButton',
  components: {
    Tag
  },
  props: {
    status: {
      type: Number,
      default: 0
    },
    successLabel: {
      type: String,
      default: '正常使用'
    },
    dangerLabel: {
      type: String,
      default: '不可用'
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>
