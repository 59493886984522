<!--
 * @Author: Ten
 * @Date: 2022/02/28 11:51
 * @LastEditTime: 2022/03/01 14:20
 * @LastEditors: Ten
 * @Description: 所属接口权限配置下拉框
-->
<template>
  <el-cascader
    v-loading="loading"
    element-loading-text=""
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.2)"
    element-loading-custom-class="el-custom-cascader-loading"
    :props="defaultProps"
    :options="options"
    :show-all-levels="false"
    filterable
    clearable
    v-model="checkedIds"
    @change="onChange"
  />
</template>

<script>
import { readAllMenusButton } from '@/api/base/modules/menu'
import { flattenTree, getParentsIdListById } from '@/utils/tree'

export default {
  name: 'APIAuthCascader',
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      defaultProps: {
        label: 'title',
        value: 'id'
      },
      checkedIds: '',
      options: [],
      flattenOptions: []
    }
  },
  watch: {
    value(val) {
      this.checkedIds = this.getCheckedIds(this.flattenOptions, val)
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      this.loading = true
      return readAllMenusButton().then(res => {
        this.options = this.formatOptions(res.data || [])
        this.flattenOptions = flattenTree(this.options)
        this.checkedIds = this.getCheckedIds(this.flattenOptions, this.value)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    formatId(id) {
      return `p_${id}`
    },
    formatOptions(options) {
      return options.map(option => {
        if (option.api_url && option.api_url.length > 0) {
          return {
            ...option,
            pid: this.formatId(option.pid),
            id: this.formatId(option.id),
            children: option.api_url.map(item => {
              return {
                ...item,
                pid: this.formatId(item.menu_id)
              }
            })
          }
        } else if (option.children && option.children.length > 0) {
          return {
            ...option,
            pid: this.formatId(option.pid),
            id: this.formatId(option.id),
            children: this.formatOptions(option.children)
          }
        } else {
          return {
            ...option,
            pid: this.formatId(option.pid),
            id: this.formatId(option.id)
          }
        }
      })
    },
    onChange() {
      let checkedId = ''
      if (this.checkedIds.length > 0) {
        checkedId = this.checkedIds[this.checkedIds.length - 1]
      }
      this.$emit('input', checkedId)
      this.$emit('change', checkedId)
    },
    getCheckedIds(options, id) {
      if (!id) {
        return []
      }
      return getParentsIdListById(options, id, this.formatId(0)).concat(id)
    }
  }
}
</script>
