<!--
 * @Author: Qy
 * @Date: 2021/10/28 12:14
 * @LastEditTime: 2022/03/29 17:39
 * @LastEditors: Qy
 * @Description:打印/详情 模板
-->
<template>
  <div class="print_view_box">
    <div class="info_wrapper">
      <h1 class="info-title">Indra应聘登记表</h1>
      <h3 class="form-title">基本信息</h3>
      <el-descriptions
        class="base-info-descrip"
        :column="3"
        size="medium"
        border
      >
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="姓名"
        >
          {{ info.name }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="申请职位"
        >
          {{ info.job_name }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="签署竞业协议"
        >
          <FieldFilter :map="isSignOpt" :value="info.is_sign_agreement + ''" />
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="性别"
        >
          {{ info.sex === 1 ? '女' : '男' }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="出生年月"
        >
          {{ info.birth_date }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="民族"
        >
          {{ info.nation }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="籍贯"
        >
          {{ info.native_place }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="政治面貌"
        >
          {{ info.political_status }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="电话"
        >
          {{ info.phone }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="邮箱"
        >
          {{ info.email }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="微信"
        >
          {{ info.wechat }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="可入职时间"
        >
          {{ info.join_time }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="目前薪资"
        >
          {{ info.current_salary }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="期望薪资(税前)"
        >
          {{ info.expected_salary }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="在职情况"
        >
          <FieldFilter
            :map="workStatusOpt"
            :value="info.current_jon_status + ''"
          />
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="紧急联系手机"
        >
          {{ info.urgent_phone }}
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="渠道来源"
          :span="2"
        >
          <!-- 来源: -->
          <FieldFilter :map="originOpt" :value="info.origin + ''" />
          <!-- 自行投递: -->
          <span v-if="isDeliverSelf">
            ；{{ info.channel | channelFieldFilter(channelOpt) }}
          </span>
          <!-- 其他渠道： -->
          <span v-if="isOtherChannel">；{{ info.other_channel }}</span>
          <!-- 推荐人： -->
          <span v-if="isReferee">；{{ info.referee }}</span>
        </el-descriptions-item>
        <el-descriptions-item
          label-class-name="label"
          content-class-name="content"
          label="目前居住地"
        >
          {{ info.home_place }}
        </el-descriptions-item>
      </el-descriptions>
      <h3 class="form-title">教育背景</h3>
      <table
        ref="eductionRef"
        id="eductionId"
        cellspacing="0"
        cellpadding="0"
        border="0"
        class="print-table"
      >
        <thead>
          <tr>
            <!-- <td><div style="width: 50px">序号</div></td> -->
            <td><div style="width: 80px; padding-left: 5px">教育形式</div></td>
            <td><div style="width: 170px">学校/培训机构名称</div></td>
            <td><div style="width: 80px">教育类别</div></td>
            <td><div style="width: 150px">起止时间</div></td>
            <td><div style="width: 140px">学历/证书</div></td>
            <td><div style="width: 140px">专业/培训课程</div></td>
          </tr>
        </thead>
        <tbody v-if="info.eduction.length">
          <tr v-for="(item, index) in info.eduction" :key="index" valign="top">
            <!-- <td>
              {{ `${index + 1}` | padNumberFilter }}
            </td> -->
            <td>
              <FieldFilter
                :map="educationTypeOpt"
                :value="item.education_type + ''"
              />
            </td>
            <td>
              {{ item.education_name }}
            </td>
            <td>
              <FieldFilter
                :map="isFulltimeOpt"
                :value="item.is_fulltime + ''"
              />
            </td>
            <td>{{ item.start_time }} ~ {{ item.end_time }}</td>
            <td>
              {{ item.academic_degree }}
            </td>
            <td>
              {{ item.course }}
            </td>
          </tr>
        </tbody>
      </table>
      <h3 class="form-title" v-if="info.experience.length">工作经历</h3>
      <div class="exp-info-wrap">
        <div
          v-for="(item, index) in info.experience"
          :key="index"
          class="item-card"
        >
          <div class="item-num">
            {{ `${index + 1}` | padNumberFilter }}
          </div>
          <div class="item-descrip">
            <el-descriptions
              class="exp-info-descrip"
              :column="2"
              size="medium"
              border
            >
              <el-descriptions-item
                label-class-name="label"
                content-class-name="content"
                label="公司名称"
              >
                {{ item.company_name }}
              </el-descriptions-item>
              <el-descriptions-item
                label-class-name="label"
                content-class-name="content"
                label="起止时间"
              >
                {{ item.start_date }} ~ {{ item.is_now === 1 ? '至今' : item.end_date }}
              </el-descriptions-item>
              <el-descriptions-item
                label-class-name="label"
                content-class-name="content"
                label="所在部门"
              >
                {{ item.department }}
              </el-descriptions-item>
              <el-descriptions-item
                label-class-name="label"
                content-class-name="content"
                label="职位名称"
              >
                {{ item.job_title }}
              </el-descriptions-item>
              <el-descriptions-item
                label-class-name="label"
                content-class-name="content"
                label="薪酬待遇"
              >
                {{ item.salary }}
              </el-descriptions-item>
              <el-descriptions-item
                label-class-name="label"
                content-class-name="content"
                label="证明人及联系方式"
              >
                {{ item.auth_phone }}
              </el-descriptions-item>
              <el-descriptions-item
                label-class-name="label"
                content-class-name="content"
                label="看新机会原因/离职原因"
              >
                {{ item.leave_reason }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>
      </div>
    </div>
    <div class="declar_wrapper">
      <div class="p1">本人声明：</div>
      <div class="p2">
        1、本人许可并授权贵公司在发放录用通知书前，可通过背景调查等方式对本人在应聘登记表和应聘简历中所提供信息的真实性和完整性进行调查。
      </div>
      <div class="p2">
        2、本人在应聘登记表和应聘简历中所提供的各项信息均真实、有效，如提供的信息、相关证明文件等不属实或有误导，可视为本人违反诚信原则，本人的应聘申请可视为取消，如本人已受聘，上述行为亦将被视为严重违纪，本人将接受公司以严重违纪理由立即解除劳动合同，且不给予任何经济补偿或赔偿。
      </div>
      <div class="sign">
        <div class="s1">填表人签名：</div>
        <div class="s1">日期：</div>
      </div>
    </div>
  </div>
</template>
<script>
import { FieldFilter } from '@/components/status-button'
import {
  politicalStatusOpt,
  isSignOpt,
  workStatusOpt,
  originOpt,
  channelOpt,
  educationTypeOpt
} from '@/client/src/enum/job'
export default {
  name: 'PrintView',
  components: { FieldFilter },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isDeliverSelf() {
      return this.info.origin === 1
    },
    isOtherChannel() {
      return this.info.origin === 1 && this.info.channel.includes('7')
    },
    isReferee() {
      return this.info.origin === 4 || this.info.origin === 5
    }
  },
  filters: {
    padNumberFilter(value) {
      const txt = value.padStart(2, '0')
      return txt
    },
    channelFieldFilter(value, map) {
      const textArr = map
        .filter((item) =>
          value.split(',').some((i) => i === item.value && item.value !== '7')
        )
        .map((i) => {
          return i.label
        })
      const text = textArr.join('；')
      return text || ''
    }
  },
  data() {
    return {
      politicalStatusOpt,
      isSignOpt,
      workStatusOpt,
      originOpt,
      channelOpt,
      educationTypeOpt,
      isFulltimeOpt: [
        {
          value: '0',
          label: '非统招'
        },
        {
          value: '1',
          label: '统招'
        }
      ]
    }
  },
  methods: {}
}
</script>
