<!--
 * @Author: Ten
 * @Date: 2021/08/09 11:07
 * @LastEditTime: 2022/03/01 11:43
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <el-select
    v-model="currentValue"
    placeholder="请选择"
    filterable
    clearable
    :loading="loading"
    :multiple="multiple"
    :collapse-tags="collapseTags"
    :size="size"
    @change="onChange"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.title"
      :value="item.id"
    />
  </el-select>
</template>

<script>
export default {
  name: 'RoleSelector',
  props: {
    type: {
      type: Number,
      default: 0
    },
    value: {
      type: [Array, String, Number],
      default() {
        return []
      }
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      currentValue: []
    }
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val
      },
      immediate: true
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.currentValue)
      this.$emit('change', this.currentValue)
    }
  }
}
</script>
