<!--
 * @Author: Ten
 * @Date: 2021/08/09 11:11
 * @LastEditTime: 2022/01/24 12:14
 * @LastEditors: Qy
 * @Description:(数组只保留前两个,若不止2个的则在内容后面加“...“标识)
-->
<template>
  <div>
    <div v-if="content && content.length > maxlength">
      <el-tooltip
        :effect="effect"
        :content="content.join(joinType)"
        placement="top"
      >
        <div
          slot="content"
          :class="inline ? 'br' : ''"
          :style="{ width: tooltipWidth }"
        >
          {{ content.join(isInline) }}
        </div>
        <div>
          {{ content.slice(0, maxlength).join(joinType) + '...' }}
        </div>
      </el-tooltip>
    </div>
    <div v-else>{{ (content !== null && content.join(joinType)) ||  noDataText }}</div>
  </div>
</template>

<script>
export default {
  name: 'TextTooltipOfArray',
  props: {
    effect: {
      type: String,
      default: 'light'
    },
    content: {
      type: Array,
      default: () => []
    },
    maxlength: {
      type: Number,
      default: 20
    },
    tooltipWidth: {
      type: String,
      default: '250px'
    },
    joinType: {
      type: String,
      default: '，'
    },
    // 内容是否换行
    inline: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: String,
      default: '/'
    }
  },
  computed: {
    isInline() {
      const { inline, joinType } = this
      return inline ? '\n' : joinType
    }
  }
}
</script>
<style lang="scss" scoped>
.br {
  white-space: pre-line;
}
</style>
