var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',[_c('FilterBar',[_c('el-form',{attrs:{"inline":true,"model":_vm.params}},[_c('el-form-item',{attrs:{"label":"角色名称："}},[_c('el-input',{attrs:{"placeholder":"请输入","maxlength":"10","clearable":""},model:{value:(_vm.params.title),callback:function ($$v) {_vm.$set(_vm.params, "title", $$v)},expression:"params.title"}})],1),_c('el-form-item',{attrs:{"label":"最后更新日期："}},[_c('DatePicker',{attrs:{"start":_vm.params.start_time,"end":_vm.params.end_time},on:{"update:start":function($event){return _vm.$set(_vm.params, "start_time", $event)},"update:end":function($event){return _vm.$set(_vm.params, "end_time", $event)}}})],1),_c('el-form-item',[_c('Button',{attrs:{"type":"refresh"},on:{"click":_vm.handleReset}}),_c('Button',{attrs:{"type":"search"},on:{"click":_vm.handleSearch}})],1)],1)],1),_c('Container',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('Button',{directives:[{name:"auth",rawName:"v-auth:create",arg:"create"}],attrs:{"type":"create"},on:{"click":_vm.handleAdd}},[_vm._v("添加角色")])]},proxy:true}])},[_c('Table',{attrs:{"loading":_vm.loading,"data":_vm.list,"pagination":{
        total: _vm.total,
        page: _vm.params.page,
        psize: _vm.params.psize
      }},on:{"page-size-change":_vm.onSizeChange,"page-current-change":_vm.onCurrentChange}},[_c('el-table-column',{attrs:{"label":"序号","min-width":"150","fixed":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("filterForamtSerialNumber")(scope.$index,_vm.params.page, _vm.params.psize))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"角色名称","min-width":"150px","prop":"title","fixed":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.title))]),(row.type === 0)?_c('Tag',{attrs:{"size":"mini"}},[_vm._v("系统角色")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"备注","min-width":"298"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('TextTooltip',{attrs:{"content":scope.row.desc}})]}}])}),_c('el-table-column',{attrs:{"label":"最后更新时间","min-width":"224","prop":"updated_at"}}),_c('el-table-column',{attrs:{"label":"操作","width":"140","class-name":"small-padding","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('el-button',{directives:[{name:"auth",rawName:"v-auth:edit",arg:"edit"}],attrs:{"title":"点击编辑","type":"text","disabled":row.type === 0},on:{"click":function($event){return _vm.handleEdit(row.id)}}},[_vm._v(" 编辑 ")]),_c('PopconfirmDelButton',{directives:[{name:"auth",rawName:"v-auth:delete",arg:"delete"}],staticClass:"ml-16",attrs:{"disabled":row.type === 0},on:{"confirm":function($event){return _vm.handleDel(row.id)}}},[_vm._v(" 删除 ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }