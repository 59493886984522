<!--
 * @Author: Ten
 * @Date: 2021/07/27 10:30
 * @LastEditTime: 2021/10/26 17:07
 * @LastEditors: Ten
 * @Description: 批量删除栏
-->
<template>
  <FixedFooterBar>
    <div slot="left" class="color-text--primary fs-14">
      已选择 <span class="color--primary">{{ selectedCount }} </span> 项
    </div>
    <PopconfirmDelButton
      type="primary"
      :disabled="selectedCount === 0"
      @confirm="$emit('confirm')"
    >
      批量删除
    </PopconfirmDelButton>
  </FixedFooterBar>
</template>

<script>
import FixedFooterBar from './FixedFooterBar.vue'
import { PopconfirmDelButton } from '@/components/button'

export default {
  name: 'BatchDelBar',
  components: {
    FixedFooterBar,
    PopconfirmDelButton
  },
  props: {
    selectedCount: {
      type: Number,
      default: 0
    }
  }
}
</script>
