<!--
 * @Author: Ten
 * @Date: 2021/08/22 11:07
 * @LastEditTime: 2021/10/28 16:50
 * @LastEditors: Ten
 * @Description: 筛选面板
-->
<template>
  <div :class="$style.wrapper">
    <div v-if="title" :class="$style.title">{{ title }}</div>
    <div :class="$style.filter">
      <el-input
        v-model="currentValue"
        :placeholder="placeholder"
        suffix-icon="el-icon-search"
        class="is-unverify"
        @input="onInput"
      />
    </div>
    <div :class="$style.body">
      <el-scrollbar :view-style="viewStyle">
        <div :class="$style.inner">
          <slot />
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterPanel',
  props: {
    value: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '搜索'
    },
    viewStyle: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      currentValue: ''
    }
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val
      },
      immediate: true
    }
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  border-radius: $border-radius--base;
  border: 1px solid $border-color--primary;
}
.title {
  padding: 15px 20px 0;
  line-height: normal;
  font-size: $font-size--base;
  color: $color-text--primary;
}
.filter {
  padding: 15px 20px;
}
.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  .inner {
    margin-top: -10px;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
