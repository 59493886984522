<!--
 * @Author: Qy
 * @Date: 2022/02/24 15:40
 * @LastEditTime: 2022/03/01 10:48
 * @LastEditors: Qy
 * @Description:
-->
<template>
  <FixedFooterBar>
    <div slot="left" class="color-text--primary fs-14">
      已选择 <span class="color--primary fw-blod">{{ selectedCount }} </span> 项
    </div>
    <div slot="right">
      <slot name="right" />
    </div>
  </FixedFooterBar>
</template>

<script>
import { FixedFooterBar } from '@/components/bar'

export default {
  name: 'BatchToolsBar',
  components: {
    FixedFooterBar
  },
  props: {
    selectedCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isSelected() {
      return this.selectedCount === 0
    }
  },
  methods: {
  }
}
</script>
