<!--
 * @Author: Qy
 * @Date: 2022/02/23 11:55
 * @LastEditTime: 2022/09/08 15:35
 * @LastEditors: ZhaoQiaoLi
 * @Description:工资条推送
-->
<template>
  <Page v-loading="loading">
    <template #actions>
      <div>
        <el-button
          class="ml-20"
          title="清空"
          icon="el-icon-delete"
          @click="handleEmptyData"
          :disabled="!isImported"
          >清空</el-button
        >
      </div>
    </template>
    <FilterBar>
      <el-form :inline="true" :model="params">
        <el-form-item label="姓名：">
          <el-input v-model.trim="params.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="params.status" placeholder="请选择">
            <el-option
              v-for="item in statusOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container id="container_body_height">
      <div class="flex flex-column flex-1 relative p24">
        <el-alert
          v-show="errorMsgTips !== ''"
          id="alert_tips_height"
          class="alert-tips error"
          :class="{ 'mb-16': errorMsgTips !== '' }"
          :title="errorMsgTips"
          type="error"
          show-icon
          :closable="false"
        >
        </el-alert>
        <el-alert
          v-show="repeatMsgTips !== ''"
          id="alert_repeat_tips_height"
          class="alert-tips warning"
          :class="{ 'mb-16': repeatMsgTips !== '' }"
          :title="repeatMsgTips"
          type="warning"
          show-icon
          :closable="false"
        >
        </el-alert>
        <u-table
          v-if="isImported"
          ref="table"
          class="rest-table"
          :data="tableListFilter"
          :height="tableHeight"
          :row-height="rowHeight"
          use-virtual
          big-data-checkbox
          :border="false"
          showHeaderOverflow="title"
          row-key="id"
          @selection-change="handleSelectionChange"
          :summary-method="summaryMethod"
          show-summary
          fixed-columns-roll
        >
          <u-table-column
            :selectable="disabledSelect"
            type="selection"
            :reserve-selection="true"
            width="60"
            fixed="left"
          />
          <u-table-column
            label="序号"
            width="80"
            show-overflow-tooltip
            fixed="left"
          >
            <template slot-scope="scope">
              {{
                scope.$index
                  | filterForamtSerialNumber(params.page, params.psize)
              }}
            </template>
          </u-table-column>
          <u-table-column
            prop="name"
            label="姓名"
            fixed="left"
            width="102"
            show-overflow-tooltip
          />
          <u-table-column prop="pay_time" label="所属时间" width="88" />
          <!-- />
            <template slot-scope="{ row }">
              {{ row.name }}_{{ row.is_repeat }}
            </template>
          </u-table-column> -->
          <u-table-column
            prop="department"
            label="项目/部门"
            min-width="96"
            show-overflow-tooltip
          />
          <u-table-column
            prop="job"
            label="岗位"
            min-width="96"
            show-overflow-tooltip
          />
          <u-table-column
            prop="email"
            label="公司邮箱"
            min-width="202"
            show-overflow-tooltip
          />
          <u-table-column
            prop="basic_salary"
            label="本月基本工资"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.basic_salary | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="position_allowance"
            label="本月岗位津贴"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.position_allowance | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="promotion_allowance"
            label="本月管理晋升津贴"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.promotion_allowance | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column prop="monthly_total" label="本月月薪合计" min-width="140" align="right">
            <template slot-scope="{ row }">
              {{ row.monthly_total | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column prop="monthly_payday" label="月计薪日" width="88" />
          <u-table-column prop="actual_payday" label="实际计薪日" width="96" />
          <u-table-column
            prop="payroll"
            label="计薪工资"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.payroll | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="overtime_salary"
            label="加班工资"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.overtime_salary | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="save_subsidy"
            label="节约补贴"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.save_subsidy | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="special_subsidy"
            label="特色福利补贴"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.special_subsidy | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="subsidy"
            label="其他补贴"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.subsidy | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="recommend_bonus"
            label="内推奖金"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.recommend_bonus | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="performance_bonus"
            label="绩效奖金"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.performance_bonus | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="project_bonus"
            label="项目奖金"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.project_bonus | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="bonus"
            label="其他奖金"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.bonus | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="fill_money"
            label="其他补款"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.fill_money | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="deductions"
            label="其他扣款"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.deductions | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="salary_payable"
            label="应发工资"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.salary_payable | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="unit_social_security"
            label="单位社保代缴"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.unit_social_security | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="person_social_security"
            label="个人社保代缴"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.person_social_security | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="social_security_total"
            label="社保合计"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.social_security_total | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="unit_provident_fund"
            label="单位公积金代缴"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.unit_provident_fund | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="person_provident_fund"
            label="个人公积金代缴"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.person_provident_fund | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="provident_fund_total"
            label="公积金合计"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.provident_fund_total | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="tax"
            label="本期应缴个税"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.tax | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column
            prop="net_payroll"
            label="实发工资"
            min-width="140"
            align="right"
          >
            <template slot-scope="{ row }">
              {{ row.net_payroll | filterFormatMoney }}
            </template>
          </u-table-column>
          <u-table-column label="状态" fixed="right" width="90">
            <template slot-scope="{ row }">
              <Tag v-show="row.status === 1" type="default">未推送</Tag>
              <Tag v-show="row.status === 2" type="success">已推送</Tag>
              <Tag v-show="row.status === 3" type="danger">推送失败</Tag>
            </template>
          </u-table-column>
          <u-table-column label="操作" fixed="right" width="90">
            <template slot-scope="{ row }">
              <el-button
                v-if="row.status === 1 || row.status === 2"
                type="text"
                :disabled="row.status === 2"
                @click="handleDelivery(false, row)"
                >推送</el-button
              >
              <el-button
                v-if="row.status === 3"
                type="text"
                @click="handleDelivery(false, row)"
                >重新推送</el-button
              >
            </template>
          </u-table-column>
        </u-table>
      </div>
      <div
        v-if="!isImported"
        v-loading="uploadLoadingSubmit"
        class="improt-wrapper default--absolute"
      >
        <div class="image"></div>
        <FileUploader
          disabledUploader=""
          class="import-btn"
          :max-size="10240"
          action="/api/automate/payment/import"
          buttonIco="el-icon-plus"
          @beforeUp="onBeforeFileUpload"
          @success="onSuccessFileUploader"
          @error="onErrorFileUploader"
        >
          导入Excel表格
        </FileUploader>
        <div class="down-btns" @click="handleDownloadTemplate">
          <svg-icon icon-class="down-excel" class="down-icon" />
          <span>下载Excel表格模板</span>
        </div>
      </div>
    </Container>
    <!-- 底部操作 -->
    <BatchToolsBar :selected-count="selectedRowNum">
      <div slot="right">
        <el-button
          type="primary"
          @click="handleDelivery(true)"
          :disabled="selectedRowNum === 0"
        >
          批量推送
        </el-button>
      </div>
    </BatchToolsBar>
    <!-- 推送进程弹框 -->
    <DeliveryProgressDialog
      ref="deliveryProgressDialog"
      :type="type"
      @onClose="handleCloseDeliveryProgressDialog"
    />
  </Page>
</template>

<script>
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { FilterBar } from '@/components/filter'
import { UTable, UTableColumn } from 'umy-ui'
import { Button } from '@/components/button'
import { Tag } from '@/components/tag'
import BatchToolsBar from '@/components/bar/BatchToolsBar.vue'
import DeliveryProgressDialog from './comps/DeliveryProgressDialog.vue'
import download from '@/utils/download'
import { confirm } from '@/utils/messageBox'
import { debounce } from '@/utils/fn'
import { FileUploader } from '@/components/file'
import { formatMoney } from '@/utils/string'

export default {
  name: 'SalaryList',
  components: {
    Page,
    Container,
    FilterBar,
    UTable,
    UTableColumn,
    Button,
    Tag,
    FileUploader,
    BatchToolsBar,
    DeliveryProgressDialog
  },
  data() {
    return {
      rowHeight: 50,
      statusOpt: [
        {
          value: 1,
          label: '未推送'
        },
        {
          value: 2,
          label: '已推送'
        },
        {
          value: 3,
          label: '推送失败'
        }
      ],
      list: [],
      selectedList: [], // 已勾选数据
      loading: false,
      uploadLoadingSubmit: false, // 上传导入文件的loading
      downloadLoading: false,
      query: {}, // 表格过滤查询参数
      params: {
        name: '',
        status: '',
        page: 1,
        psize: 20
      },
      tableHeight: 0, // 表格高度
      total: 0,
      errorMsgTips: '', // 导入失败的提示语
      repeatMsgTips: '', // 导入重复的提示语
      type: 2 // 发送方式
    }
  },
  computed: {
    // 数据是否已导入
    isImported() {
      return this.list.length !== 0
    },
    // 表格数据过滤
    tableListFilter() {
      const { query } = this
      const filterTableData = this.list
        .filter((item) => {
          if (query.name) {
            return item.name.includes(query.name)
          }
          return item
        })
        .filter((item) => {
          if (query.status) {
            return item.status === query.status
          }
          return item
        })
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.total = filterTableData.length
      return filterTableData
    },
    selectedRowNum() {
      return this.selectedList.length
    },
    // 发送方式
    sender() {
      return this.type === 1 ? '企微' : '企业邮箱'
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowOnresize, true)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowOnresize, true)
  },
  methods: {
    handleWindowOnresize: debounce(function () {
      // 窗体变化的时候改变table高度
      this.getTableHeight()
      // 窗体变化的时候修复table 固定列错位问题
      this.$nextTick(() => {
        this.$refs.table && this.$refs.table.doLayout()
      })
    }),
    // 计算表格高度
    getTableHeight() {
      this.$nextTick(() => {
        const containerHgt = document
          .querySelector('#container_body_height')
          .getBoundingClientRect().height
        const errorTipsHgt =
          this.errorMsgTips === ''
            ? 0
            : document
              .querySelector('#alert_tips_height')
              .getBoundingClientRect().height + 16
        const repeatTipsHgt =
          this.repeatMsgTips === ''
            ? 0
            : document
              .querySelector('#alert_tips_height')
              .getBoundingClientRect().height + 16

        this.tableHeight = containerHgt
          ? Number(containerHgt) - 48 - errorTipsHgt - repeatTipsHgt
          : 400

        console.log(
          '计算表格高度',
          containerHgt,
          errorTipsHgt,
          repeatTipsHgt,
          this.tableHeight
        )
      })
    },
    // 下载Excel表格模板
    handleDownloadTemplate() {
      download('./template/Inrdasoft工资条模板.xlsx')
    },
    // 上传文件之前的钩子
    onBeforeFileUpload() {
      this.errorMsgTips = ''
      this.repeatMsgTips = ''
      this.uploadLoadingSubmit = true
    },
    // 上传文件请求接口
    async onSuccessFileUploader(res) {
      const { code, msg, status } = res
      if (code === 10001 && status === 1) {
        this.uploadLoadingSubmit = false
        return this.$message.error(msg)
      } else {
        const notExistList = res.data
          .filter((i) => {
            return i.is_exist === false || i.is_invalid === true
          })
          .map((i) => {
            return i.id
          })
        const totalNum = res.data.length
        const errorNum = notExistList.length
        if (errorNum !== 0) {
          this.$message.warning({
            message: `导入成功 ${totalNum - errorNum} 条，失败 ${errorNum} 条`
          })
          this.errorMsgTips = `导入失败${errorNum}条。请检查Excel表格中以下行数是否正常：第${notExistList.join(
            '、'
          )}行`
        } else {
          this.$message.success('导入成功')
          this.errorMsgTips = ''
        }
        const repeatList = res.data
          .filter((i) => {
            return i.is_repeat === true
          })
          .map((i) => {
            return i.id
          })

        this.repeatMsgTips =
          repeatList.length !== 0
            ? `Excel表格内存在重复数据，请检查以下行数的姓名/邮箱是否正确，否则会导致多次推送：第${repeatList.join(
                '、'
              )}行`
            : ''
        await this.getTableHeight() // 计算高度
        this.list = res.data
          .filter((i) => {
            return i.is_exist === true && i.is_invalid === false
          })
          .map((i) => {
            return {
              ...i,
              status: 1
            }
          })
        this.uploadLoadingSubmit = false
      }
    },
    // 上传文件处理报错情况
    onErrorFileUploader(err) {
      this.loadingSubmit = false
      this.$message.error(err)
    },
    // 获取列表参数
    getParams(isExport = false) {
      const paramsObj = {
        ...this.params
      }
      if (isExport) {
        delete paramsObj.page
        delete paramsObj.psize
      }
      return paramsObj
    },
    // 查询
    handleSearch() {
      this.params.page = 1
      this.query = JSON.parse(JSON.stringify(this.getParams()))
    },
    // 重置
    handleReset() {
      this.params.name = ''
      this.params.status = ''
      this.params.page = 1
      this.params.psize = 20
      this.total = 0
      this.$refs.table && this.$refs.table.clearSelection()
      this.query = {}
    },
    // 翻页选择条数
    onSizeChange(val) {
      this.params.page = 1
      this.params.psize = val
    },
    // 翻页
    onCurrentChange(val) {
      this.params.page = val
    },
    // table选中的操作
    handleSelectionChange(selectedList) {
      this.selectedList = selectedList
    },
    // 禁用勾选已推送的数据
    disabledSelect(row) {
      if (row.status === 1 || row.status === 3) {
        return true
      }
      return false
    },
    // 清空数据
    handleEmptyData() {
      confirm({
        type: 'warning',
        title: '是否清空全部数据？',
        message: '清空全部数据后，需重新导入Excel表格。',
        confirmButtonText: '清 空'
      }).then(() => {
        this.list = []
        this.errorMsgTips = ''
        this.repeatMsgTips = ''
        this.handleReset()
        this.$message.success('清空成功')
      })
    },
    // 批量推送 isBatch true为批量 false 为单个
    handleDelivery(isBatch, row) {
      confirm({
        title: `${
          isBatch
            ? `是否批量推送${this.selectedRowNum}条数据？`
            : '是否推送此条数据？'
        }`,
        message: `推送成功后，可在${this.sender}查看推送消息`,
        confirmButtonText: `${isBatch ? '批量推送' : '推送'}`
      }).then(() => {
        this.$refs.deliveryProgressDialog.open(
          isBatch ? this.selectedList : [row]
        )
      })
    },
    // 关闭进程弹框后
    handleCloseDeliveryProgressDialog(resultList) {
      // eslint-disable-next-line no-undef
      const newList = _.map(this.list, function (item) {
        // eslint-disable-next-line no-undef
        return _.merge(item, _.find(resultList, { id: item.id }))
      })
      this.list = newList
      this.$refs.table.clearSelection()
      this.selectedList = []
      // console.log('新的list', this.list)
    },
    // 新表格的合计
    summaryMethod({ columns, data }) {
      const means = [] // 合计
      columns.forEach((column, columnIndex) => {
        if (columnIndex === 0) {
          means.push('合计')
        } else if (columnIndex === 1) {
          means.push(`${this.total}项`)
        } else if (
          column.label === '月计薪日' ||
          column.label === '实际计薪日' ||
          column.label === '所属时间'
        ) {
          means.push('-')
        } else {
          const values = data.map((item) => Number(item[column.property]))
          // 合计
          if (!values.every((value) => isNaN(value))) {
            means[columnIndex] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            console.log(
              'means[columnIndex]',
              formatMoney(means[columnIndex]),
              means[columnIndex]
            )
            means[columnIndex] = formatMoney(means[columnIndex])
          } else {
            means[columnIndex] = '-'
          }
        }
      })
      // 返回一个二维数组的表尾合计
      return [means]
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'styles/u-table.css'; // 引入umy-ui table样式
#container_body_height {
  min-height: 350px;
}
.improt-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  min-height: 300px;
  &.default--absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .image {
    width: 165px;
    height: 165px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 200px;
    height: 150px;
    background-image: url('~@/assets/images/default/import.png');
  }
  .import-btn {
    margin: 16px auto 10px;
  }
  .down-btns {
    display: flex;
    color: #4e5c6a;
    font-size: 14px;
    .down-icon {
      font-size: 16px;
      margin-right: 8px;
    }
    &:hover {
      cursor: pointer;
      color: #3481fc;
      .down-icon {
        color: #3481fc;
      }
    }
  }
}
.alert-tips {
  margin-bottom: 16px;
  align-items: flex-start;
  &.error {
    border: 1px solid #f69c9c;
    background: #fdeaea;
  }
  &.warning {
    border: 1px solid #f4cb73;
    background: #fcf4e1;
  }
  ::v-deep {
    .el-alert__icon {
      position: relative;
      top: 2px;
    }
    .el-alert__title {
      color: #223345;
    }
  }
}
.mb-16 {
  margin-bottom: 16px;
}

.p24 {
  padding: 0 24px;
}
.rest-table {
  width: 100%;

  ::v-deep {
    .el-table__header {
      th {
        background-color: #f6f7fb;
        color: #223345;
        font-size: 14px;
        padding: 12px 0;
        &:first-child {
          .cell {
            padding-left: 14px;
            padding-right: 14px;
          }
        }
        &.el-table__cell {
          .cell {
            white-space: nowrap;
            line-height: initial;
          }
        }
      }
    }
    .el-table {
      td {
        color: #223345;
        border-bottom: 1px solid #ebedf0;
      }
    }
    .el-table__body {
      .el-table__cell {
        padding: 4px 0;
      }
    }
    .el-table__footer {
      tbody {
        td {
          background-color: #eaeef6;
          color: #223345;
          padding: 12px 0;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-message-box__wrapper {
  &[aria-label='是否清空全部数据？'] {
    .el-message-box__btns {
      .el-button--primary {
        background-color: #f05050;
        border-color: #f05050;
      }
    }
  }
}
</style>
