<!--
 * @Author: Ten
 * @Date: 2021/10/26 19:42
 * @LastEditTime: 2022/03/03 16:52
 * @LastEditors: Ten
 * @Description: 项目成员管理
-->
<template>
  <Page>
    <FilterBar>
      <el-form :inline="true" :model="params">
        <el-form-item label="成员名称：">
          <el-input
            v-model.trim="params.real_name"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="成员账号：">
          <el-input
            v-model.trim="params.username"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item label="最后访问日期：">
          <DatePicker :start.sync="params.start_time" :end.sync="params.end_time" />
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
          <Button type="delete" v-auth:delete @click="handleBatchDel">批量移除</Button>
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container>
      <template #header>
        <Button
          v-auth:create_3
          type="create"
          @click="handleOpenDialog('memberJoin')"
        >
          添加已有成员
        </Button>
        <Button v-auth:create_4 type="create" @click="handleAdd">添加新成员</Button>
        <!-- <Button
          v-auth:create_5
          @click="handleOpenDialog('copyRoles')"
        >
          从目标游戏复制角色成员
        </Button> -->
      </template>
      <Table
        ref="table"
        :loading="loading"
        :data="filterList"
        :pagination="{
          total: total,
          page: params.page,
          psize: params.psize
        }"
        row-key="id"
        show-count
        @page-size-change="onSizeChange"
        @page-current-change="onCurrentChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="70"
          :reserve-selection="true"
          fixed
        />
        <el-table-column label="序号" min-width="115" fixed>
          <template slot-scope="scope">
            {{
              scope.$index | filterForamtSerialNumber(params.page, params.psize)
            }}
          </template>
        </el-table-column>
        <el-table-column label="成员名称" min-width="188" fixed>
          <template slot-scope="scope">{{ scope.row.real_name }}</template>
        </el-table-column>
        <el-table-column label="成员账号" min-width="215">
          <template slot-scope="{ row }">
            <span>{{ row.username }}</span>
            <Tag size="mini">{{ row.type === 1 ? '企微' : '系统' }}</Tag>
          </template>
        </el-table-column>
        <el-table-column label="角色" min-width="186" prop="role_title">
          <template slot-scope="scope">
            <TextTooltipOfArray
              :content="scope.row.role_title"
              :maxlength="2"
              inline
            />
          </template>
        </el-table-column>
        <el-table-column
          label="最后访问时间"
          min-width="272"
          prop="login_time"
        />
        <el-table-column
          label="操作"
          width="140"
          class-name="small-padding"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-auth:edit
              title="点击编辑"
              type="text"
              @click="handleEdit(scope.row.id)"
            >
              编辑
            </el-button>
            <PopconfirmDelButton
              v-auth:delete
              title="确定要移除吗？"
              class="ml-16"
              @confirm="handleDel(scope.row.id)"
            >
              移除
            </PopconfirmDelButton>
          </template>
        </el-table-column>
      </Table>
    </Container>
    <MemberJoinDialog
      ref="memberJoinDialog"
      @submit="handleReset"
    />
    <!-- <CopyRolesFormDialog
      ref="copyRolesFormDialog"
      @submit="handleReset"
    /> -->
  </Page>
</template>

<script>
import { readAllUsers, delUser, delUserByProject } from '@/api/project/modules/user'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { FilterBar } from '@/components/filter'
import { TextTooltipOfArray } from '@/components/text'
import { Button, PopconfirmDelButton } from '@/components/button'
import { Table } from '@/components/table'
import { DatePicker } from '@/components/date'
import { Tag } from '@/components/tag'
import MemberJoinDialog from './comps/MemberJoinDialog.vue'
// import CopyRolesFormDialog from './comps/CopyRolesFormDialog.vue'
import { confirm } from '@/utils/messageBox'
import { ListEventBusMixin } from '@/mixins/list-event-bus'
import { EventBus } from '@/utils/event-bus'

export default {
  name: 'ProjectUserList',
  components: {
    Page,
    Container,
    FilterBar,
    TextTooltipOfArray,
    Button,
    PopconfirmDelButton,
    Table,
    DatePicker,
    Tag,
    MemberJoinDialog
    // CopyRolesFormDialog
  },
  mixins: [ListEventBusMixin],
  data() {
    return {
      list: [],
      selectedList: [],
      loading: true,
      params: {
        page: 1,
        psize: 20,
        username: '',
        real_name: '',
        start_time: '',
        end_time: ''
      },
      total: 0
    }
  },
  computed: {
    projectId() {
      return this.$store.getters['user/projectId']
    },
    filterList({ params, list }) {
      const { page, psize } = params
      const start = (page - 1) * psize
      const end = start + psize
      return list.slice(start, end)
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    getParams() {
      return this.params
    },
    fetchList() {
      this.loading = true
      readAllUsers(this.getParams())
        .then((res) => {
          this.list =
            res.data.map((item) => {
              return {
                ...item,
                login_time: item.login_time || '/',
                role_title: item.user_project_role
                  .find((project) => project.id === this.projectId)
                  ?.user_role.map((role) => role.role_title)
              }
            }) || []
          // this.total = res.data.total
          this.total = res.data.length
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    handleSearch() {
      this.params.page = 1
      this.$refs.table.clearSelection()
      this.fetchList()
    },
    handleRefresh(source = '') {
      if (source === 'del' && this.filterList.length === 1 && this.params.page > 1) {
        this.params.page -= 1
      }
      this.$refs.table.clearSelection()
      this.fetchList()
    },
    handleReset() {
      this.$refs.table.clearSelection()
      this.params.page = 1
      this.params.psize = 20
      this.params.username = ''
      this.params.real_name = ''
      this.params.start_time = ''
      this.params.end_time = ''
      this.fetchList()
    },
    onSizeChange(size) {
      this.params.page = 1
      this.params.psize = size
      // this.fetchList()
    },
    onCurrentChange(page) {
      this.params.page = page
      // this.fetchList()
    },
    handleSelectionChange(selectedList) {
      this.selectedList = selectedList
    },
    /**
     * 打开弹窗
     * @type {*} 窗口的类型 memberJoin 添加已有成员 || copyRoles 从目标游戏复制角色成员
     * @return {*}
     * @Author: Qy
     * @Date: 2021/11/25 17:58
     */
    handleOpenDialog(type) {
      type === 'memberJoin' && this.$refs.memberJoinDialog.open()
      // type === 'copyRoles' && this.$refs.copyRolesFormDialog.open()
    },
    handleAdd() {
      this.$router.push({
        name: 'ProjectUserCreate',
        params: {
          id: -1
        }
      })
    },
    handleEdit(id) {
      this.$router.push({
        name: 'ProjectUserCreate',
        params: {
          id
        }
      })
    },
    handleBatchDel() { // 批量移除
      const selectedIdsList = this.selectedList.map((item) => {
        return item.id
      })
      if (selectedIdsList.length === 0) {
        return this.$message.error('请先勾选需要批量移除的成员')
      }
      confirm({
        message: `确认移除这${selectedIdsList.length}个成员吗？`,
        forever: true
      }).then(() => {
        this.loading = true
        delUserByProject({
          user_id: selectedIdsList
        }).then(() => {
          this.$message.success('移除成功')
          this.handleRefresh('del')
          EventBus.$emit('SystemMemberList', {
            type: 'refresh'
          })
        }).catch((err) => {
          console.log(err)
          this.loading = false
        })
      })
    },
    handleDel(id) { // 单个移除
      this.loading = true
      delUser(id).then(() => {
        this.$message.success('移除成功')
        this.handleRefresh('del')
        EventBus.$emit('SystemMemberList', {
          type: 'refresh'
        })
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    }
  }
}
</script>
