<!--
 * @Author: Ten
 * @Date: 2021/08/09 11:01
 * @LastEditTime: 2021/12/21 17:11
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <div
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.9)"
    :class="$style.wrapper"
  >
    <el-tree
      :style="{ opacity: loading ? 0 : 1 }"
      ref="tree"
      :data="menus"
      :props="defaultProps"
      :expand-on-click-node="false"
      :default-expand-all="defaultExpandAll"
      :default-expanded-keys="defaultExpandedKeys"
      :filter-node-method="filterNode"
      :empty-text="emptyText"
      :show-checkbox="editable"
      node-key="id"
      @check="onCheck"
    />
    <div v-if="editable && showCount" :class="$style.count">
      全部{{ count }} 已选{{ checkedCount }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  flattenTree,
  toTree
} from '@/utils/tree'
import { readAllMenusButton } from '@/api/base/modules/menu'
import { readAllMenusButton as readAllMenusButtonByProject } from '@/api/project/modules/menu'

export default {
  name: 'MenuTree',
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    buttonIds: {
      type: String,
      default: ''
    },
    keyword: {
      type: String,
      default: ''
    },
    editable: { // 是否可编辑
      type: Boolean,
      default: true
    },
    showCount: {
      type: Boolean,
      default: false
    },
    emptyText: { // 缺省文案
      type: String,
      default: '暂无数据'
    },
    defaultExpandAll: { // 是否默认展开全部节点
      type: Boolean,
      default: false
    },
    defaultExpandedKeys: { // 默认展开节点
      type: Array,
      default: () => []
    },
    showCheckAll: { // 是否显示全选按钮
      type: Boolean,
      default: false
    },
    filterProject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      menus: [],
      selectedIdsList: [],
      selectedButtonIdList: [],
      pidList: [],
      defaultProps: {
        children: 'children',
        label: 'title',
        disabled: (data, node) => {
          if (this.disabledId.includes(data.id)) {
            return true
          }
        }
      },
      disabledId: [],
      count: 0,
      checkedCount: 0,
      flatMenus: [],
      flatButtons: []
    }
  },
  watch: {
    keyword(val) {
      this.$refs.tree.filter(val)
    },
    value(val) {
      if (this.$parent.$options._componentTag === 'el-scrollbar') {
        this.$nextTick(() => {
          // 重新计算滚动条高度
          this.$parent.update()
          // 设置滚动条置顶
          this.$parent.wrap && (this.$parent.wrap.scrollTop = 0)
        })
      }
      this.setCheckedKeys(val)
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    onCheck(data, { checkedKeys, halfCheckedKeys }) {
      const isChecked = this.$refs.tree.getCheckedKeys().includes(data.id)
      const checkedMenus = this.getCheckedMenus(checkedKeys.concat(halfCheckedKeys))
      checkedMenus.forEach(menu => {
        const buttonListId = `button-list_${menu.id}`
        if (data.id === buttonListId && !isChecked) {
          // 没有查看按钮则不勾选其他按钮
          menu.button.forEach(button => {
            this.$refs.tree.setChecked(button.id, false, true)
          })
          return
        }
        // 有勾选列表就勾选查看按钮
        this.$refs.tree.setChecked(buttonListId, true, true)
        // 有勾选编辑就勾选详情
        const editButtonId = menu.button.find(item => item.map === 'edit')?.id
        const detailButtonId = menu.button.find(item => item.map === 'see')?.id
        if (editButtonId && detailButtonId) {
          if (checkedKeys.includes(editButtonId)) {
            this.$refs.tree.setChecked(detailButtonId, true, true)
          }
          if (data.id === detailButtonId) {
            this.$refs.tree.setChecked(editButtonId, false, true)
            this.$refs.tree.setChecked(detailButtonId, isChecked, true)
          }
        }
      })
      const value = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
      this.setValue(value)
    },
    filterNode(value, data) {
      if (this.editable) {
        if (!value) {
          return true
        }
        return data.title.indexOf(value) !== -1
      }
      return value.includes(data.id)
    },
    getCheckedMenus(keys) {
      const menuValue = keys.filter(id => (typeof id === 'number'))
      return this.flatMenus.filter(menu => menuValue.includes(menu.id) && menu.button && menu.button.length > 0)
    },
    setValue(value) {
      this.checkedCount = this.getCountChecked(value)
      this.$emit('input', value)
      this.$emit('change', value)
    },
    setCheckedKeys(keys, checked = true, deep = false) {
      if (this.pidList.length === 0 || keys.length === 0) {
        this.$refs.tree.setCheckedKeys([])
        return
      }
      const checkedMenus = this.getCheckedMenus(keys)
      checkedMenus.forEach(menu => {
        const editButton = menu.button.find(item => item.map === 'edit')
        const detailButton = menu.button.find(item => item.map === 'see')
        const buttonListId = `button-list_${menu.id}`
        // 有勾选列表就勾选查看按钮
        if (!keys.includes(buttonListId)) {
          keys = keys.concat(buttonListId)
        }
        if (editButton && detailButton) {
          const editButtonId = editButton.id
          const detailButtonId = detailButton.id
          if (this.value.includes(editButtonId) && !this.value.includes(detailButtonId)) {
            keys = keys.concat(detailButtonId)
          }
        }
      })
      const value = keys.filter(id => !this.pidList.includes(id))
      this.$refs.tree.setCheckedKeys(value, checked, deep)
      this.checkedCount = this.getCountChecked(value)
      if (!this.editable) {
        // 过滤掉非选中的值：非编辑模式下自动筛选选中的值
        this.$refs.tree.filter(value)
      }
    },
    async fetchList() {
      try {
        this.loading = true
        let res = ''
        if (this.filterProject) {
          res = await readAllMenusButtonByProject()
        } else {
          res = await readAllMenusButton()
        }
        let data = res.data || []
        if (this.showCheckAll) { // 显示全部选择框
          data = [{
            pid: -1,
            id: 0,
            title: '全部',
            children: data,
            button: []
          }]
        }
        this.flatMenus = flattenTree(data).map(menu => {
          // 如果有配置按钮权限，自动增加查看按钮
          if (menu.button && menu.button.length > 0) {
            return {
              ...menu,
              button: [
                {
                  id: `button-list_${menu.id}`,
                  title: '查看',
                  menu_id: menu.id
                }
              ].concat(menu.button.map(button => {
                return {
                  ...button,
                  id: `button_${button.id}`
                }
              }))
            }
          }
          return menu
        })
        this.flatButtons = _.flattenDeep(this.flatMenus.filter(menu => menu.button && menu.button.length > 0).map(menu => menu.button))
        this.menus = toTree(this.flatMenus.map(menu => {
          // 将菜单下的按钮配置格式化成树一体
          if (menu.button && menu.button.length > 0) {
            return {
              ...menu,
              children: menu.button
            }
          }
          return menu
        }), this.showCheckAll ? -1 : 0)
        const menusPidList = this.flatMenus.filter(menu => menu.children.length > 0).map(({ id }) => id)
        const buttonsPidList = this.flatMenus.filter(menu => menu.button.length > 0).map(({ id }) => id)
        this.pidList = menusPidList.concat(buttonsPidList)
        this.count = this.flatMenus.filter(({ id }) => !this.pidList.includes(id)).length + this.flatButtons.length
        this.setCheckedKeys(this.value)
        this.$refs.tree.filter('')
        this.loading = false
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
    getCountChecked(keys) {
      return keys.filter(id => !this.pidList.includes(id)).length
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  :global {
    .el-tree {
      flex: 1;
    }
    .el-tree-node__content {
      align-items: center;
      height: auto;
      .el-checkbox__input {
        display: flex;
        align-items: center;
      }
    }
    .el-tree-node__content > .el-tree-node__expand-icon {
      padding: 14px 10px;
    }
    .el-tree-node {
      line-height: normal;
      white-space: normal;
    }
  }
}
.buttons {
  display: flex;
  flex-wrap: wrap;
}
.count {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: right;
  line-height: normal;
  color: $color-text--primary;
}
</style>
