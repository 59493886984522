<!--
 * @Author: Ten
 * @Date: 2021/08/09 18:02
 * @LastEditTime: 2022/03/01 16:24
 * @LastEditors: Ten
 * @Description: 系统日志
-->
<template>
  <Page>
    <FilterBar>
      <el-form :inline="true" :model="params">
        <el-form-item label="操作人：">
          <el-input v-model.trim="params.real_name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="所属游戏：">
          <ProjectSelector
            v-model="params.project_id"
          />
        </el-form-item>
        <el-form-item label="操作日期：">
          <DatePicker
            :start.sync="params.start_time"
            :end.sync="params.end_time"
          />
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
          <el-tooltip content="单次导出不可超出10000条数据" placement="top">
            <Button v-auth:export type="export" class="ml-25" @click="handleExport" />
          </el-tooltip>
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container>
      <Table
        ref="table"
        :loading="loading"
        :data="list"
        :pagination="pagination"
        row-key="id"
        show-count
        @page-size-change="onSizeChange"
        @page-current-change="onCurrentChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true" fixed />
        <el-table-column label="序号" width="135" fixed>
          <template slot-scope="scope">
            {{ scope.$index | filterForamtSerialNumber(pagination.page, pagination.psize) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="real_name"
          label="操作人"
          min-width="200"
        />
        <el-table-column
          prop="project_title"
          label="所属项目"
          min-width="180"
        >
          <template slot-scope="scope">
            {{ scope.row.project_title || '-'}}
          </template>
        </el-table-column>
        <el-table-column
          prop="msg"
          label="操作动作"
          min-width="250"
        />
        <el-table-column
          prop="ip_address"
          label="操作IP"
          min-width="180"
        />
        <el-table-column
          prop="created_at"
          label="操作时间"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.created_at | filterFormatDateOfTable }}
          </template>
        </el-table-column>
        <el-table-column
          prop="sys_user"
          label="返回数据"
          min-width="150"
          fixed="right"
        >
          <template v-if="scope.row.data" slot-scope="scope">
            <el-button
              type="text"
              @click="handleOpenJsonViewer(scope.row.msg, scope.row.data)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </Table>
    </Container>
    <el-drawer
      :visible.sync="jsonViewer.visible"
      :title="jsonViewer.title"
      direction="rtl"
    >
      <div v-if="jsonViewer.visible" :class="$style['json-viewer']">
        <JSONViewer
          :content="jsonViewer.content"
          :expand-depth="jsonViewer.depth"
        />
      </div>
    </el-drawer>
  </Page>
</template>

<script>
import { readLogs, exportLogs } from '@/api/base/modules/log'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import download from '@/utils/download'
import { FilterBar } from '@/components/filter'
import { DatePicker } from '@/components/date'
import { ProjectSelector } from '@/components/project'
import { Button } from '@/components/button'
import { Table } from '@/components/table'
import { JSONViewer } from '@/components/json-viewer'

export default {
  name: 'SystemLogList',
  components: {
    Page,
    Container,
    FilterBar,
    DatePicker,
    JSONViewer,
    ProjectSelector,
    Button,
    Table
  },
  data() {
    return {
      loading: false,
      params: {
        real_name: '',
        project_id: '',
        start_time: '',
        end_time: ''
      },
      pagination: {
        page: 1,
        psize: 20,
        total: 0
      },
      list: [],
      selectedList: [],
      jsonViewer: {
        visible: false,
        title: '',
        content: {},
        depth: 1
      }
    }
  },
  created() {
    this.fetchLogList()
  },
  methods: {
    handleSearch() {
      this.pagination.page = 1
      this.fetchLogList()
    },
    handleReset() {
      this.resetParms()
      this.fetchLogList()
    },
    handleExport() { // 导出
      const params = {}
      const selectedIdsList = this.selectedList.map((item) => {
        return item.id
      })
      if (selectedIdsList.length === 0) {
        params.real_name = this.params.real_name
        params.start_time = this.params.start_time
        params.end_time = this.params.end_time
      } else {
        params.ids = selectedIdsList.join(',')
      }
      exportLogs(params).then((res) => {
        download(res)
      })
    },
    handleSelectionChange(selectedList) {
      this.selectedList = selectedList
    },
    handleOpenJsonViewer(title, content) {
      this.jsonViewer.visible = true
      this.jsonViewer.title = title
      this.jsonViewer.content = content
    },
    onSizeChange(size) {
      this.pagination.page = 1
      this.pagination.psize = size
      this.fetchLogList()
    },
    onCurrentChange(page) {
      this.pagination.page = page
      this.fetchLogList()
    },
    fetchLogList() {
      this.loading = true
      readLogs(this.getParams()).then(res => {
        this.list = res.data.data || []
        this.pagination.total = res.data.total || 0
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    getParams() {
      return {
        ...this.params,
        page: this.pagination.page,
        psize: this.pagination.psize
      }
    },
    resetParms() {
      this.$refs.table.clearSelection()
      this.params.project_id = ''
      this.params.real_name = ''
      this.params.start_time = ''
      this.params.end_time = ''
      this.pagination.page = 1
      this.pagination.psize = 20
    }
  }
}
</script>

<style lang="scss" module>
.json-viewer {
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
}
</style>
