/*
 * @Author: Ten
 * @Date: 2021/10/21 10:33
 * @LastEditTime: 2021/10/21 10:43
 * @LastEditors: Ten
 * @Description:
 */
import request from '@/api/project/request'

export function createRole(data) { // 角色创建接口
  return request({
    url: '/role',
    method: 'post',
    data
  })
}

export function readRole(id) { // 角色详情接口
  return request({
    url: `/role/${id}`,
    method: 'get'
  })
}

export function readRoles(params) { // 角色列表接口（分页）
  return request({
    url: '/role',
    method: 'get',
    params
  })
}

export function readAllRoles(params) { // 角色列表接口（不分页）
  return request({
    url: '/roles',
    method: 'get',
    params
  })
}

export function updateRole(id, data) { // 角色更新接口
  return request({
    url: `/role/${id}`,
    method: 'put',
    data
  })
}

export function modifyRoleStatus(id, data) { // 角色状态更新接口
  return request({
    url: `/role-status/${id}`,
    method: 'put',
    data
  })
}

export function delRole(id) { // 角色删除接口
  return request({
    url: `/role/${id}`,
    method: 'delete'
  })
}
