<!--
 * @Author: Ten
 * @Date: 2021/07/21 10:57
 * @LastEditTime: 2022/03/01 10:39
 * @LastEditors: Qy
 * @Description: 底部悬浮栏
-->
<template>
  <div>
    <div :style="{'height': height}"></div>
    <div
      ref="bar"
      class="fixed-footer-bar"
      :class="{'fixed-footer-bar--collapse': !this.aside.opened}"
    >
      <div class="fixed-footer-bar__left">
        <slot name="left" />
      </div>
      <div class="fixed-footer-bar__right">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FixedFooterBar',
  data() {
    return {
      height: 'auto'
    }
  },
  computed: {
    ...mapState({
      aside: (state) => state.app.aside
    })
  },
  mounted() {
    this.height = `${this.$refs.bar.offsetHeight}px`
  }
}
</script>

<style lang="scss" scoped>
.fixed-footer-bar {
  position: fixed;
  bottom: 0;
  left: $aside-width;
  display: flex;
  align-items: center ;
  padding: 12px 25px;
  width: calc(100% - #{$aside-width});
  box-sizing: border-box;
  box-shadow: 0 -5px 20px 0 rgba(124, 133, 140, 0.16);
  background-color: #fff;
  transition: all 0.28s;
  z-index: 1999;
  &.fixed-footer-bar--collapse {
    left: $aside-width--mini;
    width: calc(100% - #{$aside-width--mini});
  }
  .fixed-footer-bar__left {
    flex: 1;
  }
}
</style>
