<!--
 * @Author: Ten
 * @Date: 2022/02/28 11:51
 * @LastEditTime: 2022/03/01 16:21
 * @LastEditors: Ten
 * @Description: 按钮管理
-->
<template>
  <Page>
    <FilterBar>
      <el-form :inline="true" :model="params" class="form-inline">
        <el-form-item label="按钮类型：">
          <ButtonSelector v-model="params.map" />
        </el-form-item>
        <el-form-item label="菜单：">
          <MenuCascader v-model="params.menu_id" />
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container>
      <template #header>
        <Button v-auth:create type="create" @click="handleAdd">添加按钮</Button>
      </template>
      <Table
        :loading="loading"
        :data="list"
        :pagination="pagination"
        @page-size-change="onSizeChange"
        @page-current-change="onCurrentChange"
      >
        <el-table-column
          label="ID"
          min-width="150px"
          prop="id"
          fixed
        />
        <el-table-column
          label="按钮名称"
          min-width="150px"
          prop="title"
          fixed
        />
        <el-table-column
          label="菜单名称"
          min-width="150px"
          prop="menu_title"
        />
        <el-table-column
          label="操作"
          min-width="150px"
          class-name="small-padding"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              v-auth:edit
              title="点击编辑"
              type="text"
              @click="handleEdit(scope.row.id, scope.row)"
            >
              编辑
            </el-button>
            <PopconfirmDelButton
              v-auth:delete
              class="ml-16"
              @confirm="handleDel(scope.row.id)"
            >
              删除
            </PopconfirmDelButton>
          </template>
        </el-table-column>
      </Table>
    </Container>
    <FormDialog ref="formDialog" @submit="onFormDialogSubmit" />
  </Page>
</template>

<script>
import { readButtons, delButton } from '@/api/base/modules/button'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { FilterBar } from '@/components/filter'
import { Button, PopconfirmDelButton } from '@/components/button'
import { Table } from '@/components/table'
import FormDialog from './comps/FormDialog.vue'
import ButtonSelector from './comps/ButtonSelector.vue'
import { MenuCascader } from '@/components/menu'

export default {
  name: 'SystemButton',
  components: {
    Page,
    Container,
    FilterBar,
    Button,
    Table,
    FormDialog,
    ButtonSelector,
    MenuCascader,
    PopconfirmDelButton
  },
  data() {
    return {
      loading: false,
      list: [],
      params: {
        map: [],
        menu_id: ''
      },
      pagination: {
        page: 1,
        psize: 20,
        total: 0
      }
    }
  },
  computed: {
    buttonMap() {
      return this.$store.getters['permissions/buttonMap']
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    handleSearch() {
      this.pagination.page = 1
      this.fetchList()
    },
    handleReset() {
      this.params.map = []
      this.params.menu_id = ''
      this.pagination.page = 1
      this.pagination.psize = 20
      this.pagination.total = 0
      this.fetchList()
    },
    handleAdd() {
      this.$refs.formDialog.open()
    },
    handleEdit(id, config) {
      this.$refs.formDialog.open(id, {
        name: config.name,
        status: config.status
      })
    },
    handleDel(id) {
      this.loading = true
      delButton(id).then(() => {
        this.$message.success('删除成功')
        this.fetchList()
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    onSizeChange(val) {
      this.pagination.page = 1
      this.pagination.psize = val
      this.fetchList()
    },
    onCurrentChange(val) {
      this.pagination.page = val
      this.fetchList()
    },
    onFormDialogSubmit() {
      this.fetchList()
    },
    fetchList() {
      this.loading = true
      readButtons(this.getParams()).then(res => {
        this.loading = false
        this.list = res.data.data || []
        this.pagination.total = res.data.total || 0
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    getParams() {
      const buttonId = this.params.map[this.params.map.length - 1]
      const map = this.buttonMap.find(button => button.id === buttonId)?.map || ''
      return {
        ...this.params,
        map,
        page: this.pagination.page,
        psize: this.pagination.psize
      }
    },
    resetParms() {
      this.params.map = []
      this.params.menu_id = ''
      this.pagination.page = 1
      this.pagination.psize = 20
    }
  }
}
</script>
