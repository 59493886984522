<!--
 * @Author: Ten
 * @Date: 2022/02/28 11:51
 * @LastEditTime: 2022/03/01 14:24
 * @LastEditors: Ten
 * @Description: 添加/编辑部门
-->
<template>
  <el-dialog
    :title="currentTitle"
    width="450px"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="onCloseDialog"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      :rules="rules"
      label-width="95px"
      class="is-dialog"
    >
      <el-form-item label="上级部门：" prop="pid" :label-width="formLabelWidth">
        <DepartmentCascader
          v-model="form.pid"
          :default-list="defaultDepartmentList"
        />
      </el-form-item>
      <el-form-item label="部门名称：" :label-width="formLabelWidth" prop="title">
        <el-input v-model.trim="form.title" autocomplete="off" clearable />
      </el-form-item>
      <el-form-item label="负责人：" :label-width="formLabelWidth" prop="leader">
        <el-input v-model.trim="form.leader" autocomplete="off" clearable />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      v-loading="loading"
      element-loading-spinner=" "
    >
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" :loading="loadingSubmit" @click="saveData">
        {{ loadingSubmit ? '保存中' : departmentId === 0 ? '确 定' : '保 存' }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  createDepartment,
  readDepartment,
  updateDepartment
} from '@/api/base/modules/department'
import { DepartmentCascader } from '@/components/department'

export default {
  name: 'DepartmentForm',
  components: {
    DepartmentCascader
  },
  data() {
    return {
      loading: false,
      loadingSubmit: false,
      departmentId: 0,
      form: {
        pid: '',
        title: '',
        leader: ''
      },
      visible: false,
      formLabelWidth: '90px',
      defaultDepartmentList: [{ id: 0, title: '顶级' }],
      rules: {
        pid: [{ required: true, message: '请选择上级部门', trigger: 'change' }],
        title: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
        leader: [
          { required: true, message: '请输入负责人姓名', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    currentTitle() {
      return this.departmentId === 0 ? '添加部门' : '编辑部门'
    },
    currentSuccessTips() {
      return this.departmentId === 0 ? '添加成功' : '保存成功'
    }
  },
  methods: {
    open(id) {
      this.departmentId = id || 0
      this.visible = true
      if (this.departmentId) {
        this.loading = true
        readDepartment(this.departmentId).then((res) => {
          const { title, leader, pid } = res.data
          this.form.pid = pid
          this.form.title = title
          this.form.leader = leader
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      }
    },
    onCloseDialog() {
      this.resetForm()
    },
    resetForm() {
      this.departmentId = 0
      this.$refs.form.resetFields()
    },
    async saveDepartment() {
      try {
        this.loadingSubmit = true
        const params = { ...this.form }
        if (this.departmentId) {
          await updateDepartment(this.departmentId, params)
        } else {
          await createDepartment(params)
        }
        this.$emit('submit')
        this.visible = false
        this.$message.success(this.currentSuccessTips)
        this.loadingSubmit = false
      } catch (err) {
        console.log(err)
        this.loadingSubmit = false
      }
    },
    saveData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveDepartment()
        }
      })
    }
  }
}
</script>
