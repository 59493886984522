/*
 * @Author: Ten
 * @Date: 2021/12/21 17:07
 * @LastEditTime: 2021/12/22 10:52
 * @LastEditors: Ten
 * @Description:
 */
import request from '@/api/project/request'

export function readAllMenusButton(params) { // 菜单下按钮结构树接口
  return request({
    url: '/menus-button',
    method: 'get',
    params
  })
}
