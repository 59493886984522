<template>
  <div class="filter-bar" :class="{ 'filter-bar--module': module }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FilterBar',
  props: {
    module: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-bar {
  margin-bottom: 16px;
  padding: $box-padding $box-padding 6px $box-padding;
  border-radius: $border-radius--base;
  background-color: $box-bg-color;
  &.filter-bar--module {
    padding-top: 15px;
    border-bottom: 0;
    box-shadow: $box-shadow--base;
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 18px;
  }
  .el-form--inline .el-form-item {
    margin-right: 24px;
  }
  .el-form-item__label {
    padding: 0;
    word-break: break-word;
  }
  .el-select,
  .el-input {
    max-width: 170px;
  }
  .el-button {
    padding-left: 15px;
    padding-right: 15px;
    min-width: 60px!important;
    vertical-align: middle;
  }
}
</style>
