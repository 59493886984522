/**
 * 用户模块
 */
import request from '@/api/base/request'

export function readUser(id) { // 详情接口
  return request({
    url: `/user/${id}`,
    method: 'get'
  })
}

export function readUsers(params) { // 列表接口（分页）
  return request({
    url: '/user',
    method: 'get',
    params
  })
}

export function readAllUsers(params) { // 列表接口（不分页）
  return request({
    url: '/users',
    method: 'get',
    params
  })
}

export function createUser(data) { // 创建接口
  return request({
    url: '/user',
    method: 'post',
    data
  })
}

export function updateUser(id, data) { // 更新接口（管理员更新信息）
  return request({
    url: `/user/${id}`,
    method: 'put',
    data
  })
}

export function delUser(id) { // 删除接口
  return request({
    url: `/user/${id}`,
    method: 'delete'
  })
}

export function delUserByProject(data) { // 用户删除接口（某一个项目下角色删除）
  return request({
    url: '/user',
    method: 'delete',
    data
  })
}

export function exportUsers(data) { // 导出接口
  return request({
    url: '/user-export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
