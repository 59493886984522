<template>
  <Page403
    :color="colorPrimary"
  >
    <el-button
      slot="button"
      type="primary"
      size="large"
      @click="handleBackHome"
    >
      返回首页
    </el-button>
  </Page403>
</template>

<script>
import { colorPrimary } from '@/styles/variables.scss'
import ErrorPage from '@indrasoft/error-pages'
import '@indrasoft/error-pages/lib/errorPages.css'

export default {
  name: 'ThePage403',
  components: {
    Page403: ErrorPage.Page403
  },
  data() {
    return {
      colorPrimary
    }
  },
  methods: {
    handleBackHome() {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>
