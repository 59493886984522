<!--
 * @Author: Ten
 * @Date: 2021/08/09 11:02
 * @LastEditTime: 2021/08/17 17:52
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <div
    v-show="total > 0"
    v-loading="loading"
    element-loading-spinner=" "
    class="pagination-container"
    :style="style"
  >
    <el-pagination
      :current-page="page"
      :pager-count="pagerCount"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="psize"
      :total="total"
      :background="false"
      :layout="layout"
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 0
    },
    psize: {
      type: Number,
      default: 20
    },
    align: {
      type: String,
      default: 'right'
    },
    layout: {
      type: String,
      // default: 'jumper, prev, pager, next, total, sizes'
      default: 'prev, pager, next, sizes, total'
    },
    pagerCount: {
      type: Number,
      default: 7
    }
  },
  computed: {
    style() {
      return {
        'text-align': this.align
      }
    }
  },
  methods: {
    onSizeChange(size) {
      this.$emit('size-change', size)
    },
    onCurrentChange(page) {
      this.$emit('current-change', page)
    }
  }
}
</script>
