/*
 * @Author: Qy
 * @Date: 2022/02/28 10:00
 * @LastEditTime: 2022/02/28 11:36
 * @LastEditors: Qy
 * @Description:工资条推送
 */
import request from '@/api/project/request'

export function sendSalary(data, cancelRequest) { // 发送薪资消息
  return request({
    url: '/automate/payment/send',
    method: 'post',
    data,
    cancelRequest
  })
}
