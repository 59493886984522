<!--
 * @Author: Qy
 * @Date: 2021/11/30 17:20
 * @LastEditTime: 2021/12/09 14:16
 * @LastEditors: Qy
 * @Description:单行提示语
-->
<template>
  <div :class="$style.tips"><i class="icon el-icon-warning"></i>{{ tips }}</div>
</template>

<script>
export default {
  name: 'TextTips',
  props: {
    tips: String
  }
}
</script>

<style lang="scss" module>
.tips {
  border: 1px solid #8CB8FC;
  background-color: #E6EFFE;
  color: #223345;
  font-size: $font-size--base;
  padding: 10px 16px;
  border-radius: 6px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  :global {
    .icon {
      color: #3481FC;
      font-size: 16px;
      padding-right: 10px;
    }
  }
}
</style>
