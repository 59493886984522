<!--
 * @Author: Ten
 * @Date: 2021/08/09 11:50
 * @LastEditTime: 2021/10/25 15:29
 * @LastEditors: Qy
 * @Description:table列表中的状态显示组件
-->
<template>
  <span :class="[customClass]">
    {{ value | fieldFilter(map) }}
  </span>
</template>

<script>
export default {
  name: 'FieldFilter',
  filters: {
    fieldFilter(value, map) {
      const result = map.find((i) => i.value === value)
      return result?.label
    }
  },
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    map: {
      type: Array,
      required: true
    },
    // 样式名可以覆盖颜色样式
    customClass: {
      type: String
    }
  },
  methods: {}
}
</script>
