/**
 * 企微日志同步列表接口
 */
import request from '@/api/base/request'

export function readWWXLogs(params) { // 日志列表接口（分页）
  return request({
    url: '/wechat-log',
    method: 'get',
    params
  })
}

export function exportWWXLogs(data) { // 日志导出接口
  return request({
    url: '/wechat-log-export',
    method: 'post',
    data
  })
}
