/*
 * @Author: Ten
 * @Date: 2021/10/21 10:33
 * @LastEditTime: 2022/02/23 19:29
 * @LastEditors: Ten
 * @Description:
 */
import request from '@/api/project/request'

export function readUser(id) { // 详情接口
  return request({
    url: `/user/${id}`,
    method: 'get'
  })
}

export function readUsers(params) { // 列表接口（分页）
  return request({
    url: '/user',
    method: 'get',
    params
  })
}

export function readAllUsers(params) { // 列表接口（不分页）
  return request({
    url: '/project-users',
    method: 'get',
    params
  })
}

export function createUser(data) { // 创建接口
  return request({
    url: '/user',
    method: 'post',
    data
  })
}

export function updateUser(id, data) { // 更新接口（管理员更新信息）
  return request({
    url: `/user/${id}`,
    method: 'put',
    data
  })
}

export function delUser(id) { // 删除接口
  return request({
    url: `/user/${id}`,
    method: 'delete'
  })
}

export function delUserByProject(data) { // 用户删除接口（某一个项目下角色删除）
  return request({
    url: '/user',
    method: 'delete',
    data
  })
}

export function exportUsers(data) { // 导出接口
  return request({
    url: '/user-export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

export function batchSetUserRole(data) { // 批量为用户添加角色接口
  return request({
    url: '/users',
    method: 'post',
    data
  })
}

export function getDepUserList(params) { // 获取用户接口（区分企微和系统用户）
  return request({
    url: '/department-user-list',
    method: 'get',
    params
  })
}

export function copyProjectUserRole(data) { // 复制角色成员接口
  return request({
    url: '/copy-project-user-role',
    method: 'post',
    data
  })
}
