<!--
 * @Author: Ten
 * @Date: 2021/07/16 19:40
 * @LastEditTime: 2021/12/02 22:26
 * @LastEditors: Ten
 * @Description: 标签
-->
<template>
  <div :class="className">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    className() {
      return [
        'tag',
        this.type ? `tag__${this.type}` : '',
        this.size ? `tag__${this.size}` : ''
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  display: inline-block;
  padding: 2px 8px;
  line-height: normal;
  border-radius: 3px;
  word-break: keep-all;
  font-size: $font-size--extra-small;
  color: $color--white;
  &.tag__mini {
    padding: 2px 12px;
    transform: scale(.83333333);
    transform-origin: center;
  }
  &.tag__default {
    color: $color-text--regular;
    background-color: #E3E3E3;
  }
  &.tag__primary {
    background-color: $color--primary;
  }
  &.tag__success {
    background-color: $color--success;
  }
  &.tag__warning {
    background-color: $color--warning;
  }
  &.tag__danger {
    background-color: $color--danger;
  }
}
</style>
