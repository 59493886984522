<template>
  <div class="el-upload-list el-upload-list--picture-card">
    <div
      class="image-actions"
      :style="style"
    >
      <el-image
        :src="src"
        :fit="fit"
        :lazy="lazy"
        :style="style"
      />
      <span v-if="visibleActions" class="el-upload-list__item-actions">
        <span v-if="preview" class="el-upload-list__item-preview" @click="handlePreview">
          <i class="el-icon-zoom-in"></i>
        </span>
        <span v-if="download" class="el-upload-list__item-delete" @click="handleDownload">
          <i class="el-icon-download"></i>
        </span>
        <!-- <span v-if="remove" class="el-upload-list__item-delete" @click="handleRemove(path)">
          <i class="el-icon-delete"></i>
        </span> -->
      </span>
    </div>
    <ImageViewer
      :visible.sync="imageViewer.visible"
      :urlList="imageViewer.urlList"
    />
  </div>
</template>

<script>
import ImageViewer from './ImageViewer.vue'

export default {
  name: 'ImageActions',
  components: {
    ImageViewer
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 40
    },
    fit: {
      type: String,
      default: 'cover'
    },
    lazy: {
      type: Boolean,
      default: true
    },
    preview: {
      type: Boolean,
      default: false
    },
    download: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      path: '',
      imageViewer: {
        visible: false,
        urlList: []
      }
    }
  },
  computed: {
    style() {
      if (this.width && this.height) {
        const width = `${this.width}px`
        const height = `${this.height}px`
        return {
          width,
          height
        }
      } else {
        const size = `${this.size}px`
        return {
          width: size,
          height: size
        }
      }
    },
    visibleActions() {
      return this.preview || this.download
    }
  },
  methods: {
    handlePreview() {
      this.imageViewer.visible = true
      this.imageViewer.urlList = [this.src]
    },
    handleDownload() {
      this.$emit('download')
    },
    handleRemove() {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
.image-actions {
  position: relative;
}
</style>
