<!--
 * @Author: Qy
 * @Date: 2021/10/22 14:52
 * @LastEditTime: 2022/03/31 14:30
 * @LastEditors: Ten
 * @Description:推送进程弹框
-->
<template>
  <el-dialog
    width="520px"
    title="推送进程"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="onCloseDialog"
    custom-class="progress-dialog"
    :show-close="isSuccess"
  >
    <!-- 推送中 -->
    <div>
      <div v-show="!isSuccess">
        <TextTips tips="推送中弹窗不可关闭，如需停止，请点击「终止推送」按钮" />
        <div class="result-wrapper">
          <div class="result-wrapper__txt">
            <div class="txt">推送中任务：</div>
            <div class="num">
              <span class="bb success-txt">{{ progressNum }}</span
              >/{{ submitListTotal }}
            </div>
          </div>
        </div>
        <!-- 进度条 -->
        <div class="progress-wrapper">
          <div class="txt">进度：</div>
          <el-progress
            :percentage="Math.ceil((progressNum / submitListTotal) * 100)"
          ></el-progress>
        </div>
      </div>
      <!-- 推送完后 -->
      <div v-show="isSuccess">
        <div class="finish-result">
          <div class="finish-result__nums">
            <div class="result-wrapper success-result">
              <i class="result-wrapper__ico el-icon-success success" />
              <div class="result-wrapper__txt">
                <div class="txt">推送成功：</div>
                <div class="num">
                  <span class="bb success-txt">{{ successNum }}</span
                  >/{{ submitListTotal }}
                </div>
              </div>
            </div>
            <div
              class="result-wrapper success-result"
              v-show="submitListTotal - (successNum + errorNum) !== 0"
            >
              <i class="result-wrapper__ico el-icon-warning notpush" />
              <div class="result-wrapper__txt">
                <div class="txt">未推送：</div>
                <div class="num">
                  <span class="bb error">{{
                    submitListTotal - (successNum + errorNum)
                  }}</span
                  >/{{ submitListTotal }}
                </div>
              </div>
            </div>
            <div class="result-wrapper error-result" v-show="errorNum !== 0">
              <i class="result-wrapper__ico el-icon-error error" />
              <div class="result-wrapper__txt">
                <div class="txt">推送失败：</div>
                <div class="num">
                  <span class="bb error">{{ errorNum }}</span
                  >/{{ submitListTotal }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="successNum !== submitListTotal"
            class="finish-result__tips"
          >
            <div v-show="isAbortProgress" class="p">
              未推送原因：<span class="txt">用户终止推送</span>
            </div>
            <div v-show="!isAbortProgress && errorNum > 0" class="p">
              失败原因：<span class="txt">{{ sender }}推送失败</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button
        v-show="!isSuccess"
        :disabled="isAbortProgress"
        @click="handleAbortDelivery"
        >终止推送</el-button
      >
      <el-button v-show="isSuccess" @click="dialogVisible = false"
        >关 闭</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { sendSalary } from '@/api/biz/modules/salary'
import { TextTips } from '@/components/text'

export default {
  name: 'DeliveryProgressDialog',
  components: { TextTips },
  props: {
    title: String,
    type: { // 发送类型：1发送企业微信；2发送邮箱
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      isSuccess: false,
      progressNum: 0, // 进行中的个数
      submitList: [], // 需执行的列表
      resultList: [], // 执行后的结果列表
      cancelRequest: {},
      isAbortProgress: false // 是否是用户主动终止的
    }
  },
  computed: {
    // 总的请求个数
    submitListTotal() {
      return this.submitList.length
    },
    // 请求成功列表
    successList() {
      return this.submitList
        .filter((item) => {
          return this.successIds.some((i) => item.id === i)
        })
        .map((i) => {
          return {
            ...i,
            status: 2
          }
        })
    },
    // 请求成功的ids
    successIds() {
      return (this.resultList || [])
        .filter((i) => {
          return i.status === 2
        })
        .map((i) => {
          return i.id
        })
    },
    // 请求成功的个数
    successNum() {
      return this.successIds.length
    },
    // 请求失败列表
    errorList() {
      return this.submitList
        .filter((item) => {
          return this.errorIds.some((i) => item.id === i)
        })
        .map((i) => {
          return {
            ...i,
            status: 3
          }
        })
    },
    // 请求失败的ids
    errorIds() {
      return (this.resultList || [])
        .filter((i) => {
          return i.status === 3
        })
        .map((i) => {
          return i.id
        })
    },
    // 请求失败的个数
    errorNum() {
      return this.errorIds.length
    },
    // 终止后，没有请求到的列表
    notPushList() {
      const runList = [...this.errorIds, ...this.successIds]
      return this.submitList
        .filter((item) => {
          return runList.some((i) => item.id !== i)
        })
        .map((i) => {
          return {
            ...i,
            status: 1
          }
        })
    },
    // 发送方式
    sender() {
      return this.type === 1 ? '企微' : '企业邮箱'
    }
  },
  methods: {
    open(submitList) {
      this.progressNum = 0
      this.resultList = []
      this.isSuccess = false
      this.isAbortProgress = false
      this.dialogVisible = true
      this.submitList = JSON.parse(JSON.stringify(submitList))
      // console.log('共执行', this.submitList, submitList.length)
      this.handleDelivery(false)
    },
    onCloseDialog() {
      this.$emit('onClose', this.getShowResultList())
      this.dialogVisible = false
    },
    // 推送中
    handleDelivery() {
      if (this.isAbortProgress) {
        Object.prototype.hasOwnProperty.call(this.cancelRequest, 'cancel') &&
          this.cancelRequest.cancel()
        console.log(
          'this.cancelRequest',
          this.cancelRequest,
          Object.prototype.hasOwnProperty.call(this.cancelRequest, 'cancel')
        )
        return
      }
      if (this.progressNum === this.submitList.length) {
        return
      }
      this.progressNum += 1
      this.fetchData(this.submitList[this.progressNum - 1], this.type)
        .then(() => {
          this.submitList[this.progressNum].status = 2
          this.handleDelivery(false)
        })
        .catch(() => {
          this.isSuccess = true
        })
    },
    // 推送的列队请求
    fetchData(row, type) {
      return new Promise((resolve, reject) => {
        // this.$nextTick(() => {
        sendSalary(
          {
            ...row,
            type
          },
          this.cancelRequest
        )
          .then((res) => {
            this.resultList.push(res.data)
            // console.log('请求参数', row, '返回的数据状态', res.data)
            resolve()
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
        // })
      })
    },
    // 终止推送
    handleAbortDelivery() {
      this.isAbortProgress = true
      // this.handleDelivery(true)
      this.isSuccess = true
    },
    // 获取请求后的数据列表-成功-失败-未推送的数据整合
    getShowResultList() {
      return [...this.errorList, ...this.successList, ...this.notPushList]
    }
  }
}
</script>
<style lang="scss" scoped>
.result-wrapper {
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 24px;
  &__ico {
    font-size: 32px;
    margin-right: 8px;
  }
  &__txt {
    color: #8c94a5;
    .bb {
      font-size: 18px;
      font-weight: bold;
    }
    .success-txt {
      color: #4e5c6a;
    }
  }
  .notpush {
    color: #babfc9;
  }
  .success {
    color: #00b777;
  }
  .error {
    color: #f05050;
  }
}
.progress-wrapper {
  margin-top: 20px;
  .txt {
    color: #8c94a5;
  }
}
.finish-result {
  &__nums {
    display: flex;
    margin-bottom: 20px;
    .success-result {
      margin-right: 65px;
    }
  }
  &__tips {
    border-top: 1px solid #edf1f7;
    color: #8c94a5;
    .p {
      margin-top: 20px;
      .txt {
        color: #4e5c6a;
      }
    }
  }
}
</style>
