<!--
 * @Author: Ten
 * @Date: 2021/07/27 10:30
 * @LastEditTime: 2021/10/29 12:24
 * @LastEditors: Qy
 * @Description: 批量操作栏
-->
<template>
  <FixedFooterBar>
    <div slot="left" class="color-text--primary fs-14">
      已选择 <span class="color--primary">{{ selectedCount }} </span> 项
    </div>
    <el-button type="primary" @click="onPrint" :disabled="isSelected">
      打印
    </el-button>
    <el-button type="primary" @click="onExport" :disabled="isSelected">
      导出
    </el-button>
    <el-button type="danger" @click="onDelete" :disabled="isSelected">
      删除
    </el-button>
  </FixedFooterBar>
</template>

<script>
import { FixedFooterBar } from '@/components/bar'

export default {
  name: 'BatchToolsBar',
  components: {
    FixedFooterBar
  },
  props: {
    selectedCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isSelected() {
      return this.selectedCount === 0
    }
  },
  methods: {
    onPrint() {
      this.$emit('onPrint')
    },
    onExport() {
      this.$emit('onExport')
    },
    onDelete() {
      this.$emit('onDelete')
    }
  }
}
</script>
