<!--
 * @Author: Ten
 * @Date: 2021/10/26 17:50
 * @LastEditTime: 2022/03/01 16:26
 * @LastEditors: Ten
 * @Description: 新增/编辑用户
-->
<template>
  <Page>
    <Container
      v-loading="loading"
      back
      inner
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
      >
        <el-row :gutter="40">
          <el-col :sm="24" :md="12" :lg="12" :offset="12" :pull="12">
            <el-form-item label="成员名称：" prop="real_name" label-width="90px">
              <el-input
                v-model.trim="form.real_name"
                maxlength="20"
                placeholder="请输入2-20位成员名称"
                clearable
              />
            </el-form-item>
            <el-form-item label="成员账号：" prop="username" label-width="90px">
              <el-input
                v-if="id === -1"
                v-model.trim="form.username"
                maxlength="20"
                placeholder="请输入6-20位成员账号"
                clearable
              />
              <template v-else>{{ form.username }}</template>
            </el-form-item>
            <el-form-item label="密码：" prop="password" label-width="90px">
              <el-input
                v-model.trim="form.password"
                maxlength="15"
                placeholder="请输入6-15位密码"
                clearable
              />
            </el-form-item>
            <el-form-item label="部门：" prop="department_id" label-width="90px">
              <DepartmentCascader
                v-model="form.department_id"
              />
            </el-form-item>
            <el-form-item v-if="id !== -1" label="所属项目：" prop="user_projects" label-width="90px">
              <template v-if="form.user_projects.length > 0">
                <div v-for="(item, index) in form.user_projects" :key="index">{{ item }}；</div>
              </template>
              <template v-else>无</template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="flex flex-center flex-middle">
          <el-button @click="handleCancel">取 消</el-button>
          <el-button :loading="loadingSubmit" type="primary" @click="handleSubmit">确 定</el-button>
        </div>
      </template>
    </Container>
  </Page>
</template>

<script>
import { readUser, createUser, updateUser } from '@/api/base/modules/user'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { DepartmentCascader } from '@/components/department'

export default {
  name: 'SystemUserCreate',
  components: {
    Page,
    Container,
    DepartmentCascader
  },
  data() {
    return {
      id: -1,
      loading: false,
      loadingSubmit: false,
      form: {
        real_name: '',
        username: '',
        password: '',
        user_projects: [],
        department_id: ''
      },
      rules: {
        real_name: [
          {
            required: true,
            validator: (rule, value, callback) => {
              value = value?.trim()
              if (!value || (value && (value.length < 2 || value.length > 20))) {
                callback(new Error('请输入2-20位成员名称'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        username: [
          {
            required: true,
            validator: (rule, value, callback) => {
              value = value?.trim()
              if (!value || (this.id === -1 && value && (value.length < 6 || value.length > 20))) {
                callback(new Error('请输入6-20位成员账号'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              value = value?.trim()
              if ((rule.required || (this.id !== -1 && value)) && (!value || (value && (value.length < 6 || value.length > 15)))) {
                callback(new Error('请输入6-15位密码'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        department_id: [
          {
            required: true,
            message: '请选择部门',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    currentSuccessTips() {
      return this.id === -1 ? '添加成功' : '保存成功'
    }
  },
  created() {
    this.id = Number(this.$route.params.id) || -1
    this.$route.meta.title = '添加新成员'
    if (this.id !== -1) {
      this.loading = true
      this.rules.username.forEach(item => {
        item.required = false
      })
      this.rules.password.forEach(item => {
        item.required = false
      })
      readUser(this.id).then(res => {
        const { data } = res
        this.form.real_name = data.real_name
        this.form.username = data.username
        this.form.password = data.password
        let projects = []
        if (data.user_project_role && data.user_project_role.length > 0) {
          projects = data.user_project_role.map(project => {
            const roles = project.user_role ? project.user_role.map(role => role.role_title).join('；') : ''
            return project.title + (roles ? `（${roles}）` : '')
          })
        }
        this.form.user_projects = projects
        this.form.department_id = data.department_id
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    } else {
      this.rules.password.forEach(item => {
        item.required = true
      })
    }
  },
  methods: {
    handleCancel() {
      this.$history.back.apply(this)
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveUser()
        }
      })
    },
    getParams() {
      return {
        ...this.form,
        password: this.form.password || undefined,
        department_id: this.form.department_id || 0,
        user_projects: undefined
      }
    },
    async saveUser() {
      try {
        this.loadingSubmit = true
        if (this.id === -1) {
          await createUser(this.getParams())
        } else {
          await updateUser(this.id, this.getParams())
        }
        // EventBus.$emit('SystemMemberList', {
        //   type: this.id === -1 ? 'reset' : 'refresh'
        // })
        this.loadingSubmit = false
        this.$message.success(this.currentSuccessTips)
        this.$history.back.apply(this)
      } catch (err) {
        console.log(err)
        this.loadingSubmit = false
      }
    }
  }
}
</script>
