<!--
 * @Author: Ten
 * @Date: 2021/08/09 11:01
 * @LastEditTime: 2021/10/11 16:23
 * @LastEditors: Ten
 * @Description: 菜单级联选择器
-->
<template>
  <el-cascader
    v-loading="loading"
    element-loading-text=""
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.2)"
    element-loading-custom-class="el-custom-cascader-loading"
    v-model="checkedIds"
    :props="currentProps"
    :options="formatMenus"
    :show-all-levels="false"
    :placeholder="placeholder"
    expandTrigger="click"
    filterable
    clearable
    @change="onChange"
  />
</template>

<script>
import { readAllMenus } from '@/api/base/modules/menu'
import { toTree, getParentsIdListById } from '@/utils/tree'

export default {
  name: 'MenuCascader',
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    props: {
      type: Object,
      default() {
        return {
          label: 'title',
          value: 'id'
        }
      }
    },
    defaultList: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    options: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: false,
      currentProps: {
        label: 'title',
        value: 'id'
      },
      checkedIds: [],
      menus: []
    }
  },
  computed: {
    formatMenus() {
      return this.defaultList.concat(toTree(this.menus))
    }
  },
  watch: {
    value: {
      handler(val) {
        this.checkedIds = this.getCheckedIds(this.menus, val)
      },
      immediate: true
    },
    options: {
      handler(val) {
        this.menus = val
      },
      immediate: true
    }
  },
  created() {
    this.currentProps = Object.assign({}, this.currentProps, this.props)
    if (!this.$options.propsData.options) {
      this.fetchList()
    }
  },
  methods: {
    fetchList() {
      this.loading = true
      return readAllMenus().then((res) => {
        this.menus = res.data || []
        this.checkedIds = this.getCheckedIds(this.menus, this.value)
        this.loading = false
      }).catch(err => {
        console.log(err)
        this.loading = false
      })
    },
    onChange() {
      let checkedId = ''
      if (this.checkedIds.length > 0) {
        checkedId = this.checkedIds[this.checkedIds.length - 1]
      }
      this.$emit('input', checkedId)
      this.$emit('change', checkedId)
    },
    getCheckedIds(array, id) {
      if (id === '') {
        return []
      }
      return getParentsIdListById(array, id).concat(id)
    }
  }
}
</script>
