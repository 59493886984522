<!--
 * @Author: Ten
 * @Date: 2021/08/23 20:02
 * @LastEditTime: 2021/09/06 14:15
 * @LastEditors: Ten
 * @Description:
-->
<template>
  <el-row type="flex" :gutter="30">
    <el-col :span="leftSpan">
      <div :class="$style.subtitle">选择</div>
      <div :style="{'height': height}">
        <slot name="left" />
      </div>
    </el-col>
    <el-col :span="rightSpan" class="flex flex-column">
      <div :class="$style.subtitle">已选</div>
      <div :style="{'height': height}">
        <slot name="right" />
      </div>
    </el-col>
  </el-row>
</template>

<script>

export default {
  name: 'SelectPanel',
  props: {
    height: {
      type: String,
      default: '290px'
    },
    leftSpan: {
      type: Number,
      default: 12
    },
    rightSpan: {
      type: Number,
      default: 12
    }
  }
}
</script>

<style lang="scss" module>
.subtitle {
  margin-bottom: 20px;
  font-size: $font-size--base;
  color: $color-text--primary;
}
</style>
