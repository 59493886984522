<!--
 * @Author: Ten
 * @Date: 2021/10/26 20:49
 * @LastEditTime: 2022/03/01 16:18
 * @LastEditors: Ten
 * @Description: 添加/编辑项目角色
-->
<template>
  <Page>
    <Container
      v-loading="loading"
      back
      inner
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="90px"
      >
        <el-row :gutter="40">
          <el-col :sm="24" :md="12" :lg="12" :offset="12" :pull="12">
            <el-form-item label="角色名称：" prop="title">
              <el-input
                v-model.trim="form.title"
                maxlength="15"
                placeholder="请输入角色名称，不超过15字"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="12" :offset="12" :pull="12">
            <el-form-item label="备注：" prop="desc">
              <el-input
                v-model.trim="form.desc"
                type="textarea"
                maxlength="100"
                placeholder="请输入备注，不超过100字"
                clearable
                resize="none"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item ref="rules" label="功能权限：" prop="rules">
              <MenuTreePanel
                v-model="form.rules"
                show-check-all
                show-count
                  filter-project
                :default-expanded-keys="[0]"
                @change="onMenuTreePanelChanged"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="flex flex-center flex-middle">
          <el-button @click="handleCancel">取 消</el-button>
          <el-button :loading="loadingSubmit" type="primary" @click="handleSubmit">确 定</el-button>
        </div>
      </template>
    </Container>
  </Page>
</template>

<script>
import { readRole, createRole, updateRole } from '@/api/project/modules/role'
import { Page } from '@/components/page'
import { Container } from '@/components/container'
import { MenuTreePanel } from '@/components/menu'
import { EventBus } from '@/utils/event-bus'
import { resolveRules, generateRules } from '@/components/menu/utils'
import { validToErrorPoint } from '@/utils/validate'

export default {
  name: 'ProjectRoleCreate',
  components: {
    Page,
    Container,
    MenuTreePanel
  },
  data() {
    return {
      id: -1,
      loading: false,
      loadingSubmit: false,
      form: {
        title: '',
        desc: '',
        rules: []
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入角色名称，不超过15字',
            trigger: 'blur'
          }
        ],
        rules: [
          {
            required: true,
            message: '请选择功能权限',
            trigger: 'change'
          }
        ]
      }
    }
  },
  watch: {
    'form.menus'() {
      this.$refs.form.validateField('menus')
    },
    $route() {
      if (this.$route.name === this.$options.name) {
        this.initRole()
      }
    }
  },
  computed: {
    currentTitle() {
      return `${this.id === -1 ? '添加' : '编辑'}角色`
    },
    currentSuccessTips() {
      return this.id === -1 ? '添加成功' : '保存成功'
    }
  },
  created() {
    this.initRole()
  },
  methods: {
    onMenuTreePanelChanged() {
      this.$refs.rules.clearValidate()
    },
    handleCancel() {
      this.$history.back.apply(this)
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveRole()
        } else {
          // 定位表单错误
          this.$nextTick(() => {
            validToErrorPoint('.el-form-item.is-error', 199, () => {
              this.$message.error('还有必填的字段尚未填写，请填写完整再提交')
            })
          })
        }
      })
    },
    getParams() {
      const { menus, buttons } = resolveRules(this.form.rules)
      return {
        ...this.form,
        type: 1, // 角色类型-游戏
        rules: undefined,
        menus,
        buttons
      }
    },
    async saveRole() {
      try {
        this.loadingSubmit = true
        if (this.id === -1) {
          await createRole(this.getParams())
        } else {
          await updateRole(this.id, this.getParams())
        }
        this.$emit('submit', this.id)
        this.loadingSubmit = false
        this.$message.success(this.currentSuccessTips)
        const nameList = ['SystemRoleList', 'ProjectRoleList']
        for (let i = 0; i < nameList.length; i++) {
          EventBus.$emit(nameList[i], {
            type: this.id === -1 ? 'reset' : 'refresh'
          })
        }
        this.$history.back.apply(this)
      } catch (err) {
        console.log(err)
        this.loadingSubmit = false
      }
    },
    initRole() {
      const id = Number(this.$route.params.id) || -1
      if (id === this.id) {
        return
      }
      this.id = id
      if (this.id !== -1) {
        this.loading = true
        readRole(this.id)
          .then((res) => {
            const { data } = res
            this.form.title = data.title
            this.form.desc = data.desc
            this.form.rules = generateRules(data.menus, data.buttons)
            this.loading = false
          })
          .catch((err) => {
            console.log(err)
            this.loading = false
          })
      }
    }
  }
}
</script>
