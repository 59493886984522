<!--
 * @Author: Qy
 * @Date: 2021/10/22 14:52
 * @LastEditTime: 2022/04/02 14:34
 * @LastEditors: Qy
 * @Description:招聘列表
-->
<template>
  <Page>
    <template #actions>
      <el-button
        class="ml-20"
        title="导出全部"
        icon="el-icon-export"
        @click="handleAllExport"
        >导出全部</el-button
      >
    </template>
    <FilterBar>
      <el-form :inline="true" :model="params">
        <el-form-item label="姓名：">
          <el-input v-model.trim="params.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="电话：">
          <el-input
            v-model.trim="params.phone"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <Button type="refresh" @click="handleReset" />
          <Button type="search" @click="handleSearch" />
        </el-form-item>
      </el-form>
    </FilterBar>
    <Container>
      <Table
        ref="table"
        :loading="loading"
        :data="list"
        :pagination="{
          total: total,
          page: params.page,
          psize: params.psize
        }"
        row-key="id"
        @page-size-change="onSizeChange"
        @page-current-change="onCurrentChange"
        @selection-change="handleSelectionChange"
        @sort-change="onSortChange"
        @row-click="handleRowClick"
      >
        <!-- :reserve-selection="true" -->
        <el-table-column type="selection" width="50" fixed="left" />
        <el-table-column label="序号" min-width="60" fixed="left">
          <template slot-scope="scope">
            {{
              scope.$index | filterForamtSerialNumber(params.page, params.psize)
            }}
          </template>
        </el-table-column>
        <el-table-column label="姓名" min-width="90" fixed="left" show-overflow-tooltip>
          <template slot-scope="{ row }">
            {{row.name}}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="信息来源" width="126">
          <template slot-scope="{ row }">
            <span>{{ row.type === 1 ? '官网投递' : 'HR推荐' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="job_name"
          label="申请职位"
          min-width="126"
          show-overflow-tooltip
        />
        <el-table-column
          prop="created_at"
          label="填表时间"
          width="170"
          sortable="custom"
        />
        <el-table-column prop="sex" label="性别" width="60">
          <template slot-scope="{ row }">
            <span>{{ row.sex === 1 ? '女' : '男' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="birth_date" label="出生年月" width="100" />
        <el-table-column prop="phone" label="电话" min-width="112" show-overflow-tooltip  />
        <el-table-column prop="email" label="邮箱" min-width="126" show-overflow-tooltip />
        <el-table-column prop="wechat" label="微信" min-width="126" show-overflow-tooltip />
        <el-table-column label="操作" width="160px" fixed="right">
          <template slot-scope="{ row }">
            <div class="btns">
              <i
                v-auth:edit
                title="编辑"
                class="el-icon-edit-outline"
                @click.stop="handleOpenDialog('edit', row.id)"
              />
              <i
                v-auth:see
                title="详情"
                class="el-icon-view"
                @click.stop="handleOpenDialog('view', row.id)"
              />
              <i
                v-auth:print
                title="打印"
                class="el-icon-printer"
                @click.stop="handleSinglePrint(row.id)"
              />
              <i
                v-auth:delete
                title="删除"
                class="el-icon-delete"
                @click.stop="handleDelete(row)"
              />
            </div>
          </template>
        </el-table-column>
      </Table>
    </Container>
    <!-- 详情弹框 -->
    <ResumeInfoDialog
      ref="resumeInfoDialog"
      title="数据详情"
      @print="handleInfoPrint"
    />
    <!-- 编辑弹框 -->
    <FormDialog ref="formDialog" @submit="fetchList" />
    <!-- 打印组件-->
    <div class="hide">
      <PrintFilesBox ref="printFilesBox" :tplData="tplData" />
    </div>
    <!-- 底部操作 -->
    <BatchToolsBar
      v-if="selectedList.length > 0"
      :selected-count="selectedList.length"
    >
      <div slot="right">
        <el-button
          type="primary"
          @click="handleMorePrint"
          :disabled="selectedList.length === 0"
        >
          打印
        </el-button>
        <el-button
          type="primary"
          @click="handleMoreExport"
          :disabled="selectedList.length === 0"
        >
          导出
        </el-button>
        <el-button
          type="danger"
          @click="handleMoreDelete"
          :disabled="selectedList.length === 0"
        >
          删除
        </el-button>
      </div>
    </BatchToolsBar>
  </Page>
</template>

<script>
import { Container } from '@/components/container'
import { FilterBar } from '@/components/filter'
import { Table } from '@/components/table'
import { Button } from '@/components/button'
import FormDialog from './comps/FormDialog.vue'
import ResumeInfoDialog from './comps/ResumeInfoDialog.vue'
import PrintFilesBox from './comps/PrintFilesBox.vue'
import BatchToolsBar from '@/components/bar/BatchToolsBar.vue'
import { Page } from '@/components/page'
import { confirm } from '@/utils/messageBox'
import {
  getResumeList,
  exportResume,
  delResume,
  getResumeInfos
} from '@/api/biz/modules/job'
import download from '@/utils/download'

export default {
  name: 'ResumeList',
  components: {
    Container,
    FilterBar,
    Table,
    Button,
    ResumeInfoDialog,
    PrintFilesBox,
    BatchToolsBar,
    Page,
    FormDialog
  },
  props: {},
  data() {
    return {
      printHtml: '',
      list: [],
      selectedList: [], // 已勾选数据
      tplData: [], // 打印的详情数据
      loading: true,
      downloadLoading: false,
      params: {
        name: '',
        phone: '',
        page: 1,
        psize: 20,
        order_field: undefined,
        order_sort: undefined
      },
      total: 0
    }
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      console.log('查询参数', this.getParams())
      this.loading = true
      getResumeList(this.getParams())
        .then((res) => {
          const { data } = res
          this.list = data.data
          this.total = data.total
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    // 获取列表参数
    getParams(isExport = false) {
      const paramsObj = {
        ...this.params
      }
      if (isExport) {
        delete paramsObj.page
        delete paramsObj.psize
      }
      return paramsObj
    },
    // 查询
    handleSearch() {
      this.params.order_field = undefined
      this.params.order_sort = undefined
      this.params.page = 1
      this.fetchList()
    },
    // 重置
    handleReset() {
      this.params.name = ''
      this.params.phone = ''
      this.params.page = 1
      this.params.psize = 20
      this.total = 0
      this.params.order_field = undefined
      this.params.order_sort = undefined
      this.$refs.table.clearSelection()
      this.fetchList()
    },
    // 翻页选择条数
    onSizeChange(val) {
      this.params.page = 1
      this.params.psize = val
      this.fetchList()
    },
    // 翻页
    onCurrentChange(val) {
      this.params.page = val
      this.fetchList()
    },
    // table选中的操作
    handleSelectionChange(selectedList) {
      this.selectedList = selectedList
    },
    // 排序的操作
    onSortChange({ prop, order }) {
      console.log(prop, order)
      this.params.page = 1
      this.params.order_field = order ? prop : undefined
      this.params.order_sort = order
        ? {
          ascending: 'ASC',
          descending: 'DESC'
        }[order]
        : undefined
      this.fetchList()
    },
    // 详情 - 编辑
    handleOpenDialog(type, id) {
      type === 'edit' && this.$refs.formDialog.open(id)
      type === 'view' && this.$refs.resumeInfoDialog.open(id)
    },
    // 编辑
    handleRowClick(row) {
      this.$refs.resumeInfoDialog.open(row.id)
    },
    // 详情弹窗的打印
    handleInfoPrint(id) {
      this.getResumeInfos({ ids: id })
    },
    // 单条打印
    handleSinglePrint(id) {
      this.getResumeInfos({ ids: id })
    },
    // 多个打印
    handleMorePrint() {
      const getIdsParams = this.getIdsParams('请先勾选需要打印的数据')
      if (getIdsParams) {
        confirm({
          message: `是否要打印已选 ${getIdsParams?.selected.length} 项？`,
          forever: false
        }).then(() => {
          this.getResumeInfos(getIdsParams?.params)
        })
      }
    },
    // 请求打印详情的接口
    getResumeInfos(params) {
      this.loading = true
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      )
      let popupWindow = null
      let printDoc = null
      if (isSafari) {
        popupWindow = window.open(
          '',
          '',
          'width=450,height=450,scrollbars=yes,resizable=1'
        )
        printDoc = popupWindow.document
        printDoc.write('')
        popupWindow.addEventListener('beforeunload', () => {
          this.loading = false
        })
        popupWindow.addEventListener('load', () => {
          popupWindow.print()
        })
        popupWindow.addEventListener('afterprint', (event) => {
          console.log('After print')
          popupWindow.close()
          popupWindow = null
        })
      }
      getResumeInfos(params)
        .then((res) => {
          this.tplData = res.data
          console.log(this.tplData)
          this.$nextTick(() => {
            if (isSafari) {
              // Safari打印
              printDoc = popupWindow.document
              printDoc.write(`
                  <!DOCTYPE>
                  <html>
                  <head>
                      <title>简历打印</title>
                      <link href="./styles/print.css" rel="stylesheet">
                  </head>
                  <body>
                  ${this.$refs.printFilesBox.$el.outerHTML}
                  </body>
                  </html>
              `)
              // clean up
              printDoc.close()
            } else {
              this.$refs.printFilesBox.printFiles() // 其他浏览器打印
            }
          })
          this.loading = false
        })
        .catch((err) => {
          if (isSafari) {
            popupWindow.close()
            popupWindow = null
            printDoc = null
          }
          console.log(err)
          this.loading = false
        })
    },
    // 获取多个勾选的参数
    getIdsParams(msg) {
      if (this.selectedList.length === 0) {
        return this.$message.error(msg)
      }
      const selectedIdsList = this.selectedList.map((item) => {
        return item.id
      })
      const paramsExp = { ids: selectedIdsList.join(',') }
      return { selected: selectedIdsList || [], params: paramsExp || {} }
    },
    // 批量导出
    handleMoreExport() {
      const getIdsParams = this.getIdsParams('请先勾选需要导出的数据')
      if (getIdsParams) {
        this.exportResume(getIdsParams?.params)
      }
    },
    // 全部导出
    handleAllExport() {
      this.exportResume(this.getParams(true))
    },
    // 导出的请求接口
    exportResume(params) {
      exportResume(params).then((res) => {
        download(res)
        this.$message.success('已导出表格')
      })
    },
    /**
     * 删除数据
     * @param {*} item 当前选择的数据， 当为false值的时候，为批量操作
     * @param {*} params 多条数据的请求参数
     * @param {*} length 已勾选数据的条数
     * @return {*}
     * @Author: Qy
     * @Date: 2021/10/29 15:06
     */
    handleDelete(item, params, length) {
      const postParams = item === false ? params : { ids: item?.id }
      confirm({
        message:
          item === false
            ? `是否删除已选 ${length} 项？`
            : `是否删除【${item.name}】的数据？`,
        forever: true
      }).then(() => {
        this.loading = true
        delResume(postParams)
          .then(() => {
            this.$message.success('删除成功')
            this.$refs.table.clearSelection()
            this.fetchList()
            this.loading = false
          })
          .catch((err) => {
            console.log(err)
            this.loading = false
          })
      })
    },
    // 批量删除
    handleMoreDelete() {
      const getIdsParams = this.getIdsParams('请先勾选需要删除的数据')
      if (getIdsParams) {
        this.handleDelete(
          false,
          getIdsParams?.params,
          getIdsParams?.selected.length
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btns {
  display: flex;
  i {
    font-size: 18px;
    color: #9199a9;
    &:not(:first-child) {
      margin-left: 16px;
    }
    &:hover {
      color: $color--primary;
      cursor: pointer;
    }
  }
}
.hide {
  display: none;
}
::v-deep {
  .el-table__row {
    :hover {
      cursor: pointer;
    }
  }
}
</style>
